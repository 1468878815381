import { useState, useMemo } from 'react'
import { Form, Dropdown } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import DatePicker from 'react-datepicker'
import Select from 'react-select'
import formate from '../utils/format-text'

function Filter(props) {
 let AppPermission
 if (localStorage.getItem('config')) {
 AppPermission = JSON.parse(localStorage.getItem('config')).data.projects[0].userPermission
 }


 let stateValue = useSelector((state) => state)
 let [Filterfield, setFilterfield] = useState([])
 let [filterpicker, setfilterpicker] = useState()
 let [voicemailphone, setvoicemailphone] = useState("")
 let [toDateError, setToDateError] = useState(false);
 let [filterarrlist, setfilterarrlist] = useState([
 { label: 'Today', value: 3 },
 { label: 'Yesterday', value: 1 },
 { label: 'Last 7 Days', value: 2 },
 { label: 'Date Range', value: 4 },
 ])
 let [filtershow, setfiltershow] = useState(false)
 let [calldispo, setcalldispo] = useState([
 { label: 'NEW', value: 'new' },
 { label: 'Order', value: 'order' },
 ])
 let [priorityvalues, setpriorityvalues] = useState([
 { label: 'Low', value: 'low' },
 { label: 'Medium', value: 'medium' },
 { label: 'High', value: 'high' },
 ])
 let [meetingstatus, setmeetingstatus] = useState([
 { label: 'Upcoming', value: "0" },
 { label: 'Completed', value: 1 },
 { label: 'Missed', value: 2 },
 ])
 let [meetingmodule, setmeetingmodule] = useState([
 { label: 'Lead', value: "lead" },
 { label: 'Ticket', value: "ticket" }, { label: 'Contact', value: "contact" },

 ])
 let [isoverdue, setisoverdue] = useState("false")
 let [status, setstatus] = useState([
 { label: 'All', value: 'all' },
 { label: 'Open', value: 'open' },
 { label: 'Pending', value: 'pending' },
 { label: 'Resolve', value: 'resolve' },
 { label: 'Closed', value: 'closed' },
 { label: 'Waiting On Customer', value: 'woc' },
 { label: 'In Progress', value: 'in_progress' },
 { label: 'Hold', value: 'hold' },
 ])
 let [datedata, setdatedata] = useState({ from: '', to: '' })
 let [checked, setchecked] = useState(false)
 let [calldialstatus, setcalldialstatus] = useState([])
 let [filterdata, setfilterdata] = useState({})
 let [subfilter, setsubfilter] = useState(['new'])
 let [prioritydata, setprioritydata] = useState([''])
 let [ticketid, setticketid] = useState('')
 let [statedata, setstatedata] = useState(['open'])
 let [meetingmoduledata, setmeetingmoduledata] = useState([''])
 let [meetingstatusdata, setmeetingstatusdata] = useState([''])
 useMemo(() => {
 if (props.Filterfield != undefined) {
 if (props.type === 'lead') {
 setFilterfield(props.Filterfield)
 setsubfilter(props.subfilter)
 setfield()
 setchecked(props.checked)
 let dialstatus = JSON.parse(localStorage.getItem('dial_status'))

 let dispo = dialstatus.map((element) => {
 return { label: formate(element), value: element }
 })

 setcalldialstatus(dispo)

 }if(props.type === 'custommodule'){
 setFilterfield(props.Filterfield)
 setfield()
 }
 }
 }, [props.Filterfield])
 function setfield() {
 let obj = {}
 props.Filterfield.map((elm) => {
 if (elm.multiple == false && elm.fieldType == 'SelectList') {
 obj[elm.model] = []
 } else {
 obj[elm.model] = ''
 }
 })
 setfilterdata(obj)
 }
 function getselectvalue(field) {
 if (field == 'subfilter') {
 if (subfilter.length > 0) {
 let fltdata = subfilter.map((elm) => {

 return calldialstatus.find((elem) => elem.value == elm)
 })
 return fltdata
 } else {
 return []
 }
 }
 if (field == 'priority') {
 if (prioritydata.length > 0 && prioritydata[0] !== "") {
 let fltdata = prioritydata.map((elm) => {
 return priorityvalues.find((elem) => elem.value == elm)
 })
 return fltdata
 } else {
 return []
 }
 }
 if (field === 'status') {
 if (statedata.length > 0) {
 let fltdata = statedata.map((elm) => {
 return status.find((elem) => elem.value == elm)
 })
 return fltdata
 } else {
 return []
 }
 }
 if (field === 'meetingstatus') {

 if (meetingstatusdata.length > 0) {
 let fltdata = meetingstatusdata.map((elm) => {
 return meetingstatus.find((elem) => elem.value == elm)
 })
 return fltdata
 } else {
 return []
 }
 }
 if (field === 'meetingmodule') {
 if (meetingmoduledata.length > 0 && meetingmoduledata[0] !== "") {
 let fltdata = meetingmoduledata.map((elm) => {
 return meetingmodule.find((elem) => elem.value == elm)
 })
 return fltdata
 } else {
 return []
 }
 }

 if (field === 'date') {
 if (filterpicker == undefined || filterpicker == '') {
 return null
 } else {
 return filterarrlist.find((e) => e.value == filterpicker)
 }
 }
 }

 function dateformate(between) {
 let datedata = new Date()
 datedata.setDate(datedata.getDate() - between)
 const yyyy = datedata.getFullYear()
 let mm = datedata.getMonth() + 1
 let dd = datedata.getDate()
 if (dd < 10) dd = '0' + dd
 if (mm < 10) mm = '0' + mm
 return (datedata = yyyy + '-' + mm + '-' + dd)
 }

 function filterchange(fieldname, e) {
 if (fieldname == 'subfilter') {
 let filterdata = e.map((elm) => {
 return elm.value
 })
 setsubfilter(filterdata)
 } else if (fieldname == 'date') {
 setdatedata((set) => {
 if (e == 3 || e == 1 || e == 2) {
 set.from = dateformate(0)
 set.to = dateformate(0)
 }
 if (e == 1) {
 set.from = dateformate(1)
 set.to = dateformate(1)
 } else if (e == 2) {
 set.from = dateformate(7)
 } else if (e == 4) {
 set.from = ''
 set.to = ''
 }
 return set
 })
 setfilterpicker(e)
 } else if (fieldname === 'priority') {
 let filterdata = [e].map((elm) => {
 return elm.value
 })
 setprioritydata(filterdata)
 } else if (fieldname === 'status') {
 let filterdata = [e].map((elm) => {
 return elm.value
 })
 setstatedata(filterdata)
 } else if (fieldname === 'meetingstatus') {
 let filterdata = [e].map((elm) => {
 return elm.value
 })
 setmeetingstatusdata(filterdata)
 } else if (fieldname === 'meetingmodule') {
 let filterdata = [e].map((elm) => {
 return elm.value
 })
 setmeetingmoduledata(filterdata)
 }
 }

 function setdate(data) {
 if (data !== null) {
 const yyyy = data.getFullYear()
 let mm = data.getMonth() + 1
 let dd = data.getDate()
 if (dd < 10) dd = '0' + dd
 if (mm < 10) mm = '0' + mm
 return yyyy + '-' + mm + '-' + dd
 } else {
 return ''
 }
 }

 function dynamicFilterChangedata(field, value) {
 let data
 if (field.fieldType == 'SelectList') {
 data = value.map((elm) => {
 return elm.value
 })
 if (field.multiple == true) {
 data = data.toString()
 }
 } else {
 data = value.target.value
 }
 setfilterdata((set) => {
 set[`${field.model}`] = data
 return { ...set }
 })
 }

 function objectConverter(val) {
 const arr = JSON.parse(val.values[0])
 let option_arr = []
 for (let i = 0; i < arr.length; i++) {
 const element = arr[i]
 let obj = {}
 obj['value'] = element.value
 obj['label'] = element.name
 option_arr.push(obj)
 }

 return (
 <>
 <Select
 isMulti={true}
 styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
 menuPortalTarget={document.body}
 menuShouldScrollIntoView={true}
 menuPlacement={`auto`}
 onChange={(e) => dynamicFilterChangedata(val, e)}
 options={option_arr}
 placeholder={formate(val.placeholder)}
 />
 </>
 )
 }

 function sendfiltervalue(value) {
 if (value === 'lead') {
 setfiltershow(false)
 if (subfilter.length == 0) {
 setsubfilter(['new'])
 }
 let filtersdata = {
 subFilterValue: subfilter.length > 0 ? subfilter : ['new'],
 modified_date: datedata,
 dynamicfilter: filterdata,
 dialedStatus: checked ? 1 : 0

 }
 props.filterdataapi(filtersdata)


 } else if (value === 'ticket') {
 setfiltershow(false)
 let overduevalue = 0
 if (isoverdue === "true") {
 overduevalue = 1
 }
 let payload = {
 priority: prioritydata[0],
 status: statedata[0],
 from: datedata.from,
 to: datedata.to,
 ticketid: ticketid,
 overdue: overduevalue
 }
 if (statedata[0] === "all") {
 payload.status = ""
 }
 if (ticketid === "") {
 payload.merged_as = "parent"
 } else {
 payload.merged_as = ""
 }
 props.filterdataapi(payload)
 setticketid("")
 setprioritydata([''])
 setisoverdue("false")
 setdatedata((set) => {
 set.to = ''
 set.from = ''
 return {
 ...set,
 }
 })
 } else if (value === 'meeting') {
  if (datedata.from && datedata.to && datedata.from === datedata.to) {
    // Dates are the same, no need to show the error message
    setToDateError(false);
  } else{
    if((datedata.from && !datedata.to)||(!datedata.from && datedata.to)){         ///changes
        setToDateError(true);
        return;
      }else {
        setToDateError(false);
      }
    }
 setfiltershow(false)
 let payload = {
 from: datedata.from,
 to: datedata.to,
 module: meetingmoduledata[0],
//  status: Number(meetingstatusdata[0])
 }
 if (meetingstatusdata.length > 0 && meetingstatusdata[0] !== "") {
  payload.status = Number(meetingstatusdata[0]);
}
 props.filterdataapi(payload)
 setdatedata((set) => {
 set.to = ''
 set.from = ''
 return {
 ...set,
 }
 })
 setmeetingstatusdata([""])
 setmeetingmoduledata([""])
 } else if (value === 'voicemailfilter') {
 setfiltershow(false)
 let payload = {
 from: datedata.from,
 to: datedata.to,
 phone: voicemailphone
 }

 props.filterdataapi(payload)
 setdatedata((set) => {
 set.to = ''
 set.from = ''
 return {
 ...set,
 }
 })
 setvoicemailphone("")
 }else if(value ==="custommodule"){
 setfiltershow(false)
 let filtersdata = {
 dynamicfilter: filterdata
 }
 props.filterdataapi(filtersdata)
 }
 }
 function ticketidfunction(e) {
 setticketid(e.target.value)
 }
 function togglefunction(e) {
 setisoverdue(e.target.checked.toString())
 }
 return (
 <div>
 {(props.type === 'lead' || props.type === "custommodule") && (
 <div>
 <Dropdown show={filtershow}>
 <Dropdown.Toggle id="dropdown-custom-1" className="logout">
 <span
 onClick={() => {
 setfiltershow(!filtershow)
 }}
 className={
 stateValue.call.autodialdatas.length === 0
 ? 'material-icons lead-table-button lead-reset'
 : 'material-icons lead-table-button lead-reset'
 }
 >
 tune
 </span>
 </Dropdown.Toggle>
 <Dropdown.Menu show={filtershow}>
 <div className="lead-filter-container">
 <div className="lead-filter-container-head line">
 <span className="material-icons lead-filter-icon">
 filter_list
 </span>
 <div className="lead-filter-title">Filter</div>
 </div>
 <div className="lead-filter-parent">
 {props.type === 'lead' && <div className="lead-filter-body">
 {AppPermission.auto_dial_sub_filter && <div className="Customform-field-cnt">
 <label className={'label_cls'}>{'Dial Status'}</label>
 <Select
 styles={{
 menuPortal: (base) => ({ ...base, zIndex: 9999 }),
 }}
 menuPortalTarget={document.body}
 menuShouldScrollIntoView={true}
 menuPlacement={`auto`}
 isMulti={true}
 value={getselectvalue('subfilter')}
 onChange={(e) => {
 filterchange('subfilter', e)
 }}
 options={calldialstatus}

 />
 </div>}
 {AppPermission.auto_dial_date_range && <div className="Customform-field-cnt">
 <label className="label_cls">{'Date'}</label>
 <Select
 styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
 menuPortalTarget={document.body}
 menuShouldScrollIntoView={true}
 menuPlacement={`auto`}
 value={getselectvalue('date')}
 onChange={(e) => filterchange('date', e.value)}
 options={filterarrlist}
 isSearchable={false}
 />
 </div>}

 {filterpicker == 4 && AppPermission.auto_dial_date_range && (
 <div className="Customform-field-cnt">
 <label className={'label_cls'}>{'Date Between'}</label>
 <>
 <div className="DatetimePicker">
 <div className="Datetime_container" key="1">
 <DatePicker
 key="12"
 dateFormat="dd/MM/yyyy"
 placeholderText="From"
 selected={
 datedata.from !== ''
 ? new Date(datedata.from)
 : null
 }
 onChange={(e) => {
 setdatedata((set) => {
 set.from = setdate(e)
 return { ...set }
 })
 }}
 className="form-control"
 />
 </div>
 <div className="Datetime_container" key="2">
 <DatePicker
 key="13"
 dateFormat="dd/MM/yyyy"
 placeholderText="To"
 selected={
 datedata.to !== ''
 ? new Date(datedata.to)
 : null
 }
 onChange={(e) => {
 setdatedata((set) => {
 set.to = setdate(e)
 return { ...set }
 })
 }}
 className="form-control"
 />
 </div>
 </div>
 </>
 </div>
 )}
 <div className="Customform-field-cnt">
 <label className={'label_cls'}>{'All Leads'}</label>
 <Form.Check
 type="switch"
 id="custom-switch"
 checked={checked}
 onChange={(e) => {
 setchecked(!checked)
 }}
 />
 </div>
 </div>}
 {AppPermission.lead_dynamic_filter && Filterfield.length > 0 && <>
 <Dropdown.Header>Advanced Filter</Dropdown.Header>
 <div className="lead-filter-body">
 {Filterfield &&
 Filterfield.map((res, i) => {
 return (
 <>
 {res.fieldType == 'SelectList' &&
 res.agent_show_on_filter == true && (
 <>
 <div
 className="Customform-field-cnt"
 key={i.toString()}
 >
 <label
 className={
 false
 ? 'label_cls required'
 : 'label_cls'
 }
 >
 {formate(res.label)}{' '}
 </label>
 {objectConverter(res)}
 </div>
 </>
 )}
 {res.fieldType == 'TextInput' &&
 res.agent_show_on_filter == true && (
 <>
 <div
 className="Customform-field-cnt"
 key={i.toString()}
 >
 <label
 className={
 false
 ? 'label_cls required'
 : 'label_cls'
 }
 >
 {formate(res.label)}
 </label>
 <Form.Control
 key={i + i.toString()}

 onChange={(e) =>
 dynamicFilterChangedata(res, e)
 }
 className="form-control"
 placeholder={formate(res.placeholder)}
 />
 </div>
 </>
 )}
 </>
 )
 })}
 </div>
 </>}
 </div>
 <div className="dialog-buttons">
 <button
 onClick={() => {
 sendfiltervalue(props.type)
 }}
 className="dialog-btn1"
 >
 Apply Filter
 </button>
 </div>
 </div>
 </Dropdown.Menu>
 </Dropdown>
 </div>
 )}
 {props.type === 'tickets' && (
 <div>
 <Dropdown show={filtershow}>
 <Dropdown.Toggle id="dropdown-custom-1" className="logout">
 <span
 onClick={() => {
 setfiltershow(!filtershow)
 }}
 className={
 stateValue.call.autodialdatas.length === 0
 ? 'material-icons lead-table-button lead-reset'
 : 'material-icons lead-table-button lead-reset'
 }
 >
 tune
 </span>
 </Dropdown.Toggle>
 <Dropdown.Menu show={filtershow}>
 <div className="lead-filter-container">
 <div className="lead-filter-container-head line">
 <span className="material-icons lead-filter-icon">
 filter_list
 </span>
 <div className="lead-filter-title">Filter</div>
 </div>
 <div className="lead-filter-parent">
 <div className="lead-filter-body">
 <div className="Customform-field-cnt">
 <label className={'label_cls'}>{'Ticket Id'}</label>
 <Form.Control value={ticketid}
 onChange={(e) =>
 ticketidfunction(e)
 }
 className="form-control"
 placeholder={formate("Ticket Id")}
 />
 </div>
 <div className="Customform-field-cnt">
 <label className={'label_cls'}>{'Status'}</label>

 <Select
 styles={{
 menuPortal: (base) => ({ ...base, zIndex: 9999 }),
 }}
 menuPortalTarget={document.body}
 menuShouldScrollIntoView={true}
 menuPlacement={`auto`}
 isMulti={false}
 value={getselectvalue('status')}
 onChange={(e) => {
 filterchange('status', e)
 }}
 options={status}
 />
 </div>
 <div className="Customform-field-cnt">
 <label className={'label_cls'}>{'Priority'}</label>

 <Select
 styles={{
 menuPortal: (base) => ({ ...base, zIndex: 9999 }),
 }}
 menuPortalTarget={document.body}
 menuShouldScrollIntoView={true}
 menuPlacement={`auto`}
 isMulti={false}
 value={getselectvalue('priority')}
 onChange={(e) => {
 filterchange('priority', e)
 }}
 options={priorityvalues}
 />
 </div>


 <div className="Customform-field-cnt">
 <label className={'label_cls'}>{'Date Between'}</label>
 <>
 <div className="DatetimePicker">
 <div className="Datetime_container" key="1">
 <DatePicker
 key="12"
 dateFormat="dd/MM/yyyy"
 placeholderText="From"
 selected={
 datedata.from !== ''
 ? new Date(datedata.from)
 : null
 }
 onChange={(e) => {
 setdatedata((set) => {
 set.from = setdate(e)
 return { ...set }
 })
 }}
 className="form-control"
 />
 </div>
 <div className="Datetime_container" key="2">
 <DatePicker
 key="13"
 dateFormat="dd/MM/yyyy"
 placeholderText="To"
 selected={
 datedata.to !== ''
 ? new Date(datedata.to)
 : null
 }
 onChange={(e) => {
 setdatedata((set) => {
 set.to = setdate(e)
 return { ...set }
 })
 }}
 minDate={datedata.from !== '' ? new Date(datedata.from) : null}
 className="form-control"
 />
 </div>
 </div>
 </>
 </div>

 {/* <div className="Customform-field-cnt">
 <label className={'label_cls'}>{'Overdue'}</label>
 <Form.Check
 type="switch"
 id="custom-switch"
 checked={isoverdue === "true"}
 onChange={(e) => {
 togglefunction(e)
 }}
 />
 </div> */}
 </div>
 </div>
 <div className="dialog-buttons">
 <button
 onClick={() => {
 sendfiltervalue('ticket')
 }}
 className="dialog-btn1"
 >
 Apply Filter
 </button>
 </div>
 </div>
 </Dropdown.Menu>
 </Dropdown>
 </div>
 )}
 {props.type === 'meeting' && (
 <div>
 <Dropdown show={filtershow}>
 <Dropdown.Toggle id="dropdown-custom-1" className="logout">
 <span
 onClick={() => {
 setfiltershow(!filtershow)
 }}
 className={
 stateValue.call.autodialdatas.length === 0
 ? 'material-icons lead-table-button lead-reset'
 : 'material-icons lead-table-button lead-reset'
 }
 >
 tune
 </span>
 </Dropdown.Toggle>
 <Dropdown.Menu show={filtershow}>
 <div className="lead-filter-container">
 <div className="lead-filter-container-head line">
 <span className="material-icons lead-filter-icon">
 filter_list
 </span>
 <div className="lead-filter-title">Filter</div>
 </div>
 <div className="lead-filter-parent">
 <div className="lead-filter-body">
 <div className="Customform-field-cnt">
 <label className={'label_cls'}>{'Date Between'}</label>
 <>
 <div className="DatetimePicker">
 <div className="Datetime_container" key="1">
 <DatePicker
 key="12"
 dateFormat="dd/MM/yyyy"
 placeholderText="From"

 selected={
 datedata.from !== ''
 ? new Date(datedata.from)
 : null
 }
 onChange={(e) => {
 setToDateError(false)
 setdatedata((set) => {
 set.from = setdate(e)
 return { ...set }
 })
 }}
 className="form-control"
 />
 </div>
 <div className="Datetime_container" key="2">
 <DatePicker
 key="13"
 dateFormat="dd/MM/yyyy"
 placeholderText="To"
 selected={
 datedata.to !== ''
 ? new Date(datedata.to)
 : null
 }
 onChange={(e) => {
setToDateError(false)
 setdatedata((set) => {
 set.to = setdate(e)
 return { ...set }
 })
 }}
 minDate={datedata.from !== '' ? new Date(datedata.from) : null}
 className="form-control"
 />
 </div>
 </div>
 </>
 {toDateError && (
  <div className='error-message_'>
    Please enter both fields
  </div>
)}
 </div>
 <div className="Customform-field-cnt">
 <label className={'label_cls'}>{'Module'}</label>

 <Select
 styles={{
 menuPortal: (base) => ({ ...base, zIndex: 9999 }),
 }}
 menuPortalTarget={document.body}
 menuShouldScrollIntoView={true}
 menuPlacement={`auto`}
 isMulti={false}
 value={getselectvalue('meetingmodule')}
 onChange={(e) => {
 filterchange('meetingmodule', e)
 }}
 options={meetingmodule}
 />
 </div>
 <div className="Customform-field-cnt">
 <label className={'label_cls'}>{'Status'}</label>

 <Select
 styles={{
 menuPortal: (base) => ({ ...base, zIndex: 9999 }),
 }}
 menuPortalTarget={document.body}
 menuShouldScrollIntoView={true}
 menuPlacement={`auto`}
 isMulti={false}
 value={getselectvalue('meetingstatus')}
 onChange={(e) => {
 filterchange('meetingstatus', e)
 }}
 options={meetingstatus}
 />
 </div>
 </div>
 </div>
 <div className="dialog-buttons">
 <button
 onClick={() => {
 sendfiltervalue('meeting')
 }}
 className="dialog-btn1"
 >
 Apply Filter
 </button>
 </div>
 </div>
 </Dropdown.Menu>
 </Dropdown>
 </div>
 )}



 {props.type === 'voicemail' && (
 <div>
 <Dropdown show={filtershow}>
 <Dropdown.Toggle id="dropdown-custom-1" className="logout">
 <span
 onClick={() => {
 setfiltershow(!filtershow)
 }}
 className={
 stateValue.call.autodialdatas.length === 0
 ? 'material-icons lead-table-button lead-reset'
 : 'material-icons lead-table-button lead-reset'
 }
 >
 tune
 </span>
 </Dropdown.Toggle>
 <Dropdown.Menu show={filtershow}>
 <div className="lead-filter-container">
 <div className="lead-filter-container-head line">
 <span className="material-icons lead-filter-icon">
 filter_list
 </span>
 <div className="lead-filter-title">Filter</div>
 </div>
 <div className="lead-filter-parent">
 <div className="lead-filter-body">
 <div className="Customform-field-cnt">
 <label className={'label_cls'}>{'Date Between'}</label>
 <>
 <div className="DatetimePicker">
 <div className="Datetime_container" key="1">
 <DatePicker
 key="12"
 dateFormat="dd/MM/yyyy"
 placeholderText="From"
 selected={
 datedata.from !== ''
 ? new Date(datedata.from)
 : null
 }
 onChange={(e) => {
 setdatedata((set) => {
 set.from = setdate(e)
 return { ...set }
 })
 }}
 className="form-control"
 />
 </div>
 <div className="Datetime_container" key="2">
 <DatePicker
 key="13"
 dateFormat="dd/MM/yyyy"
 placeholderText="To"
 selected={
 datedata.to !== ''
 ? new Date(datedata.to)
 : null
 }
 onChange={(e) => {
 setdatedata((set) => {
 set.to = setdate(e)
 return { ...set }
 })
 }}
 className="form-control"
 />
 </div>
 </div>
 </>
 </div>
 <div className="Customform-field-cnt">
 <label className={'label_cls'}>{'Phone Number'}</label>
 <Form.Control
 value={voicemailphone}
 onChange={(e) =>
 setvoicemailphone(e.target.value)
 }
 className="form-control"
 placeholder={"Phone Number"}
 />
 </div>
 </div>
 </div>
 <div className="dialog-buttons">
 <button
 onClick={() => {
 sendfiltervalue('voicemailfilter')
 }}
 className="dialog-btn1"
 >
 Apply Filter
 </button>
 </div>
 </div>
 </Dropdown.Menu>
 </Dropdown>
 </div>
 )}
 </div>
 )
}

export default Filter

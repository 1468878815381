import { logDOM } from "@testing-library/react";
import api from "../../utils/axios-util";

export const fetch_voicemail = (query) => {
  
    let  filter="";
    if(query.filter){
       filter='&search='+query.filter;
    }
    if(query.phone !== undefined){
        filter='&phone='+query.phone;
     }
    let dateFilter=""
    if(query.fromDate != undefined && query.toDate != undefined){
        dateFilter = `&from=${query.fromDate}&to=${query.toDate}`
    }
     return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
            await api
                .get(`/fetch/voicemail?agent=${query.userID}&call_type=VOICEMAIL${filter}&take=${query.take}&skip=${query.skip}${dateFilter}`)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
};

import React, { useState, useRef } from 'react'
import Scriptpage from '../components/Lead/Lead-script'

function Script() {

  return (
    <div className="page-main-container">
      <div className="scroll-lft" md={8}>
        <Scriptpage/>
      </div>
    </div>
  )
}

export default Script

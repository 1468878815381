import React, { useState, useRef } from 'react'
import { useEffect } from 'react'
import ContactView from '../../pages/ContactView'
import { useParams, useLocation } from 'react-router-dom'
function CreateTickets() {
  const location = useLocation()
  const [loadCustomfrom, setloadCustomfrom] = useState(false)
  const [islead, setislead] = useState(false)

  useEffect(() => {
    setloadCustomfrom(true)
    if (location.pathname.split("/").length > 2) {
      setislead([location.pathname.split("/")[4],location.pathname.split("/")[3]])
    } else {
      setislead("0")
    }
  }, [])

  return (
    <>
      {loadCustomfrom && (
        <>
          <ContactView contactmodule={true} islead={islead} />
        </>
      )}
    </>
  )
}

export default CreateTickets

import React, {
    useEffect,
    useState
} from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import whatsapp from '../assets/images/timeline/whatsapp.svg'
import Format from '../utils/format-text'
import { ChatHistory } from './../redux/actions/ChatActions'
import EventEmitter from './../utils/EventEmitter'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
function DialogboxWa(props) {
    const [history, sethistory] = useState([])
    let agent = localStorage.getItem('user')
    let dispatch = useDispatch()
    useEffect(() => {
        if (props.open === true) {
            let data = {
                phone: props.phone_number,
                session_id: props.data.channel_id,
                channel:props.channel,
                custom_value:props.phone_number
            }

            dispatch(ChatHistory(data)).then((response) => {
                if (response.data.statusCode === 200) {
                    sethistory([].concat(response.data.data))
                } else {
                    sethistory([])
                }
            }).catch((err) => {
                EventEmitter.emit('error-message-getter', {
                    action: 'fetch',
                    module: 'whatsapp conversation',
                })
            })
        }
    }, [props.open])
    function dataformate(Data) {
        const today = new Date(Data);
        const yyyy = today.getFullYear();
        let mm = today.getMonth() + 1;
        let dd = today.getDate();
        let hh = today.getHours();
        let MM = today.getMinutes();
        let ss = today.getSeconds();
        if (dd < 10) dd = "0" + dd;
        if (mm < 10) mm = "0" + mm;
        if (hh < 10) hh = "0" + hh;
        if (MM < 10) MM = "0" + MM;
        if (ss < 10) ss = "0" + ss;
    
        const formattedToday =
          dd + "-" + mm + "-" + yyyy;
        return formattedToday;
      }
      function timeformate(Data) {
        const today = new Date(Data);
        const yyyy = today.getFullYear();
        let mm = today.getMonth() + 1;
        let dd = today.getDate();
        let hh = today.getHours();
        let MM = today.getMinutes();
        let ss = today.getSeconds();
        if (dd < 10) dd = "0" + dd;
        if (mm < 10) mm = "0" + mm;
        if (hh < 10) hh = "0" + hh;
        if (MM < 10) MM = "0" + MM;
        if (ss < 10) ss = "0" + ss;
    
        const formattedToday =
          hh + ":" + MM + ":" + ss;
        return formattedToday;
      }
    return (
        <div>
            <Dialog
                open={
                    props.open
                }
                fullWidth={true}
            >
                <DialogTitle className="dialog-header-wa">
                    <div className='dialog-header-wa-flex'>
                      
                        <>
                            {Format(props.channel+" Conversation")}
                        </>


                    </div>

                </DialogTitle>
                <DialogContent className="dialog-content">
                    <DialogContentText className={props.defaultclass}>
                    {history.length > 0 && (
                  <>
                    {history.map((value, i) => {
                      return (
                        <div key={i}>
                          {value.agent !== agent &&
                            value.msg_direction === 'out' && (
                              <div className="d-flex align-items-center justify-content-start">

                                  <>
                                    {value.attachment === 'text' && <span className="chat-request">
                                      {value.msg_text}{' '}
                                      <div className='timestamp'>{value.date_time.split('T')[0]}{" "} {moment(value.date_time.split('T')[1], ["HH.mm.ss"]).format("hh:mm a")}
                                    </div>
                                    </span>}
                                    {value.attachment === 'document' && <span className="chat-request">
                                      <a className='removedecoration' href={value.msg_text} download>Download Document</a>
                                      <div className='timestamp'>{value.date_time.split('T')[0]}{" "} {moment(value.date_time.split('T')[1], ["HH.mm.ss"]).format("hh:mm a")}
                                    </div>
                                    </span>}
                                    {value.attachment === 'template' && <span className="chat-request">
                                      {'Template Name: '} {value.msg_text}
                                      <div className='timestamp'>{value.date_time.split('T')[0]}{" "} {moment(value.date_time.split('T')[1], ["HH.mm.ss"]).format("hh:mm a")}
                                    </div>
                                    </span>}
                                    {value.attachment === 'image' && <span className="chat-request image-span">
                                      <img src={value.msg_text} alt="Image"></img>
                                      <div className='timestamp'>{value.date_time.split('T')[0]}{" "} {moment(value.date_time.split('T')[1], ["HH.mm.ss"]).format("hh:mm a")}
                                    </div>
                                    </span>}
                                    {value.attachment === 'video' && <span className="chat-request video-span">
                                      <video src={value.msg_text} controls>
                                        Your browser does not support the video tag.
                                      </video>
                                      <div className='timestamp'>{value.date_time.split('T')[0]}{" "} {moment(value.date_time.split('T')[1], ["HH.mm.ss"]).format("hh:mm a")}
                                    </div>
                                    </span>}
                                    {value.attachment === 'audio' && <span className="chat-request">
                                      <audio controls src={value.msg_text}>
                                        Your browser does not support the audio tag.
                                      </audio>
                                      <div className='timestamp'>{value.date_time.split('T')[0]}{" "} {moment(value.date_time.split('T')[1], ["HH.mm.ss"]).format("hh:mm a")}
                                    </div>
                                    </span>}


                                  </>

                               
                              </div>
                            )}

                          {value.msg_direction === 'in' && (
                            <div className="d-flex align-items-center justify-content-start">


                                <>
                                  {value.attachment === 'text' && <span className={props.channel === "whatsapp" ? "chat-request chat-user" : 'chat-request webchat-user'}>
                                    {value.msg_text}{' '}
                                    <div className='timestamp'>{dataformate(value.date_time)}{" "}{timeformate(value.date_time)}
                                    </div>
                                    
                                  </span>}
                                  {value.attachment === 'template' && <span className={props.channel === "whatsapp" ? "chat-request chat-user" : 'chat-request webchat-user'}>
                                    {'Template Name: '} {value.msg_text}
                                    <div className='timestamp'>{dataformate(value.date_time)}{" "}{timeformate(value.date_time)}
                                    </div>
                                  </span>}
                                  {value.attachment === 'document' && <span className={props.channel === "whatsapp" ? "chat-request chat-user" : 'chat-request webchat-user'}>
                                    <a className='removedecoration' href={value.msg_text} download>Download Document</a>
                                    <div className='timestamp'>{dataformate(value.date_time)}{" "}{timeformate(value.date_time)}
                                    </div>
                                  </span>}
                                  {value.attachment === 'image' && <span className={props.channel === "whatsapp" ? "chat-request chat-user image-span" : 'chat-request webchat-user image-span'}>
                                    <img src={value.msg_text} alt="Image"></img>
                                    <div className='timestamp'>{dataformate(value.date_time)}{" "}{timeformate(value.date_time)}
                                    </div>
                                  </span>}
                                  {value.attachment === 'video' && <span className={props.channel === "whatsapp" ? "chat-request chat-user video-span" : 'chat-request webchat-user video-span'}>
                                  <div className='timestamp'>{dataformate(value.date_time)}{" "}{timeformate(value.date_time)}
                                    </div>
                                    <video src={value.msg_text} controls>
                                      Your browser does not support the video tag.
                                    </video>                                
                                  </span>}
                                  {value.attachment === 'audio' && <span className={props.channel === "whatsapp" ? "chat-request chat-user" : 'chat-request webchat-user'}>
                                    <audio controls src={value.msg_text}>
                                      Your browser does not support the audio tag.
                                    </audio>
                                    <div className='timestamp'>{dataformate(value.date_time)}{" "}{timeformate(value.date_time)}
                                    </div>
                                  </span>}


                                </>

                              
                            </div>
                          )}

                          {value.agent === agent &&
                            value.msg_direction === 'out' && (
                              <div className="d-flex align-items-center justify-content-start flex-row-reverse">
                                <>
                                  {value.attachment === 'text' && <span
                                    className="chat-response"
                                    style={{ paddingRight: 10 }}
                                  >
                                    {value.msg_text}{' '}
                                  </span>}
                                  {value.attachment === 'template' && <span
                                    className="chat-response"
                                    style={{ paddingRight: 10 }}
                                  >
                                    {'Template Name: '} {value.msg_text}
                                  </span>}

                                  {value.attachment === 'document' && <span
                                    className="chat-response"
                                    style={{ paddingRight: 10 }}
                                  >
                                    <a className='removedecoration' href={value.msg_text} download>Download Document</a>
                                  </span>}
                                  {value.attachment === 'image' && <span
                                    className="chat-response image-span"
                                    style={{ paddingRight: 10 }}
                                  >
                                    <img src={value.msg_text} alt="Image"></img>
                                  </span>}
                                  {value.attachment === 'video' && <span
                                    className="chat-response video-span"
                                    style={{ paddingRight: 10 }}
                                  >
                                    <video src={value.msg_text} controls>
                                      Your browser does not support the video tag.
                                    </video>
                                  </span>}
                                  {value.attachment === 'audio' && <span
                                    className="chat-response"
                                    style={{ paddingRight: 10 }}
                                  >
                                    <audio controls src={value.msg_text}>
                                      Your browser does not support the audio tag.
                                    </audio>
                                  </span>}


                                </>


                              </div>
                            )}
                        
                        </div>
                      )
                    })}
                  </>
                )}
                        {history.length === 0 && (
                            // history.length === 0 &&
                            <>
                                <div className="chat-nodata lead-wa-nodata">
                                    No Chat
                                </div>
                            </>
                        )}
                    </DialogContentText>
                </DialogContent>
                <div className="dialog-buttons">


                    <>
                        <button
                            className="dialog-btn1" style={{ "backgroundColor": "red" }}
                            onClick={() => {
                                props.close(false)
                            }}
                        >
                            Close
                        </button>

                    </>

                </div>
            </Dialog>
        </div>
    )
}

export default DialogboxWa
import React, { useState, useEffect, useRef } from 'react'
import { TicketFetch } from '../../redux/actions/TicketAction'
import { useDispatch, useSelector } from 'react-redux'
import Format from '../../utils/format-text'
import EventEmitter from '../../utils/EventEmitter'
import { useParams } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useNavigate } from 'react-router'
import { Encode ,Decode} from '../../utils/EncodeDecode'
function OtherTickets(props) {
  let navigate = useNavigate()
  let AppPermission
  if (localStorage.getItem('config')) {
    AppPermission = JSON.parse(localStorage.getItem('config')).data.projects[0].userPermission
  }
  let { ticket_id,phone_number } = useParams()
  phone_number =Decode(phone_number)
  const [ticketdetail, setticketdetail] = useState([])
  const [count, setcount] = useState(0)
  const [infinitScroll, setinfinitScroll] = useState({ limit: 7, more: true })
  const [skipdata, setskipdata] = useState({ skip: 0, take: 5 })
  let stateValue = useSelector((state) => state)
  let dispatch = useDispatch()
  const agent = localStorage.getItem('user')
  useEffect(() => {
    if (props.type === "ticket") {
      ticketmount(props.id, skipdata)

    }
    else if (props.type === "contact") {
      contactticketmount(props.id, skipdata)

    }
    else {
      mount(stateValue.customform.formdata[0].lead_id, skipdata, stateValue.customform.formdata[0].phone_number)
    }

  }, [])

  function ticketmount(data, skipdatavalue, redirect) {
    let url = `/tickets/fetch?portal=false&All=false&linkedview=${data}&skip=${skipdatavalue.skip}&take=${data.split(",").length}`
    dispatch(TicketFetch(url))
      .then((response) => {

        if (response.status === 200) {

          setskipdata((set) => {
            set.skip = 0
            set.take = data.split(",").length
            return {
              ...set
            }
          })
          setcount(response.data.count)
          setskipdata((set) => {
            set.skip = set.skip + 5
            set.take = 10
            return {
              ...set
            }
          })
          if (redirect === "redirect") {
            setticketdetail([].concat(response.data.data[0].others))
          } else {
            setticketdetail(ticketdetail.concat(response.data.data[0].others))
          }

        } else {
          setticketdetail([].concat([]))
        }
      })
      .catch((err) => {
        EventEmitter.emit('error-message-getter', {
          action: 'fetch',
          module: 'ticket',
        })
      })
  }
  function mount(data, skipdatavalue, phone) {
    let url = `/tickets/fetch?req_id=${data}&portal=false&All=false&user=${agent}&phone_number=${phone}&ticketview=lead&skip=${skipdatavalue.skip}&take=${skipdatavalue.take}`
    dispatch(TicketFetch(url))
      .then((response) => {

        if (response.status === 200) {
          setcount(response.data.count)
          setskipdata((set) => {
            set.skip = set.skip + 5
            set.take = 10
            return {
              ...set
            }
          })
          setticketdetail(ticketdetail.concat(response.data.data[0].others))
        } else {
          setticketdetail([].concat([]))
        }
      })
      .catch((err) => {
        EventEmitter.emit('error-message-getter', {
          action: 'fetch',
          module: 'ticket',
        })
      })
  }
  function contactticketmount(data, skipdatavalue, ) {
    let url = `/tickets/fetch?req_id=${data}&portal=false&All=false&phone_number=${phone_number}&ticketview=contact&skip=${skipdatavalue.skip}&take=${skipdatavalue.take}`
    dispatch(TicketFetch(url))
      .then((response) => {

        if (response.status === 200) {
          setcount(response.data.count)
          setskipdata((set) => {
            set.skip = set.skip + 5
            set.take = 10
            return {
              ...set
            }
          })
          setticketdetail(ticketdetail.concat(response.data.data[0].others))
        } else {
          setticketdetail([].concat([]))
        }
      })
      .catch((err) => {
        EventEmitter.emit('error-message-getter', {
          action: 'fetch',
          module: 'ticket',
        })
      })
  }
  
  function navigatetoticket(ticket, phone) {
    navigate(`/tickets/${Encode(phone)}/${ticket}`)
  }

  const fetchMoreData = () => {
    if (ticketdetail.length >= count) {
      setinfinitScroll((set) => {
        set.more = false
        return {
          ...set,
        }
      })
    } else {
      setTimeout((v) => {
        let data = {
          skip: skipdata.skip + 5,
          take: 5,
        }
        if (props.type === "ticket") {
          ticketmount(props.id, skipdata)
    
        }
        else if (props.type === "contact") {
          contactticketmount(props.id, skipdata)
    
        }
        else {
          mount(stateValue.customform.formdata[0].lead_id, skipdata, stateValue.customform.formdata[0].phone_number)
        }
       
      }, 500)
    }
  }


  function timeSince(value) {
    let date = new Date(value)
    var seconds = Math.floor((new Date() - date) / 1000)

    var interval = seconds / 31536000

    if (interval > 1) {
      return Math.floor(interval) + ' year ago'
    }
    interval = seconds / 2592000
    if (interval > 1) {
      return Math.floor(interval) + ' month ago '
    }
    interval = seconds / 86400
    if (interval > 1) {
      return Math.floor(interval) + ' day ago'
    }
    interval = seconds / 3600
    if (interval > 1) {
      return Math.floor(interval) + ' hour ago'
    }
    interval = seconds / 60
    if (interval > 1) {
      return Math.floor(interval) + ' minute ago'
    }
    return Math.floor(seconds) + ' second ago'
  }
  return (
    <div style={{
      height: '100%',

    }}>
      <div
        id="ticketsDiv"
        style={{
          height: '100%',
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <InfiniteScroll
          scrollableTarget="ticketsDiv"
          dataLength={ticketdetail.length} //This is important field to render the next data
          next={fetchMoreData}
          hasMore={infinitScroll.more}
        >
          {ticketdetail.map((data, i) => {
            return (
              <div key={i} className="lead-meeting">
                <div className="lead-meeting-lft">
                  <div className="lead-ticket-header">
                    <div>
                      <strong
                     
                      >
                        {` #${data.ticket_id}   Ticket Assigned to ${Format(
                          data.user,
                        )}`}
                      </strong>
                    </div>

                    <div className="lead-ticket-subheader">
                    </div>
                    <div className="lead-ticket-subheader">
                      {`Last Modified By ${Format(data.modified_by)}`}
                    </div>
                  </div>
                  <div className="lead-meeting-subject">
                    <p className="lead-meeting-subject-p" style={{ marginBottom: 5 }}>{`Subject: ${Format(
                      data.subject,
                    )}`}</p>
                    {props.type !== "ticket" &&
                      <div className="lead-ticket-status">
                        <strong
                          className={
                            data.status === 'open'
                              ? 'lead-ticket-open'
                              : data.status === 'closed'
                                ? 'lead-ticket-closed'
                                : data.status === 'pending'
                                  ? 'lead-ticket-pending'
                                  : 'lead-ticket-others'
                          }
                        >
                          {Format(data.status)}
                        </strong>
                      </div>
                    }
                    {props.type === "ticket" &&
                      <div className="lead-ticket-status">
                        <strong
                          className={
                            data.is_duplicate === "true" ? "lead-ticket-pending" :
                              'lead-ticket-others'
                          }
                        >
                          {data.is_duplicate === "true" ? Format("Duplicate") : Format("Merged")}
                        </strong>
                      </div>
                    }
                  </div>
                </div>

                {(props.type == "lead" && AppPermission.lead_ticket_details || props.type == "meeting" && AppPermission.meeting_ticket_details || props.type == "ticket" && AppPermission.lead_ticket_details || props.type == "contact" && AppPermission.lead_ticket_details) && <div
                  onClick={() => {
                    if (props.type == "ticket") {

                      navigatetoticket(data.ticket_id, data.phone_number)
                      ticketmount(data.merged_to, { skip: 0 }, "redirect")
                      props.fetchapi("ticket")
                    } else {
                      navigatetoticket(data.ticket_id, data.phone_number)
                    }

                  }}
                  className="lead-meeting-rht lead-ticket-rht"
                >
                  <div className='timeline-head-rht'>{timeSince(data.created_at)}</div>
                  <span className="material-icons lead-call-icon lead-view-ticket-icon ">
                    confirmation_number
                  </span>
                </div>}
              </div>
            )
          })}
          {ticketdetail.length === 0 && (
            <div className="nodata " style={{ margin: '70px auto', width: '70px' }}>
              nodata
            </div>
          )}
        </InfiniteScroll>
      </div>
    </div>
  )
}

export default OtherTickets

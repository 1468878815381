const Regex_ = (string) => {

    let regexobj = {
        others: "",
        number:/^[0-9]{3,15}$/,
        phone_number: /^[0-9]{3,15}$/,
        email: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/,
        currency: "/^(?!0,?\d)([0-9]{2}[0-9]{0,}(\.[0-9]{2}))$/",
    }
    let value = regexobj[`${string}`]
    return value

}

export default Regex_
import * as actions from './BackgroundTypes';

export const setcalltype = data => {
    return {
        type: actions.INBOUND_WEBRTC,
        payload: data
    }
}
export const session = data => {
    return {
        type: actions.SESSION_WEBRTC,
        payload: data
    }
}


export const webrtcmanual = data => {
    return {
        type: actions.WEBRTC_MANUAL,
        payload: data
    }
}
export const socketmessage = data => {
    return {
        type: actions.SOCKET_MESSAGE,
        payload: data
    }
}
export const oncall_number = data => {
    return {
        type: actions.ONCALL_PHONENUMBER,
        payload: data
    }
}
export const oncall = data => {
    return {
        type: actions.ONCALL,
        payload: data
    }
}
export const networkstatus = data => {
    return {
        type: actions.NETWORK,
        payload: data
    }
}

export const triggerdispostate = data => {
    return {
        type: actions.TRIGGERDISPO,
        payload: data
    }
}
export const triggerreqpopup = data => {
    return {
        type: actions.TRIGGERREQPOPUP,
        payload: data
    }
}
export const inConference = data => {
    return {
        type: actions.INCONFERENCE,
        payload: data
    }
}

export const activeConference = data => {
    return {
        type: actions.ACTIVECONFERENCE,
        payload: data
    }
}
export const websocketstatus = data => {
    return {
        type: actions.WEBSOCKETSTATUS,
        payload: data
    }
}


export const socketconnection = (socket) =>{
    return {
        type: actions.WEBSOCKETCONNECTION,
        payload: socket
    }
}

export const callringtone = (socket) =>{
    return {
        type: actions.CALLRINGTONE,
        payload: socket
    }
}
export const callLeadDetail = (socket) =>{
    return {
        type: actions.CALLLEADDETAIL,
        payload: socket
    }
}
export const tempData = (data)=>{
    return {
        type: actions.TEMPDATA,
        payload: data
    }
}

export const ON_CALL = 'ON_CALL';
export const INCOMING = 'INCOMING';
export const ON_DIAL = 'ON_DIAL';
export const ON_LOG = 'ON_LOG';
export const BEFORECALLNAVPATH='BEFORECALLNAVPATH';
export const AUTODIALSTATUS ="AUTODIALSTATUS";
export const AUTODIALDATAS = "AUTODIALDATAS"
export const POP_AUTODIALDATA= "POP_AUTODIALDATA";
export const DISPOVALIDATION = "DISPOVALIDATION";
export const CALL_MODULE="CALL_MODULE";
export const CHATDISPOVALIDATION = "CHATDISPOVALIDATION";
export const AUTODIALPLAYPARSE ="AUTODIALPLAYPARSE";
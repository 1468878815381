import {
  INBOUND_WEBRTC,
  SESSION_WEBRTC,
  WEBRTC_MANUAL,
  SOCKET_MESSAGE,
  ONCALL,
  INCONFERENCE,
  WEBSOCKETSTATUS,
  ACTIVECONFERENCE,
  WEBSOCKETCONNECTION,
  CALLRINGTONE,
  TRIGGERDISPO,TRIGGERREQPOPUP,
  CALLLEADDETAIL,ONCALL_PHONENUMBER, TEMPDATA,NETWORK
} from "./BackgroundTypes";
const initialState = {
  calltype: "",
  session: {},
  webrtcManual: false,
  socket_message: {},
  oncall: false,
  networkstatus:"online",
  oncall_phonenumber:"",
  conference: [],
  websocket: {
    WrtcStatus: false,
    WsSocketStatus: false
  },
  ringing:false,
  activeconference: false,
  websocketconnection: {},
  audio: new Audio(`./../../../sounds/dv2-ringtone.wav`),
  isplay: false,
  triggerdispo:false,
  triggerreqpopup:false,
  oncalllead:[
    {
      lead_id: "",
      lead_name: "",
      phone_number: "",
      module: "",
      render:0
    }
  ],
  tempdata:{
    type:"",
    data:{}
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case INBOUND_WEBRTC:
      
      return {
        ...state,
        calltype: action.payload,
       

      };
    case SESSION_WEBRTC:
      return {
        ...state,
        session: action.payload,
      };
    case WEBRTC_MANUAL:
      return {
        ...state,
        webrtcManual: action.payload,
      };
    case ONCALL:
      return {
        ...state,
        oncall: action.payload,
      };
      case NETWORK:
        console.log("NETWORK===========>",action);
        return {
          ...state,
          networkstatus:action.payload
        };
    case ONCALL_PHONENUMBER:
      return {
        ...state,
        oncall_phonenumber: action.payload,
      };
    case INCONFERENCE:
      let array = state.conference;
      if (action.payload.activity === "push") {
        array.push(action.payload.data);
      } else if (action.payload.activity === "remove") {
        array = [];
      } else {
        let popdata = [];
        array.forEach(value => {
          if (value.party_number !== action.payload.data.party_number) {
            popdata.push(value);
          }
        });
        array = [...popdata];
      }
      return {
        ...state,
        conference: array,
      };
    case SOCKET_MESSAGE:
      let obj = {};
      if(action.payload.call_type==="xfer"){
        action.payload.calling_type = "xfer"
      }
      if (action.payload.evt === "RINGING") {

        obj["userChannel"] = "";
        obj["agentChannel"] = "";
        obj["subject"] = action.payload.subject;
        obj["calltype"] = action.payload.calling_type;
        obj["ringing_time"] = action.payload.calltime;
        obj["station"] = action.payload.extension;
        obj["phonenumber"] = action.payload.phonenumber;
        obj["uniqueid"] = action.payload.uniqueid;
        obj["evt"] = "RINGING";
        obj["oncalltime"] = "";
        obj["endtime"] = "";
        obj["stage1"] = "RINGING";
        obj["stage2"] = "";
        obj["stage3"] = "";
        obj['duration'] =state.socket_message.duration
        if(action.payload.call_type == 'AUTO'){
          obj["calltype"] = action.payload.call_type;
        }

      } else if (action.payload.evt === "ANSWERED") {
        obj["userChannel"] = action.payload.caller_channel;
        obj["agentChannel"] = action.payload.agent_channel;
        obj["subject"] = action.payload.subject;
        obj["calltype"] = action.payload.calling_type;
        obj["ringing_time"] = state.socket_message.ringing_time;
        obj["station"] = action.payload.extension;
        obj["phonenumber"] = action.payload.phonenumber;
        obj["uniqueid"] = action.payload.uniqueid;
        obj["evt"] = "ANSWERED";
        obj["stage1"] = "RINGING";
        obj["stage2"] = "ANSWERED";
        obj["stage3"] = "";
        obj["oncalltime"] = action.payload.answer_time;
        obj["endtime"] = "";
        obj['duration'] =state.socket_message.duration
        if(action.payload.call_type == 'AUTO'){
          obj["calltype"] = action.payload.call_type;
        }

      } else if (action.payload.evt === "DISPO") {
        obj["userChannel"] = state.socket_message.userChannel;
        obj["agentChannel"] = state.socket_message.agentChannel;
        obj["subject"] = action.payload.subject;
        obj["calltype"] = action.payload.calling_type;
        obj["ringing_time"] = state.socket_message.ringing_time;
        obj["station"] = action.payload.extension;
        obj["phonenumber"] = action.payload.phonenumber;
        obj["uniqueid"] = action.payload.uniqueid;
        obj["evt"] = "DISPO";
        obj["oncalltime"] = state.socket_message.oncalltime;
        obj["endtime"] = action.payload.end_time;
        obj["stage1"] = state.socket_message.stage1;
        obj["stage2"] = state.socket_message.stage2;
        obj["stage3"] = "DISPO";
        obj['duration'] =state.socket_message.duration
        if(action.payload.call_type == 'AUTO'){
          obj["calltype"] = action.payload.call_type;
        }

      } else if (action.payload.evt === "endcall") {
        if (state.socket_message.evt == "RINGING") {
          obj = { ...state.socket_message }
          obj['duration'] = "00:00:00"
        } else {
          obj = { ...state.socket_message }
          obj['duration'] = action.payload.duration
        }

      } else if (action.payload.evt === "whatsapp") {
        obj["phonenumber"] = action.payload.phonenumber;
      }
      return {
        ...state,
        socket_message: obj,
      };
    case WEBSOCKETSTATUS:
      let websocketstatus = state.websocket;
      if (action.payload.hasOwnProperty("WsSocketStatus")) {
        websocketstatus.WsSocketStatus = action.payload.WsSocketStatus;
      } else if (action.payload.hasOwnProperty("WrtcStatus")) {
        websocketstatus.WrtcStatus = action.payload.WrtcStatus;
      }

      return {
        ...state,
        websocket: websocketstatus,
      };

    case ACTIVECONFERENCE:


      return {
        ...state,
        activeconference: action.payload,
      };
    case WEBSOCKETCONNECTION:
      return {
        ...state,
        websocketconnection: action.payload
      };
    case CALLRINGTONE:
        if (action.payload) {
          console.log(action.payload,"7777777777777777")
          state.ringing = action.payload
          console.log(action.payload,"7777777777777777")
          state.audio.play()
        } else {
          console.log(action.payload,"777777777777777711")
          state.ringing = action.payload
          state.audio.pause()
          state.audio.currentTime = 0
        }
        return {
          ...state,
          isplay: action.payload
        };
    case TRIGGERDISPO:
      return {
        ...state,
        triggerdispo: action.payload
      };
      case TRIGGERREQPOPUP:
        return {
          ...state,
          triggerreqpopup: action.payload
        };
    case CALLLEADDETAIL:
      return {
        ...state,
        oncalllead: [action.payload]
      };

    case TEMPDATA:
        return {
          ...state,
          tempdata: action.payload
        };

    default:
      return state;
  }
};
export default reducer;

import React from 'react'
import { Container, Form, Button } from 'react-bootstrap'
function ForgotPassword() {
    return (
        <Container fluid style={{padding:0}}>
            <div
                className="d-flex align-items-center justify-content-center"
                style={{ height: "100vh", background: "#e5e5e5" }}
            >
                <div className="box1" style={{ width: '370px' }}>
                    <h3 className="title">Forgot Password</h3>


                    <div className=" row " style={{ padding: '0 20px' }}>

                        <div className=" col-md-12 mt-4">
                            <Form.Control type="text"  placeholder="Phone Number" />
                        </div>
                    </div>

                    <div style={{ padding: "20px" }}>
                        <Button variant="primary" type="submit" className="mb-3">
                            Submit
                        </Button>
                    </div>

                </div>
            </div>
        </Container>
    )
}

export default ForgotPassword

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Form, Button, Carousel } from 'react-bootstrap';
import '../assets/css/App.css';
import gtawk from '../assets/images/gtawk.png'
import logo from '../assets/images/logo.png';
import Skyfall from '../assets/images/SKyfall logo.png'
import google from "../assets/images/google.png";
import clogo from "../assets/images/image_3.png";
import img from '../assets/images/mfa_illustration2x.png';
import img1 from '../assets/images/recovery_illustration2x.png';
import { setLogin } from '../redux/Login/LoginAction';
import { setCampaign, setUser, SetDid } from '../redux/Details/DetailAction';
import { connect, useDispatch } from 'react-redux';
import jwtDecode from 'jwt-decode';
import localdomain from "../utils/constant"
import EventEmitter from '../utils/EventEmitter';
import { Asterlogin, AsterCampaigns, AsterConfig, LoginUserinfo, AuthUserinfo } from "../redux/actions/login"
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
const auth0Config = localdomain();
const common_api_url = auth0Config.login;

function Login(props) {
    let dispatch = useDispatch()
    let navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    useEffect(() => {
        if (localStorage.getItem('apinfo') === null || localStorage.getItem('extension')===null ) {
            let revertstatus = localStorage.getItem("revertload")
            localStorage.removeItem('pauseTime')
            localStorage.removeItem('revertload')
            localStorage.removeItem('loginBreak')
            localStorage.removeItem('callcount')
            localStorage.removeItem('dialpadname')
            localStorage.removeItem('webrtcManual')
            localStorage.removeItem('list')
            localStorage.removeItem('chatactive')
            localStorage.removeItem("wasession")
            localStorage.removeItem('user')
            localStorage.removeItem('did')
            localStorage.removeItem('extension')
            localStorage.removeItem('apinfo')
            localStorage.removeItem('selectedCampaign_data')
            localStorage.removeItem('autodialtimer')
            localStorage.removeItem('dial_status')
            localStorage.removeItem('pauseCode')
            localStorage.removeItem('loggedIn')
            localStorage.removeItem('campaigns')
            localStorage.removeItem('config')
            localStorage.removeItem('username')
            localStorage.removeItem('loginTime')
            localStorage.removeItem('isEdit')
            localStorage.removeItem('isEditfromLead')
            localStorage.removeItem('dispo')
            localStorage.removeItem('socketConnectCount')
            localStorage.removeItem('AuthUserinfo')
            localStorage.removeItem("oncustomform")
            localStorage.removeItem('queues')
            localStorage.removeItem('dispostatus')
            localStorage.removeItem('selectedPause')
            localStorage.removeItem('selectedQueue')
            localStorage.removeItem('script_name')
            localStorage.removeItem('loginTime')
            localStorage.removeItem('selectedCampaigns')
            localStorage.removeItem('dashboardrefresh')
            localStorage.removeItem('webrtc')
            localStorage.removeItem('loadcount')
            localStorage.removeItem('customformdata')
            localStorage.removeItem('waformdata')
            localStorage.removeItem('selectedCampaign')
            localStorage.removeItem("callstatus")
            localStorage.removeItem("oncalldata")
            localStorage.removeItem("showdispo")
            localStorage.removeItem("callactionTrigger")
            localStorage.removeItem('module_back')
            localStorage.removeItem('process')
            localStorage.removeItem('callcontroll')
            localStorage.removeItem("breakoverdue")
            localStorage.removeItem("call_recording")
            localStorage.removeItem("parking")
            localStorage.removeItem("Teaminfo")
            localStorage.removeItem("campaigndetails")
            if (revertstatus !== null && revertstatus == "true") {
                window.location.reload()
            }
        } else {
            navigate('/dashboard');
        }
    }, [])

    const routing = (data, Permissions) => {

        let AppPermissions = Permissions.data.projects[0].userPermission
        dispatch(AuthUserinfo(data)).then(function (response) {
            if (response.data.name !== "") {
                let payload = {
                    avatar_url: response.data.picture,
                    name: response.data.nickname,
                    user: response.data.name
                }
                localStorage.setItem("AuthUserinfo", JSON.stringify(payload))
                localStorage.setItem("username", response.data.nickname)
                navigate('/select');
                localStorage.setItem("callcontroll", JSON.stringify({
                    mute: false,
                    hold: false,
                    transfer: false,
                    conf: false,
                    dialpad: false
                }))


                // if (AppPermissions.view_Performance) {
                //     navigate('/dashboard')
                // } else if (AppPermissions.lead_view) {
                //     navigate("/leads")
                // } else if (AppPermissions.ticket_view) {
                //     navigate("/tickets")
                // } else if (AppPermissions.view_contacts) {
                //     navigate("/contact")
                // } else if (AppPermissions.view_meeting) {
                //     navigate("/meeting")
                // } else if (AppPermissions.voicemail_view) {
                //     navigate("/voicemail")
                // }

            }


        }).catch((err) => {
            setLoading(false)
            EventEmitter.emit('error-message-getter', {
                action: 'login',
                module: 'login',
            })


        });
    }
    const handleEmailChange = (value) => {
        setEmail(value);
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setEmailError(emailRegex.test(value) ? '' : 'Please enter the required format');
    };
    const handleSubmit = async (event) => {
        setLoading(true)
        const email = event.target[0].value.toLowerCase();
        const password = event.target[1].value;
        event.preventDefault();

        if (email === ''||!email || emailError||!password) {
            setLoading(false)
            EventEmitter.emit('error-message-getter', {
                action: 'email',
                module: 'email',
            })
        }

        else if (password === '') {
            setLoading(false)
            EventEmitter.emit('error-message-getter', {
                action: 'password',
                module: 'password',
            })
        }
        else {
            let payload = {
                username: email,
                password: password
            }
            dispatch(Asterlogin(payload))
                .then(function (response) {

                    if (response.data.statusCode === 200) {
                        setLoading(false)
                        const tok = {
                            loggedIn: true,
                            token: response.data.access_token
                        }
                        props.set_login(tok);



                        const decoded = jwtDecode(response.data.access_token);
                        let payloadData = {};
                        payloadData['tenant_code'] = `${decoded['http://api.doocti.com/claims/tenant_code']}`
                        payloadData['user_email'] = email
                        dispatch(AsterConfig(payloadData))
                            .then(function (res) {
                                if (res.status === 200) {
                                    let domains = res.data.projects[0].config[0]
                                    console.log(domains,"eeeeeeeeeeeeeeeeeee")
                                    let apinfo = {
                                        tenant_code: `${decoded['http://api.doocti.com/claims/tenant_code']}`,
                                        api_url: 'https://'+domains.api_domain,
                                        dialer_url: 'https://'+domains.dialer_domain,
                                        portal_url:'https://'+domains.portal_domain,
                                        token: response.data.access_token,
                                        socket_url:'https://'+domains.socket_domain,
                                        AuthUser: `${decoded[`aud`][1]}`,
                                        sub_version: res.data.projects[0].config[0].sub_version
                                    }
                                    console.log(apinfo,"eeeeeeeeeeeeeeeeeee")
                                    localStorage.setItem("apinfo", JSON.stringify(apinfo))
                                    let data = JSON.stringify(res)
                                    localStorage.setItem("config", data)
                                    dispatch(AsterCampaigns(email, apinfo))
                                        .then(function (res__) {
                                            var temp = res__.data.data;
                                            var campaigns = [];

                                            temp.forEach(element => {
                                                campaigns.push(element.name);
                                            });
                                            props.set_campaign(campaigns);
                                            props.set_user(email);

                                            localStorage.setItem("Teaminfo", JSON.stringify(res__.data.Teaminfo))
                                            routing(apinfo.AuthUser, res)
                                        })
                                        .catch((err) => {
                                            setLoading(false)
                                            EventEmitter.emit('error-message-getter', {
                                                action: 'fetch',
                                                module: 'campaign',
                                            })
                                        })

                                }
                            })
                    }
                    else if (response.data.statusCode === 403) {
                        setLoading(false)
                        EventEmitter.emit('error-message-getter', {
                            action: 'invalid',
                        })
                    }
                })
                .catch((err) => {
                    setLoading(false)
                    EventEmitter.emit('error-message-getter', {
                        action: 'invalid',
                    })

                });
        }
    }
    const domain_name = () => {
        const regex = /doocti/g;
        const matches = window.location.hostname.match(regex);
        if(matches!==null && matches[0]==="doocti"){
            return true
        }
        return false
    }

    return (
        <>
            <div className='mainlogin'>
                <Col className='lftlogin' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Carousel>
                        <Carousel.Item interval={1000}>
                            <img
                                src={img}
                                alt="First slide"
                            />
                            <Carousel.Caption>
                                <h3>Social Media Is Here</h3>
                                <p>Manage Customer Queries Across Channels Effortlessly</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item interval={500}>
                            <img

                                src={img1}
                                alt="Second slide"
                            />
                            <Carousel.Caption>
                                <h3> Inbuild CRM With Cloud Telephony</h3>
                                <p>Manage Your Sales And Support Process</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        {/* <Carousel.Item interval={500}>
                            <img

                                src={img}
                                alt="Third slide"
                            />
                            <Carousel.Caption>
                                <h3>Third slide label</h3>
                                <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
                            </Carousel.Caption>
                        </Carousel.Item> */}
                    </Carousel>
                </Col>
                <div className="rhtlogin" >
                    <div className="d-flex flex-column align-items-center">
                        <div style={{ textAlign: "left", "backdrop-filter": "blur(5px)", padding: "10px", "min-width": "450px" }}>
                        {domain_name()&&<h3 style={{ "text-align": "center" }}>
                                <img src={logo} alt="Text" style={{ maxWidth: 180 }} />
                            </h3>}
                            {domain_name()===false&&window.location.hostname.match('web.gtawk')&&<h3 style={{ "text-align": "center" }}>
                                <img src={gtawk} alt="Text" style={{ maxWidth: 180 }} />
                            </h3>}
                            {domain_name()===false&&window.location.hostname.match('web-dev.doocti')&&<h3 style={{ "text-align": "center" }}>
                                <img src={logo} alt="Text" style={{ maxWidth: 180 }} />
                            </h3>}
                            {domain_name()===false&&window.location.hostname.match('agent.skyfalltech')&&<h3 style={{ "text-align": "center" }}>
                                <img src={Skyfall} alt="Text" style={{ maxWidth: 180 }} />
                            </h3>}

                            <div className='loginborder'>
                                <h3 className="mt-1 heading">Login</h3>

                                <div className="line"></div>

                                <Form onSubmit={handleSubmit}>

                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>UserEmail</Form.Label>
                                        <Form.Control type="email" placeholder="yours@example.com"  value={email}
                                        onChange={(e) => handleEmailChange(e.target.value)} />
                                         <div className="error-message_">{emailError }</div>

                                    </Form.Group>

                                    <Form.Group className="mb-5" controlId="formBasicPassword">
                                 <Form.Label>Password</Form.Label>
                                    <div className="password-input-container">
                                    <Form.Control
                                        type={showPassword ? "text" : "password"}
                                        placeholder="Password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <div className="password-icon" onClick={() => setShowPassword(!showPassword)}>
                                    {showPassword ? <MdVisibility /> : <MdVisibilityOff />}
                                    </div>
                                    </div>
                                </Form.Group>

                                <Button disabled={isLoading || !email||emailError||!password} variant="primary" type="submit" className="mb-3">
                                        {isLoading ? 'Loading…' : "Submit"}
                                    </Button>
                                </Form>


                                {/* <p className="forgot" onClick={() => navigate('/forgot')}>Forgot Password?</p> */}
                            </div>
                        </div>
                        {domain_name()&&<p className="copy">@ 2021 doocti, All rights Reserved</p>}
                    </div>

                </div>
            </div>
        </>
    );
}
const mapStateToProps = (state) => {
    return {
        loggedIn: state.login.loggedIn,
        token: state.login.token
    }
}
const mapDispatchToProps = dispatch => {
    return {
        set_login: (type) => dispatch(setLogin(type)),
        set_campaign: (type) => dispatch(setCampaign(type)),
        set_user: (type) => dispatch(setUser(type)),
        set_did: (type) => dispatch(SetDid(type))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Login)
import { logDOM } from "@testing-library/react";
import api from "../../utils/axios-util";

export const fetch_meetings = (query) => {
    let  filter="";
    if(query.filter){
       filter +='&filter='+query.filter;
    }
    if(query.meetingID !== undefined){
        filter='&meetingID='+query.meetingID;
     }
     if(query.module !== undefined){
        filter +='&module='+query.module;
     }
     if(query.phone_number !== undefined){
        filter +='&phone_number='+query.phone_number;
     }
     if(query.status !== undefined){
        filter +='&status='+query.status;
     }
    let dateFilter=""
    if(query.fromDate != undefined && query.toDate != undefined){
        dateFilter = `&fromDate=${query.fromDate}&toDate=${query.toDate}`
    }
   
     return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
            await api
                .get(`/asterdialer/meetings?userID=${query.userID}${filter}&take=${query.take}&skip=${query.skip}${dateFilter}`)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
};

export const editMeeting = (query,payload) => {
   return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
            await api
                .put(`/meetings/update/${query}`,payload)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

};
export const create_meeting = (payload) => {
     return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
            await api
                .post(`/asterdialer/meeting/schedule`,payload)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

};
export const Reassign_User = (payload) => {
    return (dispatch, getState) => {
       return new Promise(async (resolve, reject) => {
           await api
               .post(`/action/event`,payload)
               .then((response) => {
                   resolve(response);
               })
               .catch((err) => {
                   reject(err);
               });
       });
   }

};
export const Meetingtitle = () => {
    return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
            await api
                .get(`/title/fetch?is_active=1&module=meeting`)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

};
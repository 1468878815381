import * as actions from './LeadTypes';

export const setLead = (data) => {
    return {
        type: actions.SET_LEADS,
        payload: data
    }
}
export const setaudio = (data) => {
    return {
        type: actions.SET_AUDIO,
        payload: data
    }
}

export const playaudio = (state,value) => {
    
    return {
        type: actions.SETPLAY_SOUND,
        payload: value
    }
}

import {
  ON_CALL,
  INCOMING,
  ON_DIAL,
  ON_LOG,
  BEFORECALLNAVPATH,
  AUTODIALDATAS,
  AUTODIALSTATUS,
  POP_AUTODIALDATA,
  DISPOVALIDATION,
  CALL_MODULE,
  CHATDISPOVALIDATION,
  AUTODIALPLAYPARSE,
} from "./CallTypes";
const initialState = {
  loading: false,
  callaction: false,
  type: "",
  dialpadnumber: "",
  beforecallNavigatePath: "",
  autodialstatus: false,
  autodialdatas: [],
  dispoValidation: true,
  chatdispoValidation:true,
  callmodule:{
    id:"",
    module:"",
    phone_no:"",
    auto:false
  },
  autodialplayparsebtn:true,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ON_LOG:
      return {
        ...state,
        loading: false,
        callaction: false,
        type: action.payload,
        dialpadnumber: state.dialpadnumber,
        beforecallNavigatePath: state.beforecallNavigatePath
      };
    case ON_DIAL:
      return {
        ...state,
        loading: false,
        callaction: true,
        type: action.payload,
        dialpadnumber: state.dialpadnumber,
        beforecallNavigatePath: state.beforecallNavigatePath
      };
    case ON_CALL:
      return {
        ...state,
        loading: false,
        callaction: true,
        type: action.payload,
        dialpadnumber: state.dialpadnumber,
        beforecallNavigatePath: state.beforecallNavigatePath
      };
    case INCOMING:
      return {
        ...state,
        loading: false,
        callaction: true,
        type: action.payload,
        dialpadnumber: state.dialpadnumber,
        beforecallNavigatePath: state.beforecallNavigatePath
      };
    case BEFORECALLNAVPATH:
      return {
        ...state,
        loading: false,
        callaction: state.callaction,
        type: state.type,
        dialpadnumber: state.dialpadnumber,
        beforecallNavigatePath: action.payload
      };


    case AUTODIALSTATUS:
      return {
        ...state,
        autodialplayparsebtn:action.payload == false? true : state.autodialplayparsebtn,
        autodialstatus: action.payload,
      };
    case AUTODIALDATAS:
      return {
        ...state,
        autodialdatas: action.payload
      }
    case AUTODIALPLAYPARSE:
      return {
        ...state,
        autodialplayparsebtn: !state.autodialplayparsebtn
      }
    case POP_AUTODIALDATA:
      return {
        ...state,
        autodialdatas: action.payload
      }
    case CHATDISPOVALIDATION:
      return {
        ...state,
        chatdispoValidation:action.payload
      }

      case DISPOVALIDATION:
      return {
        ...state,
        dispoValidation:action.payload
      }
    case CALL_MODULE:
      return {
        ...state,
        callmodule:{
          id:action.payload.id,
          module:action.payload.module,
          phone_no:action.payload.phone_no,
          auto:action.payload.auto
        }
      }
    
    default:
      return state;
  }
};
export default reducer;
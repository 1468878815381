import React from 'react'
import { useSelector } from 'react-redux';
import Dialpad from './CallDialPad'

function TransferOthersCall(props) {
    const station = localStorage.getItem("extension")
    var tenant = '';
    if (localStorage.getItem("config")) {
        tenant = JSON.parse(localStorage.getItem("config")).data.tenant_code
    }
    const agent = localStorage.getItem("user")
    let stateValue = useSelector((state) => state)



    function transferOthers(value) {
        if (props.agentActionType === "transfer") {
            let data = {
                action: 'Transfer',
                uniqueid: stateValue.background.socket_message.uniqueid,
                caller_channel: stateValue.background.socket_message.userChannel,
                agent_channel: stateValue.background.socket_message.agentChannel,
                xfer_type: 'ATTENDED',
                xfer_to: 'others',
                xfer_dest: value,
                agent: agent,
                phone_number: stateValue.background.socket_message.phonenumber,
                station: station,
                tenant_id: tenant,
            };

            props.othertransfer(data)
        }
        if (props.agentActionType === "conference") {
            let data = {
                action: "conference",
                add_number: value,
                station: station,
                tenant_id: tenant,
                uniqueid: stateValue.background.socket_message.uniqueid,
                caller_channel: stateValue.background.socket_message.userChannel,
                live_channel: stateValue.background.socket_message.agentChannel,
            }
            props.otherconference(data)
        }
    }
    return (
        <>
            <div style={{ position: 'relative' }}>

                <div className='othercall_dialpad'>
                    <Dialpad calllogdialpad={true} dialpadnumber_transfer={transferOthers} othersdialpad={true}></Dialpad>
                </div>


            </div>
        </>
    )
}

export default TransferOthersCall;
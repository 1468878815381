
export const INBOUND_WEBRTC = 'INBOUND_WEBRTC';
export const SESSION_WEBRTC = 'SESSION_WEBRTC';
export const WEBRTC_MANUAL = 'WEBRTC_MANUAL';
export const SOCKET_MESSAGE = 'SOCKET_MESSAGE';
export const ONCALL = "ONCALL";
export const ONCALL_PHONENUMBER ="ONCALL_PHONENUMBER";
export const INCONFERENCE = "INCONFERENCE";
export const WEBSOCKETSTATUS = "WEBSOCKETSTATUS";
export const ACTIVECONFERENCE = "ACTIVECONFERENCE";
export const WEBSOCKETCONNECTION = "WEBSOCKETCONNECTION";
export const CALLRINGTONE = "CALLRINGTONE";
export const TRIGGERDISPO="TRIGGERDISPO";
export const CALLLEADDETAIL = "CALLLEADDETAIL";
export const TRIGGERREQPOPUP="TRIGGERREQPOPUP"
export const TEMPDATA = "TEMPDATA"
export const NETWORK = "NETWORK"

import React, { useMemo } from "react";
import { Table } from "react-bootstrap";
import { useTable, useRowSelect } from "react-table";
import { autodial, autodialdatas } from "../redux/Calls/CallAction";
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom'
import LoadingSpinner from './LoadingSpinner'
import EventEmitter from "../utils/EventEmitter"
import Format from "../utils/format-text";
const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);


function Table1({ checkboxvalue, columns, data, selected_data, maskphoneindex , loader}) {
  let AppPermission

  if (localStorage.getItem('config')) {
    AppPermission = JSON.parse(localStorage.getItem('config')).data.projects[0].userPermission
  }
  const location = useLocation()
  let dispatch = useDispatch()
  let stateValue = useSelector((state) => state);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, selectedFlatRows } =
    useTable(
      {
        columns,
        data,
      },
      useRowSelect,
      (hooks) => {
        if (location.pathname === `/leads` && AppPermission.auto_dial) {
          hooks.visibleColumns.push((columns) => [
            {
              id: "selection",
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <div>
                  {
                    checkboxvalue === true &&

                    < IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                  }
                </div>
              ),
              Cell: ({ row }) => (

                <div>
                  {
                    checkboxvalue === true &&
                    <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                  }
                </div>
              ),
            },
            ...columns,
          ]);


        }
      }
    );


  useMemo(() => {

    let selectedvalue = selectedFlatRows.map((elm) => elm.original)
    if (selectedFlatRows.length > 0 && stateValue.call.autodialstatus == false && stateValue.background.oncall == false) {
      selected_data(selectedvalue)
      dispatch(autodialdatas(selectedvalue))
      EventEmitter.emit("auto-dial", "true")
    } else if (selectedFlatRows.length == 0 && stateValue.call.autodialstatus == false) {
      dispatch(autodialdatas(selectedvalue))
      EventEmitter.emit("auto-dial", "false")
    }
  }, [selectedFlatRows])

  function celldata(params, i) {
   
    if (i === 0 && params.props.column.Header === "Profile") {
      let url = params.props.cell.value
      if(url !==""){
        return (
          <>
            <img className="img_profile" src={url} alt="test" />
          </>
        )
      }else{
        <p>{params}</p>
      }
      
    } else {
      if (i == maskphoneindex.index) {
        params.props.row.values[maskphoneindex.value] = Format(params.props.row.values[maskphoneindex.value], { maskbool: true })
      }
      return (<>
        <p >{params}</p>
      </>)
    }

  }


  const dynamicStyles = {
    color: true ? 'green' : 'red',
    fontSize: true ? '16px' : '12px',
    // Add more styles as needed
  };


  return (
    <>
      <Table className="lead" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        {rows.length > 0 && loader == false && <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} >
                {row.cells.map((cell, j) => {
                   console.log("params.props.column",)
                  return (
                    <td   className={j === 0 ? "min-td" : ""}  {...cell.getCellProps()}>
                      <div style={{
                      backgroundColor: cell.render("Cell").props.column.color ? data[i].all?.phone_number_color?.code : 'white',
                      fontSize: true ? '16px' : '12px',
                      borderRadius:"5px",
                      color:cell.render("Cell").props.column.color ? data[i].all?.phone_number_color?.text_color : 'black',
                      paddingTop:'7px',
                      paddingRight:"5px",
                      paddingLeft:"5px"
                      // Add more styles as needed
                    }}   className="txt-ohide">
                        {celldata(cell.render("Cell"), j)}
                      </div>

                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>}

      </Table>
      { loader == true && <LoadingSpinner size="larger" />}
      {rows.length === 0 && loader == false && 
        <div className="nodata" style={{ "margin": "auto" }}>No Data</div>


      }

    </>
  );
}

export function LeadTable(props) {
  const value = props.leaddetail;
  const columns = React.useMemo(() => props.header, []);
  function selected_data(data) {
  }

  return (
    <>
      <Table1 maskphoneindex={props.maskphoneindex} checkboxvalue={props.checkbox} columns={columns} data={value} selected_data={selected_data} loader={props.loader}/>
    </>
  );
}

export default LeadTable;




import CryptoJS from 'crypto-js';

const Encode = (payload) => {
    if(payload !== "" &&payload !== undefined){
        const enbase64data = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(payload));
        return enbase64data
    }
    
};



const Decode = (payload) => {
    if(payload !== "" && payload !== undefined){
        const debase64data = CryptoJS.enc.Base64.parse(payload).toString(CryptoJS.enc.Utf8);
        return debase64data
    }
    
};

export { Encode, Decode };
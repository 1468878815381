import React, { useState, useEffect, useMemo } from 'react'
import wp from '../assets/images/wp.png'
import warning from '../assets/images/warning.svg'
import Toast from 'react-bootstrap/Toast'
import ToastContainer from 'react-bootstrap/ToastContainer'
import fromat from '../utils/format-text'
import EventEmitter from "../utils/EventEmitter"
function Toster(props) {
  const [showsnackbar, setshowsnackbar] = useState(false)

  useMemo(() => {
    if (props.errormessage !== "" && props.errormessage !== undefined) {

      setshowsnackbar(true)
    }

  }, [props.errormessage])

  function setShow(value, note) {
   
    props.close(value)
    if (note) {
      props.note(note)
      
    }
  }
  function autoclose() {
    setshowsnackbar(false)
    EventEmitter.emit("error-message-getter-response")
  }
  return (
    <div>
      {props.type !== 'snackbar' && (
        <ToastContainer position="top-end">
          <Toast
            bg={'light'}
            onClose={() => setShow(false)}
            show={props.show}
            // autohide
            // delay={props.delay}
          >
            <Toast.Header closeButton={false} className={props.HeaderClass}>
            
              {props.HeaderClass==="whatsapp" && <img className={`${props.HeaderClass}_img`} src={wp} alt="test" />}
              {props.HeaderClass==="meeting"&&<span
                className={ `material-icons ${props.HeaderClass}_img`}
              >
                group
              </span>}
              {props.HeaderClass==="tickets"&&<span
                className={ `material-icons ${props.HeaderClass}_img`}
              >
                confirmation_number
              </span>}
              <strong className="me-auto">{fromat(props.header)}</strong>
              <small>Now</small>
            </Toast.Header>
            <Toast.Body>
              <div className="Toast_Body">
                {fromat(props.tosterdata.header1,{maskbool:true})}
              </div>
              <div className="Toast_Body">
                {fromat(props.tosterdata.header2)}
              </div>
              <div className="Toast_Body">
                {fromat(props.tosterdata.header3)}
              </div>
              <div className="tost-button">
                {props.button_txt.cancel && (
                  <button
                    onClick={() => setShow(false, props.button_value.cancel)}
                    className={`${props.button_txt.cancel} btn1`}
                  >
                    {' '}
                    {fromat(props.button_value.cancel)}
                  </button>
                )}

                <button
                  onClick={() => setShow(false, props.button_value.accept)}
                  className={`${props.button_txt.accept} btn1`}
                >
                  {' '}
                  {fromat(props.button_value.accept)}
                </button>
              </div>
            </Toast.Body>
          </Toast>
        </ToastContainer>
      )}
      {props.type === 'snackbar' && (
        <ToastContainer containerPosition={'absolute'} position="bottom-end">
          <Toast
            bg={'light'}
            onClose={() => autoclose(false)}
            show={showsnackbar}
            autohide
            delay={3000}
          >
            <Toast.Header closeButton={false} className={props.color==="red"?`warning_head`:`warning_cold`}>
              <img className={`warning_image`} src={warning} alt="test" />
              <strong className="me-auto">{fromat(props.errormessage)}</strong>
            </Toast.Header>
          </Toast>
        </ToastContainer>
      )}
    </div>
  )
}

export default Toster
import * as actions from './LoginTypes';
var localStorageRef = ''
if(localStorage.getItem("apinfo")){
    localStorageRef = JSON.parse(localStorage.getItem("apinfo")).token
}

const initialState = {
    loggedIn: localStorage.getItem("loggedIn"),
    token:localStorageRef
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.SET_LOGIN:
            return {
                ...state,
                loggedIn : action.payload.loggedIn,
                token: action.payload.token
            }
        default:
            return state;
    }
}
export default reducer;
import React, { useState, useMemo } from 'react'
import ContactMainView from '../components/Contacts/ContactMainView'
import ContactSidebar from '../components/Lead/Leadsideview'
import '../assets/css/Leads.css'
import EventEmitter from '../utils/EventEmitter'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { callLeadDetail } from '../redux/background/BackgroundAction'
import {
  ContactFetch,
  ContactCustomformFields,GetAsterCustommoduleData
} from '../redux/actions/ContactActions'
import { Decode } from '../utils/EncodeDecode'
function ContactView(props) {
  let Dispatch = useDispatch()
  let { phone_number, contact_id } = useParams()
  if(phone_number !== undefined){
    phone_number =Decode(phone_number)
  }
  const [name, setname] = useState()
  let state_value = useSelector((state) => state)
  const [create, setcreate] = useState(false)
  const [formData, setformData] = useState({})
  const [isthere, setisthere] = useState(false)
  const [formfield, setformfield] = useState([])




  useMemo(() => {

    mount()
  }, [phone_number])


  function fetchapi() {
    mount()
  }
  function mount() {
    Dispatch(ContactCustomformFields()).then(async(response) => {
      
      for (let i = 0; i < response.data.fields.length; i++) {
        const element = response.data.fields[i];
        if (element.catagory_value === "Custom module") {
          let url = `?template_name=${element.model}&skip=${0}&take=${0}&takeall=true`
          let payload = {
            url,
            bodydata:{
              template_name: element.model,
              fetch_type: 'internal',
            }
          }
         await  Dispatch(GetAsterCustommoduleData(payload)).then((res) => {
          if (res.data.statusCode === 200) {
              
              element.values = JSON.stringify(res.data.data.map((elm) => {
                return { name: elm.title, value: elm.title };
              }))

            }
          }).catch((err) => {
           
            EventEmitter.emit('error-message-getter', {
              action: 'fetch',
              module: 'Custom module data',
            })
          })
        }

      }

      setformfield(response.data)

      if (props.contactmodule) {
        setformData({ status: 204, data: "" })
        setcreate(true)
        setisthere(true)
        if (state_value.background.oncall === true && localStorage.getItem("dialpadname") === "") {
          localStorage.setItem("dialpadname", "Unknown Number");
          setname(localStorage.getItem("dialpadname"))
        }
      } else {
        Dispatch(
          ContactFetch(
            `/contact/fetchsearch?number=${phone_number}`,
          ),
        ).then((res) => {
          if (res.data.data.length === 0) {
            res.status = 204
            if (state_value.background.oncall === true && localStorage.getItem("dialpadname") === "") {
              localStorage.setItem("dialpadname", "Unknown Number");
              setname(localStorage.getItem("dialpadname"))
            }

            setcreate(true)
            setisthere(true)
            setformData(res)

          } else if (res.data.statusCode == 200) {
            if (state_value.background.oncall && state_value.background.oncalllead[0].render < 1 && state_value.background.socket_message.phonenumber.toString() === phone_number) {
              let leadData = {
                lead_id: res.data.data[0].id,
                lead_name: res.data.data[0].name,
                phone_number: res.data.data[0].phone_number,
                module: "contact",
                render: 1
              }
              Dispatch(callLeadDetail(leadData))
              let names = res.data.data[0].name
              if (names === "") {
                names = res.data.data[0].phone_number
              }
              localStorage.setItem("dialpadname", names);

              setname(localStorage.getItem("dialpadname"))
            }

            setisthere(true)
            setformData(res)
            setcreate(false)




          }
        }).catch((err) => {
          EventEmitter.emit('error-message-getter', {
            action: 'fetch',
            module: "contact",
          })
        })
      }
    }).catch((err) => {
      EventEmitter.emit('error-message-getter', {
        action: 'fetch',
        module: 'form fields',
      })
    })
  }

  return (
    <div className="page-main-container">
      {isthere && (
        <>
          <div className="scroll-lft leads-lft">
            {create === false && (
              <div className="leads-sidebar">
                <ContactSidebar
                  formdatas={formData}
                  type={"contact"}
                  id={contact_id}
                />
              </div>
            )}
            <div
              className={
                create
                  ? 'leads-main leads-main-new'
                  : 'leads-main leads-main-exist'
              }
            >
              <ContactMainView
                fetchapi={fetchapi}
                operation={create}
                islead={props.islead}
                formdatas={formData}
                statedata={formfield}
                type={"ticket"}
              />
            </div>
          </div>

        </>
      )}
    </div>
  )
}

export default ContactView

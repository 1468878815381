import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Contact from './Contacts/Contactdetail'

function TransferCentersCall(props) {
    const station = localStorage.getItem("extension")
var tenant = '';
let AppPermission
if (localStorage.getItem('config')) {
    AppPermission = JSON.parse(localStorage.getItem('config')).data.projects[0].userPermission
}
if (localStorage.getItem("config")) {
    tenant = JSON.parse(localStorage.getItem("config")).data.tenant_code
}
const agent = localStorage.getItem("user")
    let stateValue = useSelector((state) => state)
    let diddata = useSelector((state) => state.detail.did)
    const [search, setsearch] = useState("")



    function transferOthers(value) {

        let data = {
            action: 'Transfer',
            uniqueid: stateValue.background.socket_message.uniqueid,
            caller_channel: stateValue.background.socket_message.userChannel,
            agent_channel: stateValue.background.socket_message.agentChannel,
            xfer_type: 'ATTENDED',
            xfer_to: 'others',
            xfer_dest: value,
            agent: agent,
            phone_number: stateValue.background.socket_message.phonenumber,
            station: station,
            tenant_id: tenant,
        };

        props.callcentertransfer(data)
    }


    function conferencenumber(value) {

        let data = {
            action: "conference",
            add_number: value,
            station: station,
            tenant_id: tenant,
            uniqueid: stateValue.background.socket_message.uniqueid,
            caller_channel: stateValue.background.socket_message.userChannel,
            live_channel: stateValue.background.socket_message.agentChannel,
        }

        props.contactconference(data)
    }
    return (
        <>
            <div style={{ height: '400px' }}>
                {props.type === "conference" &&
                    < Contact transfernumber={transferOthers} conferencenumber={conferencenumber} actiontype={"conference"}></Contact>
                }
                {
                    props.type !== "conference" &&
                    < Contact transfernumber={transferOthers} conferencenumber={conferencenumber} actiontype={"transfer"} ></Contact >
                }
            </div >

        </>
    )
}

export default TransferCentersCall;
import api from "../../utils/axios-util";


export const AsterCampaigns = (temp_campaign) => {
    return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
            var apinfo = JSON.parse(localStorage.getItem("apinfo")) || "{}";
            await api
                .get(`/campaign/fetch?tid=${apinfo.tenant_code}&name=${temp_campaign}`)
                .then((response) => {
                    resolve(response);
                    
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
};
export const AsterGetCampaignsData = (payload) => {
    return (dispatch, getState) => {
      return new Promise(async (resolve, reject) => {
        await api.get(`/campaign?campaign_name=${payload}`).then(response => {
          resolve(response);
          
        }).catch((err) => {
          reject(err)
        })
      })
    };    
  
  };

  export const PauseCodeDuration = (payload) => {
    return (dispatch, getState) => {
      return new Promise(async (resolve, reject) => {
        await api.get(`/pausecode?pause_code=${payload}`).then(response => {
          resolve(response);
          
        }).catch((err) => {
          reject(err)
        })
      })
    };
  }

  export const liveagentdata = (payload) => {
    return (dispatch, getState) => {
      return new Promise(async (resolve, reject) => {
        await api.get(`/liveagent/datafetch?user=${payload}`).then(response => {
          resolve(response);
          
        }).catch((err) => {
          reject(err)
        })
      })
    };
  }
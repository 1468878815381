import {
  CUSTOM_DATA,
  CUSTOM_FIELDS,
  CUSTOM_UPDATE,
  CUSTOM_TICKET_REQ_MODULE,
} from './CustomFormTypes'
const initialState = {
  formdata: [],
  fields: [],
  reqOption:[]
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CUSTOM_DATA:return {
        formdata: [action.payload],
        fields: state.fields,
        reqOption:state.reqOption,
      }
    case CUSTOM_TICKET_REQ_MODULE:
      return {
        formdata: state.formdata,
        fields: state.fields,
        reqOption: action.payload,
      }
    case CUSTOM_FIELDS:
      return {
        fields: action.payload,
        formdata: state.formdata,
        reqOption:state.reqOption,
      }
    case CUSTOM_UPDATE:
      return {
        formdata: [action.payload],
        fields: state.fields,
        reqOption:state.reqOption,
      }
    default:
      return state
  }
}
export default reducer

import React, { useState, useEffect, useRef } from 'react'
import { FetchTemplate } from '../../redux/actions/ContactActions'
import { useDispatch, useSelector } from 'react-redux'
import Format from '../../utils/format-text'
import EventEmitter from '../../utils/EventEmitter'
import { useParams } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useNavigate } from 'react-router'
import { Encode, Decode } from '../../utils/EncodeDecode'
function LinkLead(props) {
    let navigate = useNavigate()
    let campaigninfo = JSON.parse(localStorage.getItem("selectedCampaign_data"));
    let { phone_number } = useParams()
    phone_number = Decode(phone_number)
    let agent = localStorage.getItem('user')
    const [leaddetails, setleaddetails] = useState([])
    const [count, setcount] = useState(0)
    const [skipdata, setskipdata] = useState({ skip: 0, take: 20 })
    let stateValue = useSelector((state) => state)
    let dispatch = useDispatch()
    useEffect(() => {
        leadtemplateMount(phone_number, skipdata)
    }, [])

    function leadtemplateMount(data, skipdatavalue) {
        let url = `/contact/template?phone_number=${data}&user=${agent}&skip=${skipdatavalue.skip}&take=${skipdatavalue.take}`
        dispatch(FetchTemplate(url))
            .then((response) => {

                if (response.status === 200) {

                    setskipdata((set) => {
                        set.skip = 0
                        set.take = 10
                        return {
                            ...set
                        }
                    })
                    setcount(response.data.count)
                    setskipdata((set) => {
                        set.skip = set.skip + 5
                        set.take = 10
                        return {
                            ...set
                        }
                    })

                    setleaddetails(leaddetails.concat(response.data.data))


                } else {
                    setleaddetails([].concat([]))
                }
            })
            .catch((err) => {
                EventEmitter.emit('error-message-getter', {
                    action: 'fetch',
                    module: 'lead template',
                })
            })
    }


    async function navigatetoticket(ph_no, id) {
        await navigate(`/dashboard`)
        await navigate(`/leads/${Encode(phone_number)}/${id}`)
    }


    return (
        <div style={{
            height: '100%',

        }}>
            <div
                id="ticketsDiv"
                style={{
                    height: '100%',
                    overflow: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >

                {leaddetails.map((data, i) => {
                    return (
                        <div key={i} className="lead-meeting">
                            <div className="lead-meeting-lft">

                                <div className="lead-meeting-subject">


                                    <div className="lead-ticket-status">
                                        <strong
                                            className={

                                                'lead-ticket-others'
                                            }
                                        >
                                            Template Name :  {Format(data.template)} | Lead Id : {Format(data.lead_id)}
                                        </strong>
                                    </div>


                                </div>
                            </div>

                            {<div
                                onClick={() => {

                                    navigatetoticket(phone_number, data.lead_id)


                                }}
                                className={campaigninfo.template_name !== data.template ? " disabledbutton lead-meeting-rht" : "lead-meeting-rht"}
                            >

                                <span className="  material-icons lead-call-icon lead-view-ticket-icon " >
                                    contact_page
                                </span>
                            </div>}
                        </div>
                    )
                })}
                {leaddetails.length === 0 && (
                    <div className="nodata " style={{ margin: 'auto', width: '70px' }}>
                        nodata
                    </div>
                )}

            </div>
        </div>
    )
}

export default LinkLead
import React, { useState, useEffect } from 'react'
import imcoming from "../assets/images/person.svg";
import { fetchcallaction } from '../redux/Calls/CallAction'
import { CustomformData, } from '../redux/actions/Customform'
import { ContactFetch } from '../redux/actions/ContactActions'
import { tempData} from '../redux/background/BackgroundAction'
import { connect, useDispatch, useSelector } from 'react-redux'
import Format from '../utils/format-text'
function Incoming(props) {
  let AppPermission
  if (localStorage.getItem('config')) {
    AppPermission = JSON.parse(localStorage.getItem('config')).data.projects[0].userPermission
  }
  let Dispatch = useDispatch()
  const [name, setname] = useState()
  let dialname = localStorage.getItem("dialpadname")
  let stateValue = useSelector((state) => state)
  
  useEffect(() => {
    if ((stateValue.background.socket_message.calltype === "INBOUND" || stateValue.background.socket_message.calltype === "AUTO"|| stateValue.background.socket_message.calltype === "POWER") && stateValue.background.socket_message.evt === "RINGING") {
      // setname(dialname)
      Dispatch(
        ContactFetch(
          `/contact/fetchsearch?number=${stateValue.background.socket_message.phonenumber}`,
        ),
      ).then((res) => {
        const s = res.data.data
        if (s.length > 0) {
          let obj = {
            first_name: s[0].contactName,
            last_name: "",
            email: s[0].email,
            phone_number: s[0].contactNumber,
            address: s[0].address1,
            country: s.country,
            city: s[0].city,
            state: s[0].state,
            profile_url: s[0].profile_url,
          }
          Dispatch(tempData({type:"contact",data:obj}))
          setname(res.data.data[0].name)
          // localStorage.setItem("dialpadname", res.data.data[0].name);
        }
        else {
          Dispatch(tempData({type:"",data:{}}))
          let campaigninfo = JSON.parse(localStorage.getItem('selectedCampaign_data'))
          let payload = {
            template_name: campaigninfo.template_name,
            type: campaigninfo.type,
            phone_number: stateValue.background.socket_message.phonenumber,
            id: ''
          }
          Dispatch(CustomformData(payload)).then((res) => {
            if (res.status != 204) {
              setname(res.data.data[0].others.first_name)
              // localStorage.setItem("dialpadname", res.data.data[0].others.first_name);

            } else {
              setname('Unknown Number')
              // localStorage.setItem("dialpadname",'Unknown Number');
            }


          })
        }
      })
      props.sound_(true)
    }
  }, [stateValue.background.socket_message.evt])
  function call() {
    console.log("=============================================>","start call function","testlogs")
    props.callAction('on_call')
    console.log("=============================================>","start call function","testlogs")
    props.call(true)
    if (AppPermission.webrtc && stateValue.background.socket_message.calltype === "xfer") {
      console.log("=============================================>"," xfer condition","testlogs")
      props.cutmusic()
    }
    console.log("=============================================>","enter call condition","testlogs")
  }
  function showDispofunction() {
    console.log("=============================================>","start cut function","testlogs")
    props.call(false)
    console.log("=============================================>","end cut function","testlogs")
  }
  function back2form() {
    props.back2form()
  }
  return (
    <>
      <div className="incoming ">
        <div className="incoming-sub-div ">
          <div className="call-animation1 call-image">
            <img
              src={imcoming}
              className="img-circle"
              alt="test"
              style={{ cursor: 'pointer' }}
              onClick={() => back2form()}
            />
          </div>
          <h2 className="incoming-txt" style={{ cursor: 'pointer' }} onClick={() => back2form()}>{name}</h2>
          <div className="incoming-txt incoming-txt-number" style={{ cursor: 'pointer' }} onClick={() => back2form()}>
         { Format(stateValue.background.socket_message.phonenumber, { maskbool: true })}
          </div>
          <p className="incoming-txt">Incoming Call</p>
          {AppPermission.webrtc&&<div className="call_action">
            <div
              className="material-icons red"
              onClick={() => showDispofunction()}
            >
              call_end
            </div>
            <div onClick={() => call(true)} className="material-icons green">
              call
            </div>
          </div>}
        </div>
        <div className="wave"></div>
        <div className="bottom-wave"></div>
      </div>
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    callAction: (type) => dispatch(fetchcallaction(type)),
  }
}
export default connect(null, mapDispatchToProps)(Incoming)

import React from 'react'
import { Form } from 'react-bootstrap'
import incoming from '../assets/images/incoming.png'
function ChatM(props) {
    const ClickMe = () => {
        props.ClinkMe();
    }
    return (
        
            <div className="chat-box" >
                <div className="chat-box-header contact_head">
                <h3>Chatmmmm</h3>
                <div>

                    <span className="material-icons" onClick={() => ClickMe()}>
                        close
                    </span>
                </div>
                </div>
                <div className="chat-box-body">
                    
                    <div className="chat-logs">
                    <div className="d-flex align-items-center justify-content-start">
                        <img src={incoming} alt="test" style={{ height: 50, width: 50, borderRadius: 50 }} />
                        <span>Hi</span>
                    </div>
                    <div className="d-flex align-items-center justify-content-start flex-row-reverse">
                        <img src={incoming} alt="test" style={{ height: 50, width: 50, borderRadius: 50 }} />
                        <span style={{ paddingRight: 10 }}>Hi</span>
                    </div>
                    </div>
                </div>
                <div className="chat-input" style={{position:'relative'}}>
                <Form.Control type="text" placeholder="Type here.." style={{ paddingRight: 40, height: '55px', borderRadius: 0,}} />
                    <div style={{ position: 'absolute', bottom: 10, right: 10 }}>
                        <span className="material-icons">
                            attach_file
                        </span>
                        <span className="material-icons">
                            send
                        </span>
                    </div>
                </div>
            </div>
        
    )
}

export default ChatM

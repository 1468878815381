export const SET_CAMPAIGN = 'Set_Campaign';
export const SET_USER = 'Set_User';
export const SET_TYPE = 'Set_Type';
export const SET_OPTIONS = 'Set_Options';
export const SET_PHONE = 'Set_Phone';
export const SET_QUEUE = 'Set_Queue';
export const SET_PAUSE = 'Set_Pause';
export const LOGIN_TIME = 'Login_Time';
export const PAUSE_TIME = 'Pause_Time';
export const DID = 'DID';
export const AGENTSTATUS = "AGENTSTATUS";

import React, { useState } from 'react'
import {  useSelector } from 'react-redux';
import { Form, Table } from 'react-bootstrap';

function TransferQueueCall(props) {
    const station = localStorage.getItem("extension")
var tenant = '';
if (localStorage.getItem("config")) {
    tenant = JSON.parse(localStorage.getItem("config")).data.tenant_code
}
const agent = localStorage.getItem("user")
    let AppPermission
    if (localStorage.getItem('config')) {
        AppPermission = JSON.parse(localStorage.getItem('config')).data.projects[0].userPermission
    }
    let queuedata = JSON.parse(localStorage.getItem("config")).data.projects[0].queue
    let stateValue = useSelector((state) => state)
    const [search, setsearch] = useState("")
    function transferQueue(value) {
        let data = {
            action: 'Transfer',
            uniqueid: stateValue.background.socket_message.uniqueid,
            caller_channel: stateValue.background.socket_message.userChannel,
            agent_channel: stateValue.background.socket_message.agentChannel,
            xfer_type: 'BLIND',
            xfer_to: 'queue',
            xfer_dest: value,
            agent: agent,
            phone_number: stateValue.background.socket_message.phonenumber,
            station: station,
            tenant_id: tenant,
        };

        props.queuetransfer(data)
    }
    return (
        <>
            {AppPermission.queue_transfer_search && <div style={{ position: 'relative' }}>
                <span className="material-icons" style={{ position: 'absolute', top: 8, left: 10 }}>
                    search
                </span>
                <Form.Control onChange={(event) => {
                    setsearch(event.target.value)
                }} type="text" placeholder="Search" style={{ paddingLeft: 40, height: '35px', marginTop: '10px' }} />
            </div>}
            <div className='table_cls'>
                <Table hover size="sm" className="agent_table">
                    <thead>
                        <tr>
                            <th>Queue</th>

                        </tr>
                    </thead>
                    <tbody>
                        {queuedata.filter((val) => {
                            if (search === "") {
                                return val
                            }
                            else if (val.name.toLowerCase().includes(search.toLowerCase())) {
                                return val
                            }

                        }).map((value) => {
                            return (

                                <tr >
                                    <td onClick={() => { transferQueue(value.name) }}>{value.name}</td>
                                </tr>
                            )
                        })

                        }
                    </tbody>
                </Table>
            </div>
        </>
    )
}

export default TransferQueueCall;
import React, { useState, useEffect, useRef } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import Dialogbox from '../Dialogbox'
import { Form } from 'react-bootstrap'
import EventEmitter from '../../utils/EventEmitter'
import { AsterContact, contactSearch } from '../../redux/actions/ContactActions'
import InfiniteScroll from 'react-infinite-scroll-component'
import contact_png from '../../assets/images/contact.png'
import fromat from '../../utils/format-text'
import { useNavigate } from 'react-router'
import { PopupRouter } from '../../redux/actions/Customform'
import { Encode } from '../../utils/EncodeDecode'
import LoadingSpinner from '../LoadingSpinner'
function ContactDetails(props) {
  let navigate = useNavigate()
  let campaigninfo = JSON.parse(localStorage.getItem("selectedCampaign_data"))
  let dispatch = useDispatch()
  let wasession = localStorage.getItem("wasession")
  let AppPermission
  if (localStorage.getItem('config')) {
    AppPermission = JSON.parse(localStorage.getItem('config')).data.projects[0].userPermission
  }
   let [loader,setloader]= useState(false)
  const [values, Setvalue] = useState('')
  const [ContactDetails, SetContactDetails] = useState([])
  const [skipdata, setskipdata] = useState({
    skip: 0,
    take: 10,
  })

  let [count, setcount] = useState(0)
  const [infinitScroll, setinfinitScroll] = useState({
    limit: 7,
    more: true,
  })

  useEffect(() => {
    fetchCallLog(skipdata)
  }, [])

  let fetchMoreData = () => {
    if (ContactDetails.length >= count) {
      setinfinitScroll((set) => {
        set.more = false
        return {
          ...set,
        }
      })
    } else {
      setTimeout(() => {
        if (values === '') {
          fetchCallLog(skipdata,"Moredata")
        } else {
          searchTerm(values, 'lazyload',"Moredata")
        }
      }, 1000)
    }
  }

  const fetchCallLog = async (payloadValue,Moredata) => {
    let payload = {
      skip: payloadValue.skip,
      take: payloadValue.take,
    }
    if(Moredata == "Moredata"){
      setloader(true)
    }

    dispatch(AsterContact(payload))
      .then(async function (response) {
        setloader(false)
        if (response.status === 200) {
          setcount(response.data.count)

          if (payload.skip === 0) {
            setskipdata((set) => {
              set.take = 10
              set.skip = 10
              return {
                ...set,
              }
            })

            SetContactDetails([].concat(response.data.data))
          } else {
            SetContactDetails(ContactDetails.concat(response.data.data))
            setskipdata((set) => {
              set.take = set.take
              set.skip = set.skip + 10
              return {
                ...set,
              }
            })
          }
          setinfinitScroll((set) => {
            set.limit = set.limit + set.limit

            return {
              ...set,
            }
          })
        }
      })

      .catch((err) => {
        EventEmitter.emit('error-message-getter', {
          action: 'fetch',
          module: 'abandon',
        })
      })
  }

  function searchTerm(value, lazyload,Moredata) {
    if (value != '') {
      let payload
      if (lazyload === 'lazyload') {
        Setvalue(value)

        payload = {
          skip: skipdata.skip,
          take: 10,
          value: value,
        }
      } else {
        Setvalue(value)
        setinfinitScroll((set) => {
          set.more = true
          return {
            ...set,
          }
        })
        payload = {
          skip: 0,
          take: 10,
          value: value,
        }
      }
      if(Moredata !== "Moredata"){
        setloader(true)
      }

      dispatch(contactSearch(payload))
        .then(async function (response) {
setloader(false)
          if (response.status === 200) {


            if (lazyload === 'lazyload') {
              SetContactDetails(ContactDetails.concat(response.data.data))

              setskipdata((set) => {
                set.take = 10
                set.skip = payload.skip + 10
                return {
                  ...set,
                }
              })
              setcount(response.data.count)
            } else {
              SetContactDetails([].concat(response.data.data))

              setskipdata((set) => {
                set.take = 10
                set.skip = 10
                return {
                  ...set,
                }
              })
              setcount(response.data.count)
            }
          } else {
            SetContactDetails([].concat(response.data))
          }
        })

        .catch((err) => {
          EventEmitter.emit('error-message-getter', {
            action: 'search',
            module: 'abandon',
          })
        })
    } else {
      Setvalue(value)
      SetContactDetails([])
      setskipdata((set) => {
        set.take = 10
        set.skip = 0
        return {
          ...set,
        }
      })
      let payload = {
        skip: 0,
        take: 10,
      }
      setinfinitScroll((set) => {
        set.more = true
        return {
          ...set,
        }
      })
      fetchCallLog(payload)
    }
  }

  const getnumber = (value, actiontype, id, name) => {
    let readystate = localStorage.getItem("selectedPause")
    if (readystate == "available") {
      if (actiontype === "transfer") {
        props.transfernumber(value)
      } else if (actiontype === "conference") {
        props.conferencenumber(value)
      } else {
        props.callnumber([value, id, name])
      }

    } else {

      EventEmitter.emit('error-message-getter', {
        action: 'ready',
        module: "Agent Not ready",
      })
    }


  }
  function navigater(phone){
    
    let payload = {
      template_name: campaigninfo.template_name,
      phone_number: phone
    }
    let process = localStorage.getItem('process')
    if (process === "leads" || process === "leads,meetings" || process === "leads,tickets,meetings" || process === "leads,tickets"  ) {
      payload.module = 'leads'
      dispatch(PopupRouter(payload)).then((res) => {
        if (res.data.ids.length > 0) {
          let id = res.data.ids[0].lead_id
          navigate(`/leads/${Encode(phone)}/${id}`)
        } else {
          navigate('/leads/create')

        }
      }).catch((err) => {
        EventEmitter.emit('error-message-getter', {
          action: 'fetch',
          module: 'popup router',
        })
      })
    } else if (process === "tickets" || process === "tickets,meetings") {
      payload.module = 'tickets'
      dispatch(PopupRouter(payload)).then((res) => {
        if (res.data.ids.length > 0) {
          let id = res.data.ids[0].ticket_id
          navigate(`/tickets/${Encode(phone)}/${id}`)
        } else {

          navigate('/tickets/create')

        }
      }).catch((err) => {
        EventEmitter.emit('error-message-getter', {
          action: 'fetch',
          module: 'popup router',
        })
      })
    }
    else if (process === "meetings") {
      payload.module = 'meetings'
      dispatch(PopupRouter(payload)).then((res) => {
        if (res.data.ids.length > 0) {
          let id = res.data.ids[0].fwp_id
          navigate(`/meeting/${Encode(phone)}/${id}`)
        } else {

          if (res.data.contact_id.length > 0) {
            navigate(`/contact/${Encode(phone)}/${res.data.contact_id[0].id}`)
          } else {
            navigate('/contact/create')
          }


        }
      }).catch((err) => {
        EventEmitter.emit('error-message-getter', {
          action: 'fetch',
          module: 'popup router',
        })
      })
    }
    

  
  
}
  function setaddcontact() {
    navigate(`/contact/create`)
  }
  return (
    <div className="callscreen-log">
      <div
        style={{
          display: 'flex',
          'flex-direction': 'column',
          height: ' 100%',
        }}
      >
        <>
          <div className="contact-header">
            <div className="contact-search">
              {AppPermission.contact_create && <span
                className="material-icons callscreen-contact-icon custom-cursor"
                onClick={() => {
                  setaddcontact()
                }}
              >
                add_circle
              </span>}
              {AppPermission.contact_search && <Form.Control
                onChange={(event) => {
                  searchTerm(event.target.value)
                }}
                type="text"
                placeholder="Search"
                style={{ paddingLeft: 10, height: '30px', marginTop: '10px' }}
              />}
            </div>
          </div>

          <>
            <div
              id="contactDiv"
              style={{
                height: '100%',
                overflow: 'auto',
                color: 'grey',
                textAlign: 'center',
              }}
            >
              <InfiniteScroll
                dataLength={ContactDetails.length}
                next={fetchMoreData}
                hasMore={infinitScroll.more}
                scrollableTarget="contactDiv"
              >
                {ContactDetails.length > 0 && (
                  <div className="contact_list">
                    {ContactDetails.map((ele, n) => {
                      return (
                        <div key={n.toString()} className='callloghover'>
                          <div 
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            {AppPermission.click_to_call && wasession === "false" && props.actiontype !== "transfer" && props.actiontype !== "conference" &&
                              <div
                                style={{ cursor: 'pointer' }}
                                className="material-icons complete_call"
                                onClick={() => getnumber(ele.contactNumber, "_", ele.id, ele.contactName)}
                              >
                                phone
                              </div>}

                            {props.actiontype === "transfer" &&
                              <div
                                style={{ cursor: 'pointer' }}
                                className="material-icons complete_call"
                                onClick={() => getnumber(ele.contactNumber, "transfer")}
                              >
                                phone
                              </div>}
                            {props.actiontype === "conference" &&
                              <div
                                style={{ cursor: 'pointer' }}
                                className="material-icons complete_call"
                                onClick={() => getnumber(ele.contactNumber, "conference")}
                              >
                                phone
                              </div>}

                            {props.actiontype !== "transfer" && props.actiontype !== "conference" && <div onClick={() => navigater(ele.contactNumber, ele.id)} style={{ marginLeft: 10 ,cursor:'pointer' }}>
                              <h4>{fromat(ele.contactName)}</h4>
                              <p>{fromat(ele.contactNumber,{maskbool:true})}</p>
                            </div>}
                            {(props.actiontype === "transfer" || props.actiontype === "conference") && <div style={{ marginLeft: 10}}>
                              <h4>{fromat(ele.contactName)}</h4>
                              <p>{fromat(ele.contactNumber,{maskbool:true})}</p>
                            </div>}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                )}
                {ContactDetails.length === 0 && loader == false && (
                  <>
                    <div className="contact-nodata">No Data</div>
                  </>
                )}
                { loader && <LoadingSpinner />}
              </InfiniteScroll>
            </div>
          </>
        </>
      </div>
    </div>
  )
}

export default ContactDetails

import React, { useState, useRef } from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { Col, Form, Row, Button, Card, Dropdown } from 'react-bootstrap'
import CallScreen from '../components/CallScreen'
import ContactTable from '../components/Table'
import EventEmitter from '../utils/EventEmitter'
import { useDispatch, useSelector } from 'react-redux'
import { ContactFetch } from '../redux/actions/ContactActions'
import '../assets/css/Leads.css'
import Filters from '../components/filter'
import InfiniteScroll from 'react-infinite-scroll-component'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { Outbound } from '../redux/SocketAction/SocketAction'
import { callmodule } from '../redux/Calls/CallAction'
import Format from '../utils/format-text'
import { Encode } from '../utils/EncodeDecode'
import Dialogbox from '../components/Dialogbox'
import { getTemplate } from '../redux/actions/ChatActions'
import { waTemplate } from '../redux/SocketAction/SocketAction'
import waicon from '../assets/images/whatsapp.svg'

function Contacts() {
  let campaigninfo = JSON.parse(localStorage.getItem('selectedCampaign_data'))
  let [wadata, setwadata] = useState([])
  const [popUpData, setpopUpData] = useState({
    open: false,
    Type: 'form_bar',
    field: [
      {
        type: 'SelectList',
        label: 'Whatsapp Template',
        placeholder: ' select message',
        required: true,
        model: 'whatsapp_template',
        option: [],
        multiple: false,
        value: '',
      },
    ],
    title: 'Whatsapp Template',
    Message: 'Selected Successfully',
    Butonstatus: 'Send',
  })
  const [searchselect, setsearchselect] = useState([])
  let wasession = localStorage.getItem("wasession")
  let stateValue = useSelector((state) => state)
  let dispatch = useDispatch()
  let AppPermission
  if (localStorage.getItem('config')) {
    AppPermission = JSON.parse(localStorage.getItem('config')).data.projects[0].userPermission
  }
  let navigate = useNavigate()
  const [infinitScroll, setinfinitScroll] = useState({ limit: 7, more: true })
  const [searchContactValue, setsearchContactValue] = useState("")
  const [contactDetails, setcontactDetails] = useState([])
  const [skipdata, setskipdata] = useState({
    skip: 0,
    take: 20,

  })
  let [maskphoneindex,setmaskphoneindex] = useState({index:0,value:""})
  let [count, setcount] = useState(0)
      let [loader,setloader]= useState(false)
  const [header, setheader] = useState([
    {
      Header: 'Contact Id',
      accessor: 'id',
    },
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Phone Number',
      accessor: 'phone',
    },

    {
      Header: 'Action',
      accessor: '',
      Cell: ({ row }) => {
        return (
          <div className="lead_action">
            {<span
              className="material-icons"
              style={{ color: '#3e98c7' }}
              onClick={() => ContactView(row, 'ContactView')}
            >
              info
            </span>}
            {AppPermission.click_to_call && <span
              className={stateValue.background.oncall == false && wasession === "false" ? "material-icons" : "material-icons disabledbutton"}
              style={{ color: '#32AA52' }}
              onClick={() => clickcall(row)}
            >
              phone
            </span>}
            {AppPermission.wa_view &&
              <img
                onClick={() => { wapopup(row.original) }}
                className={"wa_icon"}
                src={waicon}
                alt="test"
              />


            }
          </div>
        )
      },
    },
  ])
  useEffect(() => {
    mountedfunction()
    let index= header.findIndex((elm=> elm.Header =="Phone Number"))
    if(index !=-1){
      setmaskphoneindex({index:index,value:header[index].accessor})
    }

    if(AppPermission.wa_view){
    dispatch(getTemplate()).then((res) => {
      if (res.data.length > 0) {

        setpopUpData((set) => {
          set.field[0].option = res.data.map(e=>{
            e.label = e.name
            e.value = e.id
            return e
          })
          return {
            ...set
          }
        })
      }
    }).catch((err) => {
      EventEmitter.emit('error-message-getter', {
        action: 'fetch',
        module: "whatsapp Template",
      })
    })
    }
  }, [])

  function mountedfunction(action) {
    let url
    if (action === "refresh") {
      setinfinitScroll((set) => {
        set.more = true
        return {
          ...set,
        }
      })
      setsearchContactValue("")
      url = `/contact/fetchsearch?skip=${0}&take=${20}`
    }
    else if (searchContactValue !== "") {
      url = `/contact/fetchsearch?skip=${skipdata.skip}&take=${skipdata.take}&value=${searchContactValue}`
    }
    else {
      url = `/contact/fetchsearch?skip=${skipdata.skip}&take=${skipdata.take}`
    }
    if(action !="Moredata"){
      setloader(true)
    }
    dispatch(ContactFetch(url))
      .then((response) => {
        setloader(false)
        if (response.status === 200) {
          setcount(response.data.count)
          setskipdata((set) => {
            if (set.take === 20) {
              set.skip = 20
              set.take = 10
            } else {
              set.skip = set.skip + 10
              set.take = 10
            }

            return {
              ...set,
            }
          })
          let element = response.data.data
          let value = []
          for (let i = 0; i < element.length; i++) {

            let obj = {
              id: element[i].id,
              name: Format(element[i].contactName),
              email: element[i].email,
              phone: element[i].contactNumber,

            }

            value.push(obj)
          }
          if (action === "refresh") {
            setcontactDetails([].concat(value))
          } else {
            setcontactDetails(contactDetails.concat(value))
          }

        } else {
          setcontactDetails([])
        }
      })
      .catch((err) => {
        EventEmitter.emit('error-message-getter', {
          action: 'fetch',
          module: 'contact',
        })
      })
  }

  const ContactView = (row, pageName) => {
    if (pageName === 'ContactView') {
      navigate(`/contact/${Encode(row.original.phone)}/${row.cells[0].value}`)
    }
  }

  const createContact = () => {
    navigate(`/contact/create`)
  }


  const filterdataapi = (data, isfilter) => {

  }

  function searchcontact(e) {
    setinfinitScroll((set) => {
      set.more = true
      return {
        ...set,
      }
    })
    setskipdata((set) => {
      set.search = e.target.value
      set.skip = 20
      set.take = 10
      return {
        ...set,
      }
    })
    setsearchContactValue(e.target.value)

    let url

    url = `/contact/fetchsearch?skip=${0}&take=${20}&value=${e.target.value}`
    setloader(true)
    dispatch(ContactFetch(url))
      .then((response) => {
        setloader(false)
        if (response.status === 200) {
          setcount(response.data.count)
          let element = response.data.data
          let value = []
          for (let i = 0; i < element.length; i++) {

            let obj = {
              id: element[i].id,
              name: Format(element[i].contactName),
              email: element[i].email,
              phone: element[i].contactNumber,

            }

            value.push(obj)
          }
          setcontactDetails([].concat(value))
        } else {
          setcontactDetails([])
        }
      })
      .catch((err) => {
        EventEmitter.emit('error-message-getter', {
          action: 'fetch',
          module: 'contact',
        })
      })
  }

  let fetchMoreData = () => {
    if (contactDetails.length >= count) {
      setinfinitScroll((set) => {
        set.more = false
        return {
          ...set,
        }
      })
    } else {
      setTimeout(() => {
        mountedfunction("Moredata")
      }, 1000)
    }
  }


  function clickcall(data) {

    let readystate = localStorage.getItem("selectedPause")

    if (readystate == "available") {
      let payload = {
        state: stateValue.background.websocketconnection,
        value: data.original.phone.toString()
      }

      dispatch(callmodule({
        id: data.original.id.toString(),
        module: "contact",
        phone_no: data.original.phone.toString()
      }))
      dispatch(Outbound(payload.state, payload.value))

    } else {

      EventEmitter.emit('error-message-getter', {
        action: 'ready',
        module: "Agent Not ready",
      })
    }

  }

  const refresh = () => {
    mountedfunction('refresh')
    setskipdata((set) => {
      set.skip = 0
      set.take = 20
      return {
        ...set,
      }
    })
    setinfinitScroll((set) => {
      set.more = true
      return {
        ...set,
      }
    })
  }
  function wapopup(data) {
    console.log(data)
    setwadata(data)
    setpopUpData((set) => {
      set.open = true
      return {
        ...set
      }
    })
  }
  function closepopup(data, name) {
    if (data.action === "cancel") {
      setpopUpData((set) => {
        set.open = false
        return {
          ...set
        }
      })
    } else {
      function find_(data_) {
        return data_.value === data.data[0].whatsapp_template;
      }


      let templatedata = {
        "action": "send-wa-template",
        "station": localStorage.getItem('extension'),
        "phone_number": wadata.phone.toString(),
        "sender_id": wadata.id,
        "sender_type": 1,
        "sender_name": wadata.name,
        "tenant_id": JSON.parse(localStorage.getItem("config")).data.tenant_code,
        "agent": localStorage.getItem("user"),
        "template": {
          "id": data.data[0].whatsapp_template,
          "type": "",
          "name": data.field[0].option.find(find_).name,
          "language": data.field[0].option.find(find_).lang
        }
      }
      if (data.action === "create") {
        dispatch(waTemplate(stateValue.background.websocketconnection, templatedata))
        setpopUpData((set) => {
          set.open = false
          return {
            ...set
          }
        })
      }


     
    }
  }
  return (
    <div className="page-main-container">
      <Dialogbox
        fields={closepopup}
        value={popUpData}
        className="dialog-body-default1"
        // cancel={{ display: 'none' }}
        save={'disabled'}
      />
      <div className="scroll-lft" md={8}>


        <div className="lead-header">

          <div className="lead-header1">
          <div className="route-header lead-header1-lft" style={{cursor: 'default'}}> Contacts</div>
            {AppPermission.contact_search && <div className="lead-search">
              <span className="material-icons lead-search-icon">search</span>
              <Form.Control
                onChange={(e) => {
                  searchcontact(e)
                }}
                type="text"
                value={searchContactValue}
                placeholder="Search"
                style={{
                  border: '1px solid #e9ecef',
                  backgroundColor: '#fff',
                  padding: 10,
                  'padding-left': '40px',
                }}
              />
            </div>}
          </div>
          <div className="lead-header2">
          {AppPermission.contact_refresh && 
           <OverlayTrigger
           placement={'bottom'}
           overlay={<Tooltip>{'Refresh'}</Tooltip>}
         >
           <div
             onClick={refresh}
             className="lead-save-icons lead-create-head-rht material-icons"
           >
             refresh
           </div>
         </OverlayTrigger>
         }

            {AppPermission.contact_filter && <Filters filterdataapi={filterdataapi} type={'contacts'} />}
            {AppPermission.contact_create && 
            <OverlayTrigger
            placement={'bottom'}
            overlay={<Tooltip>{'Add'}</Tooltip>}
          >
            <div
              onClick={createContact}
              className="lead-save-icons lead-create-head-rht material-icons"
            >
              add_circle
            </div></OverlayTrigger>}
          </div>
        </div>
        <div className="lead-body">
          <div
            id="ticketDiv"
            style={{
              height: '100%',
              overflow: 'auto',
              color: 'grey',
              textAlign: 'center',
              width: '100%',
            }}
          >
            <InfiniteScroll
              dataLength={contactDetails.length}
              next={fetchMoreData}
              hasMore={infinitScroll.more}
              scrollableTarget="ticketDiv"
            >
              <ContactTable
                loader={loader}
                checkbox={false}
                header={header}
                leaddetail={contactDetails}
                type="lead"
                maskphoneindex={maskphoneindex}
              />
            </InfiniteScroll>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contacts

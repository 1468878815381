import React, { useState, useEffect } from 'react'
import '../assets/css/Colorbar.css'
function ColorBar(props) {

   

    return (
        <>
         
            <div className='colorBarMain'>
                {props.ColorCode.map((value) => {
                    return (
                        <>
                            <div className='colorBarsub'>
                            <div style={{
                      backgroundColor: value.code,
                      
                    }} className='colorBarbox'></div>
                                <p    className='colorBartitle'>{value.condtion_name} {value.value}</p>
                            </div>
                        </>


                    )
                })}
                 <p className='colorBartitle maincolorBartitle'>Duplicate Lead Count Lable</p>
            </div>
           
        </>
    )
}

export default ColorBar;

import React, {
  useState,
  useEffect
} from 'react'
import Select from 'react-select'
function Timepicker(props) {
  const [hours, sethours] = useState([])
  const [minute, setminute] = useState([])
  const [currentvalue, setcurrentvalue] = useState({ hh: '00', min: '00' })
  useEffect(() => {
    let hour = []
    for (let index = 0; index < 25; index++) {
      let obj
      if (index < 10) {
        obj = {
          label: `0${index}`,
          value: `0${index}`,
        }
      } else {
        obj = {
          label: `${index}`,
          value: `${index}`,
        }
      }

      hour.push(obj)
    }
    sethours(hour)

    let min = []
    for (let index = 0; index < 61; index++) {
      let obj
      if (index < 10) {
        obj = {
          label: `0${index}`,
          value: `0${index}`,
        }
      } else {
        obj = {
          label: `${index}`,
          value: `${index}`,
        }
      }

      min.push(obj)
    }
    setminute(min)
  }, [])



  useEffect(() => {

    if (props.currentvalue !== undefined && props.currentvalue !== null && props.currentvalue !== "") {
      let time = props.currentvalue.split(':')
      if (time[0].split("").length === 3) {
        time[0] = `${time[0].split("")[1]}${time[0].split("")[2]}`
      }
      if (time[1].split("").length === 3) {
        time[1] = `${time[1].split("")[1]}${time[1].split("")[2]}`
      }

      setcurrentvalue((set) => {
        set.hh = time[0]
        set.min = time[1]
        return {
          ...set,
        }
      })
    } else {
      setcurrentvalue((set) => {
        set.hh = "00"
        set.min = "00"
        return {
          ...set,
        }
      })
    }
  }, [props.currentvalue])

  function handleChangedata(type, data) {
    if (type === 'HH') {
      setcurrentvalue((set) => {
        set.hh = data.value
        return {
          ...set,
        }
      })
      props.hoursfinaldata({
        hour: `${data.value}`,
        minute: `${currentvalue.min}`,
        val: props.valuegetter,
        datetime: props.datetime,
      })
    }

    else {
      setcurrentvalue((set) => {
        set.min = data.value
        return {
          ...set,
        }
      })
      props.minutefinaldata({
        hour: `${currentvalue.hh}`,
        minute: `${data.value}`,
        val: props.valuegetter,
        datetime: props.datetime,
      })

      
    }
  }
  return (
    <>
      <div className="timer_div">
        <Select
          className="timer_div1"
          isMulti={false}
          isDisabled={props.disabled}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          menuPortalTarget={document.body}
          menuShouldScrollIntoView={false}
          onChange={(e) => handleChangedata('HH', e)}
          value={{ label: currentvalue.hh, value: currentvalue.hh }}
          options={hours}
          isSearchable={true}
          menuIsOpen={props.readonly ? false : undefined}
          placeholder={'HH'}
          menuPlacement={`auto`}
        />

        <Select
          className="timer_div2"
          isMulti={false}
          isDisabled={props.disabled}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          menuPortalTarget={document.body}
          menuShouldScrollIntoView={false}
          onChange={(e) => handleChangedata('MIN', e)}
          value={{ label: currentvalue.min, value: currentvalue.min }}
          options={minute}
          isSearchable={true}
          menuIsOpen={props.readonly ? false : undefined}
          placeholder={'MIN'}
          menuPlacement={`auto`}
        />
      </div>
    </>
  )
}

export default Timepicker

import api from "../../utils/axios-util";



export const AsterCalllog = (payload) => {
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      await api.post(`/calllog`, payload).then(response => {
        resolve(response);
      }).catch((err) => {
        reject(err)
      })
    })
  }

};

export const AsterInbound = (payload) => {
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      await api.post(`/calllog`, payload).then(response => {
        resolve(response);
      }).catch((err) => {
        reject(err)
      })
    })
  }

};

export const AsterOutbound = (payload) => {
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      await api.post(`/calllog`, payload).then(response => {
        resolve(response);
      }).catch((err) => {
        reject(err)
      })
    })
  }

};


export const AsterAbandoned = (payload) => {
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      await api.post(`/calllog`, payload).then(response => {
        resolve(response);
      }).catch((err) => {
        reject(err)
      })
    })
  }

};

export const AsterCalllogSearch = (payload) => {
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      await api.post(`/calllog`, payload).then(response => {
        resolve(response);
      }).catch((err) => {
        reject(err)
      })
    })
  }

};

export const AsterInboundSearch = (payload) => {
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      await api.post(`/calllog`, payload).then(response => {
        resolve(response);
      }).catch((err) => {
        reject(err)
      })
    })
  }

};


export const AsterOutboundSearch = (payload) => {
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      await api.post(`/calllog`, payload).then(response => {
        resolve(response);
      }).catch((err) => {
        reject(err)
      })
    })
  }

};

export const AsterAbandonedSearch = (payload) => {
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      await api.post(`/calllog`, payload).then(response => {
        resolve(response);
      }).catch((err) => {
        reject(err)
      })
    })
  }

};

export const AsterGetCampaigns = (payload) => {
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      await api.get(`/campaigntype?campaign_name=${payload}`).then(response => {
        localStorage.setItem("selectedCampaign_data", JSON.stringify(response.data.data[0]))
        resolve(response);
      }).catch((err) => {
        reject(err)
      })
    })
  }

};

export const AsterUpdateAgent = (payload) => {
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      await api.put(`/asterdialer/agent/live/${payload.user}`, { campaign: payload.campaign,dialer_flag:payload.dialer_flag }).then(response => {
        resolve(response);
      }).catch((err) => {
        reject(err)
      })
    })
  }

};

export const AsterOtherAgent = (payload) => {
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      await api.post(`/otherAgentStatus`, payload).then(response => {
        if(response.status ==200){
          dispatch({
            type: 'AGENTSTATUS',
            payload: response.data.data
          })
        }
        resolve(response);
      }).catch((err) => {
        reject(err)
      })
    })
  }

};


import api from "../../utils/axios-util";



export const ChatHistory = (data) => {
    return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
            let url
            if (data.session_id) {
                url = `/whatsapp/history?msg_from=${data.phone}&session_id=${data.session_id}&channel=${data.channel}&custom_value=${data.custom_value}`
            } else {
                url = `/whatsapp/history?msg_from=${data.phone}&skip=${data.skip}&take=${data.take}&channel=${data.channel}&custom_value=${data.custom_value}`
            }
            await api
                .get(url)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

};

export const WhatsappContact = (data) => {
    let condition = ""

    if (data.search) {
        condition = `agent=${data.agent}&search=${data.search}&skip=${data.skip}&take=${data.take}&channel=${data.channel}`
    }
    else {
        condition = `agent=${data.agent}&skip=${data.skip}&take=${data.take}&channel=${data.channel}`
    }
    return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
            await api
                .get(`/whatsapp/contacts?${condition}`)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

};

export const WaLive = (data) => {
    return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
            let url
            url = `/whatsapp/liveSession?agent=${data.agent}`

            await api
                .get(url)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

};
export const getTemplate = (data) => {
    return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
            let url
            url = `/template`

            await api
                .get(url)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

};
export const upload = (data) => {
    return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
            let url
            url = `/attachment`
            api.defaults.headers["Content-Type"] = "multipart/form-data";
            await api
                .post(url,data)
                .then((response) => {
                    api.defaults.headers["Content-Type"] = "application/json";
                    resolve(response);
                })
                .catch((err) => {
                    api.defaults.headers["Content-Type"] = "application/json";
                    reject(err);
                });
        });
    }

};
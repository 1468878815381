import { Navigate,Outlet } from "react-router-dom";
import { connect } from 'react-redux';

const ProtectedRoutes = (props) => {
    const isAuth = props.loggedIn;
    return isAuth ? <Outlet /> : <Navigate to='/' />;
}

const mapStateToProps = (state) => {
    return {
      loggedIn: state.login.loggedIn
    }
}

export default connect(mapStateToProps) (ProtectedRoutes);
import React, { useState, useRef } from 'react'
import { useEffect } from 'react'
import TicketView from '../../pages/TicketView'
import { useDispatch } from 'react-redux'
import { tempData } from '../../redux/background/BackgroundAction'

function CreateTickets() {
  let dispatch = useDispatch()
  const [loadCustomfrom, setloadCustomfrom] = useState(false)

  useEffect(() => {
      setloadCustomfrom(true)
      return () => {
        console.log("Destory only") 
        dispatch(tempData({type:"",data:{}}))
      };
  }, [])

  return (
    <>
      {loadCustomfrom && (
       <>
          <TicketView ticketmodule={true} />
        </>
      )}
    </>
  )
}

export default CreateTickets

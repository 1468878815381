import React, { useState, useEffect } from 'react'
import {  useDispatch, useSelector } from 'react-redux'
import {  useLocation } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser'
import { LeadScript } from '../../redux/actions/Asterleads'
var agent = localStorage.getItem('user')
function Script(props) {
  let [scriptcontent, setscriptcontent] = useState('')
  let Dispatch = useDispatch()
  const location = useLocation()
  useEffect(() => {
    let script = localStorage.getItem('script_name')
    Dispatch(LeadScript(script)).then(async function (response) {
           if (response.status === 200) {
        if (response.data.data[0].type === 'TEXT') {
          let html = response.data.data[0].value.replace(/\n/g, `<br>`)
     
          // .replace(/<header/g, `<span class="lead-script-head"`)
          //   .replace(/header/g, `span`)
          //   .replace(/<paragraph/g, `<div class="lead-script-body"`)
          //   .replace(/paragraph/g, `div`)
          //   .replace(/<bold/g, `<strong class="lead-script-bold"`)
          //   .replace(/bold/g, `strong`)
          //   .replace(/<break/g, `<br`)
          //   .replace(/<link>/g, ` `)
          //   .replace(/link>/g, ` `)

            function urlify(text) {
              var urlRegex = /(https?:\/\/[^\s]+)/g;
              return text.replace(urlRegex, function(url) {
                return '<a class="lead-script-link"  href="' + url + '">' + "Click Here" + '</a>';
              })
             
            }
          setscriptcontent(urlify(html))
        }
        if (response.data.data[0].type === 'URL') {
          
          const url = response.data.data[0].value;
          function urlifyUrl(text) {
            return '<a class="lead-script-link" href="' + text + '">' + text  + '</a>';
          }
          setscriptcontent(urlifyUrl(url));
        }
      }
    })
  }, [])

 
  return (
    <>
      <div className="lead-notepad-container">
        <div className={ location.pathname === `/script`? "lead-notepad-head1" :"lead-notepad-head"}>
          <div className="lead-notepad-head-lft lead-meetings-head-lft">
            <div className=  { location.pathname === `/script`? "lead-notepad-head-title route-header1" :"lead-notepad-head-title route-header"} >
              Script
            </div>
          </div>
        </div>
        <div className="lead-note-padbody">
          <div className="lead-script-content">{ReactHtmlParser(scriptcontent)}</div>
        </div>
      </div>
    </>
  )
}

export default Script
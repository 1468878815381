  function setlocalstorage(name,value){
    localStorage.setItem(name,JSON.stringify(value))
  }

  function getlocalstorage(name){
    return JSON.parse(localStorage.getItem(name))
  }

  function removelocalstorage(name){
    return localStorage.removeItem(name)
  }

  export { setlocalstorage , getlocalstorage , removelocalstorage }
import * as actions from './DetailTypes';

export const setCampaign = data => {
    localStorage.setItem("campaigns", JSON.stringify(data));
    return {
        type: actions.SET_CAMPAIGN,
        payload: data
    }
}

export const setUser = data => {
    localStorage.setItem("user", data);
    return {
        type: actions.SET_USER,
        payload: data
    }
}

export const setType = data => {
    localStorage.setItem("selectedCampaign", data);
    return {
        type: actions.SET_TYPE,
        payload: data
    }
}

export const setPhone = data => {
    return {
        type: actions.SET_PHONE,
        payload: data
    }
}

export const setOptions = data => { 
    localStorage.setItem("pauseCode", JSON.stringify(data.pauseCode));
    localStorage.setItem("queues", JSON.stringify(data.queues));
    localStorage.setItem("dispo", JSON.stringify(data.dispo));
    return {
        type: actions.SET_OPTIONS,
        payload: data
    }
}

export const setQueue = data => {
    localStorage.setItem("selectedQueue",data );
    return {
        type: actions.SET_QUEUE,
        payload: data
    }
}



export const setPause = data => {
    localStorage.setItem("selectedPause", data);
    return {
        type: actions.SET_PAUSE,
        payload: data
    }
}

export const loginTime = data => {
    localStorage.setItem("loginTime", data);
    return {
        type: actions.LOGIN_TIME,
        payload: data
    }
}

export const pauseTime = data => {
    localStorage.setItem("pauseTime", data);
    return {
        type: actions.PAUSE_TIME,
        payload: data
    }
}

export const SetDid = data => {
    
    return {
        type: actions.DID,
        payload: data
    }
}


import React, { useState, useEffect, useMemo } from 'react'
import CallScreen from '../components/CallScreen'
import ContactMainView from '../components/Contacts/ContactMainView'
import LeadMainView from '../components/Lead/LeadMainView'
import TicketMainView from '../components/Tickets/TicketMainView'
import MeetingsideBar from '../components/Lead/Leadsideview'
import '../assets/css/Leads.css'
import EventEmitter from '../utils/EventEmitter'
import { useParams } from 'react-router-dom'
import { callLeadDetail } from '../redux/background/BackgroundAction'
import { connect, useDispatch, useSelector } from 'react-redux'
import {
    fetch_meetings,
    editMeeting,
    create_meeting,
} from '../redux/actions/Meetings'
import {
    TicketFetch,
    TicketCustomformFields,
} from '../redux/actions/TicketAction.js'
import {
    ContactFetch,
    ContactCustomformFields,
} from '../redux/actions/ContactActions'
import { Decode } from '../utils/EncodeDecode'
import { CustomformData, CustomformFields } from '../redux/actions/Customform'

function MeetingsView(props) {
    let Dispatch = useDispatch()
    const [name, setname] = useState()
    let { phone_number, meeting_id } = useParams()
    phone_number =Decode(phone_number)
    const agent = localStorage.getItem('user')
    let state_value = useSelector((state) => state)
    const [create, setcreate] = useState(false)
    const [formData, setformData] = useState({})
    const [isthere, setisthere] = useState(false)
    const [formfield, setformfield] = useState([])
    const [models, setmodels] = useState(null)
    const [id, setid] = useState("")

    function fetchapi(params) {
        mount()
    }
    useMemo(() => {

        mount()
    }, [phone_number])

    function mount() {
        let value = {
            skip: 0,
            take: 10,
            userID: agent,
            meetingID: meeting_id,
        }
        Dispatch(fetch_meetings(value))
            .then(async function (response) {
                let model = response.data.data[0].model
                setmodels(model)
                let id = response.data.data[0].leadID
                if (model === "contact") {
                    Dispatch(ContactCustomformFields()).then((response) => {
                        setformfield(response.data)

                        if (props.contactmodule) {
                            setformData({ status: 204, data: "" })
                            setcreate(true)
                            setisthere(true)

                            if (state_value.background.oncall === true && localStorage.getItem("dialpadname") === "") {
                                localStorage.setItem("dialpadname", "Unknown Number");
                                setname(localStorage.getItem("dialpadname"))
                            }
                        } else {
                            Dispatch(
                                ContactFetch(
                                    `/contact/fetchsearch?number=${phone_number}`,
                                ),
                            ).then((res) => {
                                if (res.data.data.length === 0) {
                                    res.status = 204
                                    setcreate(true)
                                    setisthere(true)
                                    setformData(res)

                                    if (state_value.background.oncall === true && localStorage.getItem("dialpadname") === "") {
                                        localStorage.setItem("dialpadname", "Unknown Number");
                                        setname(localStorage.getItem("dialpadname"))
                                    }
                                } else if (res.data.statusCode == 200) {


                                    setformData(res)
                                    setcreate(false)
                                    setid(res.data.data[0].id)
                                    setisthere(true)
                                    let names = res.data.data[0].name
                                    if (names === "") {
                                        names = res.data.data[0].phone_number
                                    }
                                    localStorage.setItem("dialpadname", names);
                                    setname(localStorage.getItem("dialpadname"))
                                    if (state_value.background.oncall && state_value.background.oncalllead[0].render < 1
                                        && state_value.background.socket_message.phonenumber.toString() === phone_number) {
                                        let leadData = {
                                            lead_id: res.data.data[0].id,
                                            lead_name: res.data.data[0].name,
                                            phone_number: res.data.data[0].phone_number,
                                            module: "contact",
                                            render: 1
                                        }
                                        Dispatch(callLeadDetail(leadData))
                                    }

                                }
                            }).catch((err) => {
                                EventEmitter.emit('error-message-getter', {
                                    action: 'fetch',
                                    module: 'contact',
                                })
                            })
                        }
                    }).catch((err) => {
                        EventEmitter.emit('error-message-getter', {
                            action: 'fetch',
                            module: 'form fields',
                        })
                    })
                } else if (model === "ticket") {
                    let campaigninfo = JSON.parse(localStorage.getItem('selectedCampaign_data'))
                    Dispatch(TicketCustomformFields()).then((response) => {
                        setformfield(response.data)

                        if (props.ticketmodule) {
                            setformData({ status: 204, data: "" })
                            setcreate(true)
                            setisthere(true)

                            if (state_value.background.oncall === true && localStorage.getItem("dialpadname") === "") {
                                localStorage.setItem("dialpadname", "Unknown Number");
                                setname(localStorage.getItem("dialpadname"))
                            }
                        } else {
                            Dispatch(
                                TicketFetch(
                                    `/tickets/fetch?ticket_id=${id}&portal=false&All=false&template_name=${campaigninfo.template_name
                                    }&phone_number=${phone_number}`,
                                ),
                            ).then((res) => {
                                if (res.status == 204) {
                                    setcreate(true)
                                    setisthere(true)
                                    setformData(res)

                                    if (state_value.background.oncall === true && localStorage.getItem("dialpadname") === "") {
                                        localStorage.setItem("dialpadname", "Unknown Number");
                                        setname(localStorage.getItem("dialpadname"))
                                    }
                                } else if (res.data.statusCode == 200) {
                                    setformData(res)
                                    setcreate(false)
                                    let names = res.data.data[0].basic.first_name
                                    setid(phone_number)
                                    setisthere(true)
                                    if (names === "") {
                                        names = res.data.data[0].basic.phone_number
                                    }
                                    localStorage.setItem("dialpadname", names);
                                    setname(localStorage.getItem("dialpadname"))
                                    if (state_value.background.oncall && state_value.background.oncalllead[0].render < 1 && state_value.background.socket_message.phonenumber.toString() === phone_number) {
                                        let leadData = {
                                            lead_id: res.data.data[0].others.ticket_id,
                                            lead_name: res.data.data[0].basic.first_name,
                                            phone_number: res.data.data[0].others.phone_number,
                                            module: "ticket",
                                            render: 1
                                        }
                                        Dispatch(callLeadDetail(leadData))
                                    }

                                }
                            }).catch((err) => {
                                EventEmitter.emit('error-message-getter', {
                                    action: 'fetch',
                                    module: 'ticket',
                                })
                            })
                        }
                    }).catch((err) => {
                        EventEmitter.emit('error-message-getter', {
                            action: 'fetch',
                            module: 'form fields',
                        })
                    })
                } else {
                    let campaigninfo = JSON.parse(localStorage.getItem('selectedCampaign_data'))
                    Dispatch(CustomformFields(campaigninfo.template_name)).then((response) => {
                        setformfield(response.data.data[0])
                        let payload = {
                            template_name: campaigninfo.template_name,
                            type: campaigninfo.type,
                            phone_number: phone_number,
                            id:id

                        }
                        Dispatch(CustomformData(payload)).then((res) => {
                            if (res.status == 204) {
                                setcreate(true)
                                setisthere(true)
                                setformData(res)

                                if (state_value.background.oncall === true && localStorage.getItem("dialpadname") === "") {
                                    localStorage.setItem("dialpadname", "Unknown Number");
                                    setname(localStorage.getItem("dialpadname"))
                                }
                            } else if (res.data.statusCode == 200) {


                                setformData(res)
                                setcreate(false)
                                setid(res.data.data[0].others.lead_id)
                                setisthere(true)
                                if (state_value.background.oncall && state_value.background.oncalllead[0].render < 1 && state_value.background.socket_message.phonenumber.toString() === phone_number) {
                                    let leadData = {
                                        lead_id: res.data.data[0].others.lead_id,
                                        lead_name: res.data.data[0].others.first_name,
                                        phone_number: res.data.data[0].others.phone_number,
                                        module: "lead",
                                        render: 1
                                    }
                                    Dispatch(callLeadDetail(leadData))
                                    let names = res.data.data[0].others.first_name
                                    if (names === "") {
                                        names = res.data.data[0].others.phone_number
                                    }
                                    localStorage.setItem("dialpadname", names);
                                    setname(localStorage.getItem("dialpadname"))
                                }


                            }
                        }).catch((err) => {
                            EventEmitter.emit('error-message-getter', {
                                action: 'fetch',
                                module: 'lead data',
                            })
                        })
                    }).catch((err) => {
                        EventEmitter.emit('error-message-getter', {
                            action: 'fetch',
                            module: 'form fields',
                        })
                    })
                }
            })

    }

    return (
        <div className="page-main-container">
            {isthere && (
                <>
                    <div className="scroll-lft leads-lft">
                        {create === false && (
                            <div className="leads-sidebar">
                                <MeetingsideBar
                                    formdatas={formData}
                                    type={models}
                                    id={id}

                                />
                            </div>
                        )}
                        <div
                            className={
                                create
                                    ? 'leads-main leads-main-new'
                                    : 'leads-main leads-main-exist'
                            }
                        >
                            {models === "contact" && <ContactMainView
                                fetchapi={fetchapi}
                                operation={create}
                                formdatas={formData}
                                statedata={formfield}
                                type={"contact"}

                            />}
                            {models === "ticket" && <TicketMainView
                                fetchapi={fetchapi}
                                operation={create}
                                formdatas={formData}
                                statedata={formfield}
                                type={"ticket"}
                            />}
                            {models === "lead" && <LeadMainView
                            fetchapi={fetchapi}
                                operation={create}
                                formdatas={formData}
                                statedata={formfield}
                                type={"lead"}

                            />}
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default MeetingsView

import React from 'react'
import Nav from '../components/navbar'
import { Outlet } from 'react-router-dom'
import CallScreen from '../components/CallScreen'
function Layout() {
  return (
    <div className="app-wrapper">
      <div className="main-container">
        <Nav />
        <div className="content">
          <Outlet />
          <div className="scroll-rgt" md={4}>
            <CallScreen />
          </div>
        </div>

      </div>
    </div>
  )
}

export default Layout

export default function environment() {
    var env = "qa-agent-production"
    var credentials = {};
    if (env === 'production') {
      credentials.common_api_url = 'https://api.doocti.com';
      credentials.security_key = "securitykey-here"
    } else if (env === 'development') {
      credentials.login="https://api.doocti.com"; 
      credentials.common_api_url = 'https://api-dev.doocti.com';
      credentials.security_key = "securitykey-here"
    } else if (env === 'demo') {
      credentials.common_api_url = 'https://api-demo.doocti.com';
      credentials.security_key = "securitykey-here"
    } else if (env === 'test') {
      credentials.common_api_url = 'https://test.doocti.com';
      credentials.security_key = "securitykey-here"
    }else if (env === 'qa-agent-production') {
      credentials.login="https://api.doocti.com"; 
      // credentials.common_api_url = 'http://0.0.0.0:8101';
      credentials.common_api_url = 'https://api-v2.doocti.com';
      credentials.security_key = "Ndoocti"
    }
    return credentials;
  }
  
import api from "../../utils/axios-util";

export const ContactFetch = (url) => {
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      await api
        .get(url)
        .then((response) => {

          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};
export const ContactCustomformFields = () => {
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      await api
        .get(`/contactfields/fetch`)
        .then((response) => {

          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};
export const AsterContact = (skipdata) => {
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      await api.get(`/contact/fetchsearch?skip=${skipdata.skip}&take=${skipdata.take}`).then(response => {
         resolve(response);
      }).catch((err) => {
        reject(err)
      })
    })
  }
}

export const contactSearch = (skipdata) => {
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      await api.get(`/contact/fetchsearch?value=${skipdata.value}&skip=${skipdata.skip}&take=${skipdata.take}`).then(response => {
        if (response.status === 204) {
          response.data = []
        }
        resolve(response);
      }).catch((err) => {
        reject(err)
      })
    })
  }



};

export const fetchCountry = (payload) => {

  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      await api.get(`/country/fetch?agent=true&country_id=&state_id=`).then(response => {
        resolve(response);

      }).catch((err) => {
        reject(err)
      })
    })
  }
}

export const fetchState = (value) => {
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      await api.get(`/country/fetch?agent=true&country_id=${value}&state_id=`).then(response => {
        resolve(response);
      }).catch((err) => {
        reject(err)
      })
    })
  }
}

export const fetchCity = (value) => {
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      await api.get(`/country/fetch?agent=true&country_id=&state_id=${value}`).then(response => {
        resolve(response);
      }).catch((err) => {
        reject(err)
      })
    })
  }
}

export const contactCreate = (payload) => {
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      await api
        .post(`/contact/create`, payload)
        .then((response) => {

          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};

export const ContactUpdate = (ticket_id, payload) => {
  return (dispatch, getState) => {
      return new Promise(async (resolve, reject) => {
          await api
              .put(`/contact/update/${ticket_id}`, payload)
              .then((response) => {

                  resolve(response);
              })
              .catch((err) => {
                  reject(err);
              });
      });
  };
};

export const FetchTemplate = (url) => {
  return (dispatch, getState) => {
      return new Promise(async (resolve, reject) => {
          await api
              .get(url)
              .then((response) => {

                  resolve(response);
              })
              .catch((err) => {
                  reject(err);
              });
      });
  };
};
export const GetAsterCustommoduleData = (payload) => {
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {

      await api
        .post("/formbuilder/aster/form/fetch"+payload.url,payload.bodydata)
        .then((response) => {

          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};
import React, { useState, useEffect, useRef } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { MeetingFetch } from '../../redux/actions/Asterleads'
import Format from '../../utils/format-text'
import { Button } from 'react-bootstrap'
import { Outbound } from '../../redux/SocketAction/SocketAction'
import InfiniteScroll from 'react-infinite-scroll-component'
import EventEmitter from '../../utils/EventEmitter'
import { useNavigate } from 'react-router'
import { Form } from 'react-bootstrap'
import { triggerdispostate, callLeadDetail } from "../../redux/background/BackgroundAction";
import moment from 'moment'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import {
  fetch_meetings,
  editMeeting, Meetingtitle,
  create_meeting,
} from '../../redux/actions/Meetings'
import Dialogbox from '../Dialogbox'
import { useParams, useLocation } from 'react-router-dom'
import { callmodule } from '../../redux/Calls/CallAction'
import { Encode, Decode } from '../../utils/EncodeDecode'
function LeadMeetings(props) {
  const [checked, setchecked] = useState(false)
  let navigate = useNavigate()
  let AppPermission
  if (localStorage.getItem('config')) {
    AppPermission = JSON.parse(localStorage.getItem('config')).data.projects[0].userPermission
  }
  const location = useLocation()
  let wasession = localStorage.getItem("wasession")
  let { phone_number, meeting_id, ticket_id, contact_id } = useParams()
  phone_number = Decode(phone_number)
  const agent = localStorage.getItem('user')
  const [Meetingdata, setMeetingdata] = useState([])
  const [checkedItems, setCheckedItems] = useState(Array(Meetingdata.length).fill(false));
  const [meetingCheckId,setMeetingCheckId]=useState(undefined)
  let stateValue = useSelector((state) => state)
  const [editId, setEditId] = useState('')
  const [skipdata, setskipdata] = useState({ skip: 0, take: 10 })
  const [count, setcount] = useState(0)
  const [infinitScroll, setinfinitScroll] = useState({ limit: 7, more: true })
  const [shedulealart, setshedulealart] = useState(false)
  const [popupdatatoupdate, setpopupdatatoupdate] = useState({
    open: false,
    Type: 'form_bar',
    field:
      [
        {
          type: 'SelectList',
          label: 'Meeting Title',
          placeholder: 'please select meeting title',
          required: true,
          model: 'name',
          option: [],
          multiple: false,
          value: '',
          readonly: false,
        },
        {
          type: 'TextInput',
          label: 'others',
          placeholder: 'enter Meeting Name',
          required: false,
          model: 'others',
          value: "",
          readonly: false,
          multiple: false,
          fieldtype: 'others',
          model_type: "Text",
          show: false
        }
        ,{
          type: 'SelectList',
          label: 'Meeting Sub Title',
          placeholder: 'please select meeting sub title',
          required: false,
          model: 'meeting_sub_title',
          option: [],
          multiple: false,
          value: '',
          readonly: false,
          show:true
        },
        {
          type: 'TextInput',
          label: 'Phone Number',
          placeholder: 'Phone Number',
          required: true,
          model: 'meetingphoneNumber',
          value: "",
          readonly: true,
          multiple: false,
          fieldtype: 'phone_number',
          model_type: "Number"
        },
        {
          type: 'DatetimePicker',
          label: 'Schedule Date',
          placeholder: 'please select',
          required: true,
          model: 'scheduleDate',
          multiple: true,
          value: '',
        },
        {
          type: 'LongTextInput',
          label: 'Description',
          placeholder: 'enter your comments',
          required: true,
          model: 'comments',
          value: '',
          multiple: false,
        },
      ],
    title: 'Update Schedule Date (Schedule Date Must 30Min After)',
    Message: 'Updated Successfully',
    Butonstatus: 'Save',
  })
  // const [popupdatatoupdate, setpopupdatatoupdate] = useState({
  // open: false,
  // Type: 'form_bar',
  // field: [
  // {
  // type: 'DatetimePicker',
  // label: 'select DatetimePicker',
  // placeholder: 'please select',
  // required: true,
  // model: 'schedule_date',
  // multiple: true,
  // value: '',
  // },
  // ],
  // title: 'Update Schedule Date (Schedule Date Must 30Min After)',
  // Message: 'Updated Successfully',
  // Butonstatus: 'Save',
  // })
  
  const handleCheckboxChange = (index) => {
    const newCheckedItems = [...checkedItems];
    newCheckedItems[index] = !newCheckedItems[index];
    setCheckedItems(newCheckedItems);
  };
  const [popUpData1, setpopUpData1] = useState({
    open: false,
    Type: 'Notification_bar',
    field: [],
    title: 'Select Schedule Date',
    Message: 'Updated Successfully',
    Butonstatus: 'OK',
  })

  const [statuspopupdata, setstatuspopupdata] = useState({
    open: false,
    Type: 'form_bar',
    field: [],
    title: 'Change Meeting Status',
    Message: `once you change the status to completed can't revock !!! `,
    Butonstatus: 'Save',
  })


  const [popupdata, setpopupdata] = useState({
    open: false,
    Type: 'form_bar',
    field: [
      {
        type: 'SelectList',
        label: 'Meeting Title',
        placeholder: 'please select meeting title',
        required: true,
        model: 'name',
        option: [],
        multiple: false,
        value: '',
        readonly: false,
      },
     
      {
        type: 'TextInput',
        label: 'others',
        placeholder: 'enter Meeting Name',
        required: false,
        model: 'others',
        value: "",
        readonly: false,
        multiple: false,
        fieldtype: 'others',
        model_type: "Text",
        show: false
      }
      , {
        type: 'SelectList',
        label: 'Meeting Sub Title',
        placeholder: 'please select meeting sub title',
        required: false,
        model: 'meeting_sub_title',
        option: [],
        multiple: false,
        value: '',
        readonly: false,
        show:true
      },
      {
        type: 'TextInput',
        label: 'Phone Number',
        placeholder: Format(phone_number, { maskbool: true }),
        required: true,
        model: 'phoneNumber',
        // value: phone_number,
        readonly: true,
        multiple: false,
        fieldtype: 'phone_number',
        model_type: "Number"
      },
      {
        type: 'DatetimePicker',
        label: 'Schedule Date',
        placeholder: 'please select',
        required: true,
        model: 'scheduleDate',
        multiple: true,
        value: moment(datetime()).add(35, 'm').toDate(),
      },
      {
        type: 'LongTextInput',
        label: 'Description',
        placeholder: 'enter your comments',
        required: true,
        model: 'comments',
        value: '',
        multiple: false,
      },
    ],

    title: 'Create Meeting',
    Message: 'Created Successfully ',
    Butonstatus: 'Create',
  })

  let dispatch = useDispatch()
  useEffect(() => {
    MeetingFetchs()
    fetchmeetingTitle()
  }, [])
  useEffect(() => {
    if (stateValue.background.socket_message.evt == 'RINGING') {
      MeetingFetchs("call")
    }

  }, [stateValue.background.socket_message.evt])
  function fetchmeetingTitle() {
    dispatch(Meetingtitle()).then(function (res) {
      if (res.data.statusCode == 200) {
        let arr = []
        for (let i = 0; i < res.data.data.length; i++) {
          const e = res.data.data[i];
          arr.push({
            label: e.title,
            value: e.title,
            subtitle:e.sub_title
          })
        }
        arr.push({
          label: "others",
          value: "others"
        })
        setpopupdata((set) => {
          set.field[0].option = arr
          return set
        })
        setpopupdatatoupdate((set) => {
          set.field[0].option = arr
          return set
        })

        console.log(arr, "setmeeting_titlesetmeeting_titlesetmeeting_title")
      }
    })
  }
  const MeetingFetchs = (action) => {
    let lead = ''
    if (meeting_id === undefined) {
      if (props.id === 'nolead') {
        lead = ''
      } else {
        lead = props.id
      }
    } else {
      lead = meeting_id
    }
    let value
    if (action === 'action') {
      value = {
        skip: 0,
        take: 10,
        userID: agent,
        module: props.type,
        meetingID: meeting_id,
        phone_number: phone_number
      }
    } else if (action === "call") {
      value = {
        skip: 0,
        take: 10,
        userID: agent,
        module: props.type,
        meetingID: meeting_id,
        phone_number: phone_number
      }
    }
    else {
      value = {
        skip: skipdata.skip,
        take: skipdata.take,
        userID: agent,
        module: props.type,
        meetingID: meeting_id,
        phone_number: phone_number
      }
    }
    if (location.pathname === `/meeting/${Encode(phone_number)}/${meeting_id}`) {
      delete value.module
      delete value.phone_number
    }
    if(meetingCheckId!==undefined){
     delete value.meetingID
    }
    dispatch(fetch_meetings(value))
      .then(async function (response) {
        if (response.data.statusCode === 200) {
          setcount(response.data.totalCount)
          if (action === 'action') {
            setMeetingdata([].concat(response.data.data))

            setskipdata((set) => {
              set.skip = 0
              return {
                ...set,
              }
            })
          } else {
            if (action === "call") {
              setMeetingdata([].concat(response.data.data))
            } else {
              setMeetingdata(Meetingdata.concat(response.data.data))
            }


            setskipdata((set) => {
              set.skip = set.skip + 10
              return {
                ...set,
              }
            })
          }
        } else {
          setMeetingdata([])
        }
      })
      .catch((err) => {
        EventEmitter.emit('error-message-getter', {
          action: 'fetch',
          module: "meeting",
        })
      })
  }
  const dialogopen = (data) => {
    setpopupdata((set) => {
      set.open = data
      return {
        ...set,
      }
    })
  }
  const dialogopentoupdate = (data, id, date) => {
    if (id) setEditId(id)
    setpopupdatatoupdate((set) => {

      let name = set.field[0].option.find((e) => {
        if (e.value === date.meeting_name) {
          return e.value
        }
      })
      if (name === undefined) {
        name = 'others'
        set.field[1].show = true
        set.field[1].required = true
      } else {
        name = name.value
        set.field[1].show = false
        set.field[1].required = false
      }
      let subtitle=[]
      if(name!=='others'){
        for (let i = 0; i < popupdatatoupdate.field[0].option.length; i++) {
          const element = popupdatatoupdate.field[0].option[i];
          if(element.label===name){
           let subtitlearr=element.subtitle.split(',')
           for (let j = 0; j < subtitlearr.length; j++) {
            const subtitleelement = subtitlearr[j];
            subtitle.push({
              label:subtitleelement,
              value:subtitleelement,
            })
            
           }
          }
          
        }
      }
      console.log(name, "rrrrrrrrrrrrrrrrwwwrrrrrrrrrrrr", date)
      set.open = data
      set.field[2].option = subtitle
      set.field[0].value = name
      set.field[2].value = date.meeting_sub_title
      set.field[1].value = date.meeting_name
      set.field[3].value = date.leadNumber
      // set.field[3].value = date
      set.field[4].value = date.scheduledTime
      set.field[5].value = date.subject
      
      return {
        ...set,
      }
    })
  }
  const notify_bar = (data, action) => {
    setpopUpData1((set) => {
      set.open = data
      if (action == 'update') {
        set.Message = 'Update Sucessfully'
      } else {
        set.Message = 'Created Sucessfully'
      }
      return {
        ...set,
      }
    })
  }
  const closeNotify = (data) => {
    setpopUpData1(data)
  }
  const closepopup = (data) => {
    if (data.action === 'create') {
      let selected_campaign = localStorage.getItem('selectedCampaign')
      let selected_user = localStorage.getItem('user')
      let lead_id
      let modelvalue
      if (props.type === "ticket") {
        modelvalue = props.type
        lead_id = props.id
      }
      else if (props.type === "contact") {
        modelvalue = props.type
        lead_id = props.id
      }
      else {
        modelvalue = "lead"
        lead_id = props.id
      }
     
      let payload = {
        meetingName: data.data[0]['name'] === 'others' ? data.data[0].others : data.data[0]['name'],
        meetingTilte: data.data[0]['comments'],
        meetingTime: data.data[0]['scheduleDate'],
        meeting_sub_title: data.data[0]['meeting_sub_title'],
        user_email: selected_user,
        leadID: `${lead_id}`,
        phoneNumber: phone_number,
        campaign: selected_campaign,
        module: modelvalue,
        callerName: props.name === "" ? phone_number : props.name
      }
      console.log(payload,"data.data[0]data.data[0]data.data[0]")
      dispatch(create_meeting(payload))
        .then(function (res) {
          if (res.data.statusCode == 201) {
            setshedulealart(false)
            notify_bar(true, 'create')
            MeetingFetchs('action')
            setpopupdata((data) => {
              data.open = false
              return {
                ...data,
              }
            })
          }
          if (res.data.statusCode == 204) {
            setshedulealart(true)

          }

        })
        .catch((err) => {

          EventEmitter.emit('error-message-getter', {
            action: 'create',
            module: 'meeting',
          })
        })
    } else {
      setpopupdata((data) => {
        data.open = false
        return {
          ...data,
        }
      })
    }
  }
  const closepopuptoupdate = (data) => {
    if (data.action === 'create') {
      if (data.data[0].name === 'others') {
        data.data[0].name = data.data[0].others
      }
      delete data.data[0].others
      delete data.data[0].meetingphoneNumber

      dispatch(editMeeting(editId, data.data[0]))
        .then(function (res) {
          if (res.data.statusCode == 200) {
            setshedulealart(false)
            notify_bar(true, 'update')
            MeetingFetchs('action')
            setpopupdatatoupdate((data) => {
              data.open = false
              return {
                ...data,
              }
            })
          }
          if (res.status == 204) {
            setshedulealart(true)

          }
        })
        .catch((err) => {
          EventEmitter.emit('error-message-getter', {
            action: 'update',
            module: 'meeting',
          })
        })

    } else {
      setpopupdatatoupdate((data) => {
        data.open = false
        return {
          ...data,
        }
      })
    }
  }
  function datetime() {
    var currentdate = new Date()
    return currentdate
  }
  const fetchMoreData = () => {
    if (Meetingdata.length >= count) {
      setinfinitScroll((set) => {
        set.more = false
        return {
          ...set,
        }
      })
    } else {
      setTimeout((v) => {
        let data = {
          skip: skipdata.skip + 10,
          take: 10,
        }

        MeetingFetchs()
      }, 500)
    }
  }
  function clickcall(data, id) {

    let readystate = localStorage.getItem("selectedPause")
    if (readystate == "available") {
      let payload = {
        state: stateValue.background.websocketconnection,
        value: data.toString()
      }
      dispatch(callmodule({
        id: meeting_id.toString(),
        module: "meeting",
        phone_no: phone_number.toString()
      }))
      dispatch(Outbound(payload.state, payload.value, { meeting: true, meetingid: id }))
      let names
      if (props.name === "") {
        names = phone_number.toString()
      } else {
        names = props.name
      }
      localStorage.setItem("dialpadname", names);
      let leadData = {
        lead_id: props.id,
        lead_name: props.name,
        phone_number: phone_number.toString(),
        module: props.type,
        render: 1
      }
      dispatch(callLeadDetail(leadData))

    } else {

      EventEmitter.emit('error-message-getter', {
        action: 'ready',
        module: "Agent Not ready",
      })
    }

  }
  function redirecttomeetings(id, number) {
    navigate(`/meeting/${Encode(number)}/${id}`)
  }

  function assign(id) {
    setstatuspopupdata((set) => {
      set.open = true
      return {
        ...set,
      }
    })
    setchecked(true)
    if(id !== undefined){
      setMeetingCheckId(id)
    }
  }
  function closestatuspopup(data) {
    if (data.action === "cancel") {
    const firstCheckedIndex = checkedItems.indexOf(true)
     handleCheckboxChange(firstCheckedIndex)
      setchecked(false)
      setstatuspopupdata((set) => {
        set.open = false
        return {
          ...set,
        }
      })
      
    } else {
      // let status = { status: 1 , sms_notify:"0", wa_notify:"0", email_notify:"0",api_push:"0" }
      let status = { status: 1 }
      if(meetingCheckId !==undefined){
        meeting_id=meetingCheckId
      }
      dispatch(editMeeting(meeting_id, status))
        .then(function (res) {
          if (res.status === 200) {
            MeetingFetchs('action')
            setstatuspopupdata((set) => {
              set.open = false
              return {
                ...set,
              }
            })

          }if (res.status == 204) {
            setshedulealart(true)
 
          }
        })
        .catch((err) => {
          EventEmitter.emit('error-message-getter', {
            action: 'update',
            module: 'meeting status',
          })
        })
    }
  }

  function timeSince(value) {
    let date = new Date(value)
    var seconds = Math.floor((new Date() - date) / 1000)

    var interval = seconds / 31536000

    if (interval > 1) {
      return Math.floor(interval) + ' year ago'
    }
    interval = seconds / 2592000
    if (interval > 1) {
      return Math.floor(interval) + ' month ago '
    }
    interval = seconds / 86400
    if (interval > 1) {
      return Math.floor(interval) + ' day ago'
    }
    interval = seconds / 3600
    if (interval > 1) {
      return Math.floor(interval) + ' hour ago'
    }
    interval = seconds / 60
    if (interval > 1) {
      return Math.floor(interval) + ' minute ago'
    }
    return Math.floor(seconds) + ' second ago'
  }
  const isshow = (data,value) => {
    
    let subtitle=[]
    if(value!=='others'){
      for (let i = 0; i < popupdata.field[0].option.length; i++) {
        const element = popupdata.field[0].option[i];
        console.log(element.label===value,"rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr")
        if(element.label===value){
         let subtitlearr=element.subtitle.split(',')
         for (let j = 0; j < subtitlearr.length; j++) {
          const subtitleelement = subtitlearr[j];
          subtitle.push({
            label:subtitleelement,
            value:subtitleelement,
          })
          
         }
        }
        
      }
    }
    
    setpopupdata((set) => {
      set.field[1].show = data
      set.field[1].required = data
      if(value=='others'){
        set.field[2].show = false
      }else{
        if(subtitle.length>0 && subtitle[0].label !== '' &&  subtitle[0].value !== ''){
          set.field[2].show = true
        }else{
          set.field[2].show = false
        }
      }
      set.field[2].option = subtitle
      return set
    })
    setpopupdatatoupdate((set) => {
      set.field[1].show = data
      set.field[1].required = data
      if(value=='others'){
        set.field[2].show = false
      }else{
        if(subtitle.length>0 && subtitle[0].label !== '' &&  subtitle[0].value !== ''){
          set.field[2].show = true
        }else{
          set.field[2].show = false
        }
      }
      set.field[2].option = subtitle


      return set
    })
  }
  return (
    <>
      <Dialogbox
        fields={closestatuspopup}
        value={statuspopupdata}
        defaultclass={'dialog-body-default'}
      />
      <Dialogbox
        shedulealart={shedulealart}
        fields={closepopuptoupdate}
        value={popupdatatoupdate}
        isshow={isshow}
        defaultclass={'dialog-body-default1'}
      />
      <Dialogbox
        shedulealart={shedulealart}
        fields={closepopup}
        value={popupdata}
        isshow={isshow}
        defaultclass={'dialog-body-default1'}
      />
      <Dialogbox
        fields={closeNotify}
        value={popUpData1}
        className="dialog-body-default1"
      />
      <>
        {location.pathname !== `/meeting/${Encode(phone_number)}/${meeting_id}` && (
          <>
            <div className="lead-meeting-main-div">
              <div className="lead-notepad-head Customform-header ">
                <div className="lead-notepad-head-lft lead-meetings-head-lft">

                  <div className="lead-notepad-head-title">{Format(props.type) + ' Meeting'} </div>
                </div>
                {(props.type === "lead" || props.type === "ticket" || props.type === "contact") && AppPermission.lead_meeting_create && <div
                  className=" lead-save-icon lead-notepad-head-rht material-icons"
                  onClick={() => {
                    dialogopen(true)
                  }}
                >
                  add_circle
                </div>}
              </div>
              <div
                id="MeetingsDiv"
                style={{
                  height: 'calc(100% - 70px)',
                  overflow: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                }}
                className="lead-meeting-main-div-bottom"
              >
                {Meetingdata.length > 0 && (
                  <>
                    <InfiniteScroll
                      scrollableTarget="MeetingsDiv"
                      dataLength={Meetingdata.length} //This is important field to render the next data
                      next={fetchMoreData}
                      hasMore={infinitScroll.more}
                    >
                      {Meetingdata.map((value,index) => {
                        return (
                          <>
                   
                          <div className="lead-meeting">
                          <div className="Customform-save-bts">
                           {Meetingdata[index].status !== "completed" && AppPermission.meeting_edit && (
                       <OverlayTrigger
                           placement={'bottom'}
                           overlay={<Tooltip>Mark As Completed</Tooltip>}
                            >
                           <div className="meeting-status">
                             <Form.Check
                               checked={checkedItems[index]}
                               type="checkbox"
                               id="custom-check"
                               className="check-box"
                               onChange={(e) => {
                                handleCheckboxChange(index)
                                 if (e.target.checked.toString() === 'true') {
                                   assign(value.meetingID)
                                 }
                               }}
                             />
                           </div>
                      </OverlayTrigger>
                         )}
                   </div>
                            <div className="lead-meeting-lft">
                              <div className="lead-meeting-header">

                                <strong
                                  className={
                                    value.status === 'upcoming'
                                      ? 'lead-Upcoming-lable' : value.status === 'completed' ? "lead-completed-lable"
                                        : 'lead-expire-lable'
                                  }
                                >
                                  {Format(value.status)}{' '}
                                </strong>
                              </div>
                              <div className="lead-meeting-subject">
                                <p>Meeting Time:  {value.scheduledTime.split(" ")[0]} {moment(value.scheduledTime.split(" ")[1], ["HH.mm.ss"]).format("hh:mm a")}</p>

                              </div>
                            </div>

                            <div className="lead-meeting-rht">
                            {AppPermission.meeting_reschedule  && (<span
                                className="material-icons lead-shedule-icon custom-cursor"
                                style={{
                                  color: value.status === 'completed' ? '#ccc' : '#FF9900',
                                  pointerEvents: value.status === 'completed' ? 'none' : 'auto',
                                  opacity: value.status === 'completed' ? 1 : 1,
                                }}
                                onClick={() =>
                                  dialogopentoupdate(
                                    true,
                                    value.meetingID,
                                    value,
                                  )
                                }
                              >
                                history
                              </span>)}
                              {AppPermission.view_meeting && <span
                                className="material-icons lead-shedule-icon custom-cursor"
                                style={{
                                    color: value.status === 'completed'?'#ccc':'#3e98c7', 
                                    background: "rgb(62 152 199 / 15%)",
                                    pointerEvents: value.status === 'completed' ? 'none' : 'auto',
                                    opacity: value.status === 'completed' ? 1 : 1, 
                                  }}
                                onClick={() =>
                                  redirecttomeetings(
                                    value.meetingID, phone_number
                                  )
                                }
                              > group
                              </span>}
                            </div>


                          </div>
                          </>
                        )
                      })}
                    </InfiniteScroll>
                  </>
                )}

                {Meetingdata.length === 0 && (
                  <div
                    className="nodata "
                    style={{ margin: 'auto', width: '70px' }}
                  >
                    nodata
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </>
      <>
        {location.pathname == `/meeting/${Encode(phone_number)}/${meeting_id}` &&
          Meetingdata.length > 0 && (
            <div className="lead-meetings-view">
              <div className="lead-meetings-view-header">
                <strong className={'lead-meetings-view-header-text'}>
                  <span

                    className={
                      Meetingdata[0].status === 'upcoming'
                        ? 'lead-Upcoming-lable' : Meetingdata[0].status === 'completed' ? "lead-completed-lable"
                          : 'lead-expire-lable'
                    }
                  >

                    {`${Format(Meetingdata[0].status)}`}
                  </span>


                </strong>

                <div className="Customform-save-bts">
                  {Meetingdata[0].status !== "completed" && AppPermission.meeting_edit && (
                    <OverlayTrigger
                      placement={'bottom'}
                      overlay={<Tooltip>Mark As Completed</Tooltip>}
                    >
                      <div className="Customform-save-icon ">
                        <Form.Check
                          checked={checked}
                          type="checkbox"
                          id="custom-check"
                          className="check-box"
                          onChange={(e) => {
                            if (e.target.checked.toString() === 'true') {
                              assign()
                            }
                          }}
                        />
                      </div>
                    </OverlayTrigger>
                  )}


                </div>

                {AppPermission.meeting_reschedule && <span>
                  <div
                   className={`material-icons lead-shedule-icon custom-cursor ${
                    Meetingdata[0].status === 'completed' ? 'disabled-icon' : ''
                  }`}
                  style={{
                    cursor:'pointer',
                    color: Meetingdata[0].status === 'completed' ? '#ccc' : '#FF9900',
                    pointerEvents: Meetingdata[0].status === 'completed' ? 'none' : 'auto',
                    opacity: Meetingdata[0].status === 'completed' ? 1 : 1,
                  }}
                    onClick={() =>
                      dialogopentoupdate(
                        true,
                        Meetingdata[0].meetingID,
                        Meetingdata[0],
                      )
                    }
                  >
                    history
                  </div>
                </span>}
                <span>
                  {AppPermission.click_to_call && <div
                    className={stateValue.background.oncall == false && wasession === "false" ? "material-icons lead-meeting-call-icon" : "material-icons lead-meeting-call-icon disabledbutton"}
                    style={{
                      color: Meetingdata[0].status === 'completed' ? '#ccc' : '#32AA52',
                      pointerEvents: Meetingdata[0].status === 'completed' ? 'none' : 'auto',
                      opacity: Meetingdata[0].status === 'completed' ? 1 : 1,
                    }}
                    onClick={() =>
                      clickcall(Meetingdata[0].phoneNumber, meeting_id)
                    }
                  >
                    phone
                  </div>}
                </span>
              </div>
              <div className="lead-meetings-view-body">
                <div className="lead-meetings-view-body-lft">
                  <div className="lead-meeting-subdetail-non-flx">
                    <div className="lead-meeting-subdetail lead-txt-color">
                    Meeting ID:
                      <span className="txt-blue">
                      {` ${Format(Meetingdata[0].meetingID)}`}
                      </span>
                      </div>
                      </div>
                      <div className="lead-meeting-subdetail-non-flx">
                      <div className="lead-meeting-subdetail lead-txt-color">
                      Title:
                      <span className="txt-blue">
                        {` ${Format(Meetingdata[0].meeting_name)}`}
                      </span>
                     <div>
                     Sub Title:
                      <span className="txt-blue">
                        {` ${Format(Meetingdata[0].meeting_sub_title)}`}
                      </span>
                     </div>
                      
                    </div>
                    <div className="lead-meeting-subdetail lead-meeting-font">

                      {timeSince(Meetingdata[0].created)}


                    </div>
                  </div>
                  <div className="lead-meeting-subdetail-flx">

                    <div className="lead-meeting-subdetail lead-txt-color">
                      Meeting Created By{' '}
                      <span className="txt-blue">
                        {Format(Meetingdata[0].ownerID)}
                      </span>
                    </div>
                    <div

                      className={
                        "lead-completed-lable"}
                    >

                      {` ${Format(Meetingdata[0].model)}`}
                    </div>

                  </div>
                  <div className="lead-meeting-subdetail lead-txt-color">
                    <span className="lead-txt-lft"> Phone Number :</span>
                    <span className="lead-txt-rgt">
                      {Format(Meetingdata[0].phoneNumber, { maskbool: true })}
                    </span>
                  </div>
                  <div className="lead-meeting-subdetail lead-txt-color">
                    <span className="lead-txt-lft"> Sheduled In </span>
                    <span className="lead-txt-rgt">
                      {Format(Meetingdata[0].phoneNumber, { maskbool: true })} Campaign
                    </span>
                  </div>

                  <div className="lead-meeting-subdetail lead-txt-color">
                    <span className="lead-txt-lft"> Sheduled On:</span>
                    <span className="lead-txt-rgt">
                      {Meetingdata[0].scheduledTime.split(" ")[0]} {moment(Meetingdata[0].scheduledTime.split(" ")[1], ["HH.mm.ss"]).format("hh:mm a")}

                    </span>
                  </div>

                </div>
                <div className="lead-meetings-view-body-rht">
                  <div className="lead-meeting-detail lead-meeting-comment">
                    Description:
                  </div>
                  <div className="lead-meeting-subdetail lead-meeting-comment-body lead-txt-color">
                    {Format(Meetingdata[0].subject)}
                  </div>
                </div>
              </div>
            </div>
          )}
      </>
    </>
  )
}

export default LeadMeetings
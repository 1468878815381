import React, { useState, useEffect } from 'react'
import EventEmitter from '../utils/EventEmitter'
import Format from '../utils/format-text'
import '../assets/css/profile.css'
import { queueLogin, queueLogout, logoutaction } from '../redux/SocketAction/SocketAction'
import {
  setType,
  setOptions,
  setQueue,
  setPause,
} from '../redux/Details/DetailAction'
import { setLead } from '../redux/Lead/LeadAction'
import { connect, useDispatch, useSelector } from 'react-redux'
import { AsterGetCampaignsData } from '../redux/actions/AsterHeader'
import { FiMail } from 'react-icons/fi'
import Form from 'react-bootstrap/Form'
import jwtDecode from 'jwt-decode'
import { logout } from '../redux/actions/login'
import { useNavigate } from 'react-router'
function Profile(props) {
  let navigate = useNavigate()
  let AppPermission
  let queue_ = JSON.parse(localStorage.getItem('selectedQueue'))
  if (localStorage.getItem('config')) {
    AppPermission = JSON.parse(localStorage.getItem('config')).data.projects[0].userPermission
  }
  const callValue = JSON.parse(localStorage.getItem('callstatus'))
  const decoded = jwtDecode(props.token)
  const tid = decoded['http://api.doocti.com/claims/tenant_code']
  const [campaigntoggleAction, setcampaigntoggleAction] = useState(false)
  const [queuetoggleAction, setqueuetoggleAction] = useState(false)
  const [crmtoggleAction, setcrmtoggleAction] = useState(false)
  const phone = props.phone
  const user = props.user
  const queues = props.queues
  const campaigns = props.campaigns
  let dispatch = useDispatch()
  let arr_queue
  arr_queue = JSON.parse(props.selected_queue)
  const selectedCampaign = props.selected_campaign
  const selectedPause = props.selected_pause
  const [queue, setQueue] = useState([])
  const [showsave, setshowsave] = useState(false)
  const [queuecount, setqueuecount] = useState(arr_queue.length)
  const [isChecked, setChecked] = useState(new Array(queues.length).fill(false))
  const [isCheckAll, setCheckAll] = useState(false)


  let tenant
  if (localStorage.getItem('config')) {
    tenant = JSON.parse(localStorage.getItem('config')).data.tenant_code
  }
  const station = localStorage.getItem('extension')
  let session_state = useSelector((state) => state)
  useEffect(() => {
    if (queue_.length === 0) {
      props.closenav(true)
    }
  }, [queue_])
  useEffect(() => {
    if (JSON.stringify(queue) === JSON.stringify(arr_queue)) {
      setshowsave(false)
    } else {
      setshowsave(true)
    }

  }, [queue.length])

  useEffect(() => {
    if (arr_queue.length > 0) {
      arr_queue.map((data, i) => {
        queue.push(data)
      })

      queue.map((data) => {
        let i = queues.indexOf(data)
        setChecked((item) => {
          return item.map((item, j) => {
            return j === i ? !item : item
          })
        })
      })
      if (arr_queue.length == queues.length) {
        setCheckAll(true)
      } else {
        setCheckAll(false)
      }
    }
    event()

  }, [])
  function event() {
    EventEmitter.addListener('user-logout-emitterresponse', (data) => {

      dispatch(logout({ user: props.user }))
        .then(function () {
          const apInfo = JSON.parse(localStorage.getItem("apinfo")) || {};
          const subVersion = apInfo.sub_version;
          if (subVersion) {
            const logoutRedirectUrl = process.env.MAINVERSION;
            window.location.href = logoutRedirectUrl;
          }else{
            const logoutRedirectUrl = process.env.PUBLIC_URL;
          window.location.href = logoutRedirectUrl;
          }


          EventEmitter.emit('user-logout-emitterresponse-2', data)

        })
        .catch((err) => {
          EventEmitter.emit('error-message-getter', {
            action: 'logout',
            module: 'logout',
          })
        })
    })
    EventEmitter.addListener('queue-logout-response', (data) => {
      if (data.logout === true) {
        let logout_action = {
          tenant_id: tenant,
          agent: user,
          station: station,
          campaign: selectedCampaign,
        }
        dispatch(logoutaction(session_state.background.websocketconnection, logout_action))
      }


    })
  }

  function updatequeue() {
    let notPresentInData = queues.filter(val => !queue.includes(val));
    let readystate = localStorage.getItem("selectedPause")
    let paused_ = true
    if (readystate == "available") {
      paused_ = false
    }
    props.set_queue(JSON.stringify(queue))
    const data = {
      action: 'QueueLogin',
      agent: localStorage.getItem('user'),
      campaign: selectedCampaign,
      paused: paused_,
      queue: queue,
      station: station,
      tenant_id: tenant,
    }

    const data2 = {
      action: 'QueueLogout',
      agent: localStorage.getItem('user'),
      campaign: selectedCampaign,
      current_queue: queue,
      queue: notPresentInData,
      station: station,
      tenant_id: tenant,
    }
    dispatch(queueLogin(session_state.background.websocketconnection, data))
    dispatch(queueLogout(session_state.background.websocketconnection, data2))
    props.closenav(true)

  }
  function chooseQueue(i, e) {
    const updatedCheckedState = isChecked.map((item, index) =>
      index === i ? !item : item,
    )

    setChecked(updatedCheckedState)
    if (e.checked == 'true') {
      queue.push(e.value)
    } else {
      let index = queue.indexOf(e.value)

      queue.splice(index, 1)
    }

    if (queues.length == queue.length) {
      setCheckAll(true)
    } else {
      setCheckAll(false)
    }

    setQueue(queue)


  }


  // const changeCampaign = (item) => {
  //   props.set_type(item)

  //   dispatch(AsterGetCampaignsData(item))
  //     .then((res) => {
  //       const options = {}
  //       let queue = res.data.data.ingroup.split(',')
  //       let pausecode = res.data.data.pausecode.split(',')
  //       let dispo = res.data.data.dispo_statuses.split(',')
  //       options.pauseCode = pausecode
  //       options.queues = queue
  //       options.dispo = dispo
  //       props.set_options(options)
  //       queue.length = 0
  //       props.set_queue(JSON.stringify(queue))
  //       const data = {
  //         action: 'QueueLogin',
  //         agent: user,
  //         campaign: selectedCampaign,
  //         paused: true,
  //         queue: queue,
  //         station: station,
  //         tenant_id: tenant,
  //       }

  //       const data2 = {
  //         action: 'QueueLogout',
  //         agent: user,
  //         campaign: selectedCampaign,
  //         current_queue: queue,
  //         queue: [],
  //         station: station,
  //         tenant_id: tenant,
  //       }
  //       dispatch(queueLogin(session_state.background.websocketconnection, data))
  //       dispatch(
  //         queueLogout(session_state.background.websocketconnection, data2),
  //       )

  //     })
  //     .catch((err) => {
  //       EventEmitter.emit('error-message-getter', {
  //         action: 'fetch',
  //         module: 'campaign data',
  //       })
  //     })
  //   setcampaigntoggleAction(!campaigntoggleAction)
  // }
  function agentLogout() {

    const data2 = {
      action: 'QueueLogout',
      agent: localStorage.getItem('user'),
      campaign: selectedCampaign,
      current_queue: [],
      queue: queue,
      station: station,
      tenant_id: tenant,
      logout: true
    }
    dispatch(
      queueLogout(session_state.background.websocketconnection, data2),
    )
  }

  return (
    <div className="profile">
      <div className="profile-head">
        <div className="profile-up">

          <div className="profile-content-div">
            <div className="profile-content-subdiv">
              <div className="profile-content-subdiv-lft profile-icon">
                {' '}
                <FiMail />
              </div>
              <div className="profile-content-subdiv-rht">{user}</div>
            </div>
            <div className="profile-content-subdiv">
              <div className="material-icons profile-content-subdiv-lft ">
                campaign
              </div>
              <div className="profile-content-subdiv-rht ">
                {Format(selectedCampaign)}
              </div>
            </div>
            <div className="profile-content-subdiv-queue-pause">
              <div className="profile-content-subdiv profile-queue-pause">
                <div className="material-icons profile-content-subdiv-lft profile-icon">
                  pause
                </div>
                <div className="profile-content-subdiv-rht profile-queue-pause-txt">
                  {Format(selectedPause)}
                </div>
              </div>
            </div>
            <div className="profile-content-subdiv-queue-pause">
              <div className="profile-content-subdiv profile-queue-pause">
                <div className="material-icons profile-content-subdiv-lft profile-icon">
                  phone
                </div>
                <div className="profile-content-subdiv-rht profile-queue-pause-txt">
                  {Format(station)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="profile-down"></div>
      </div>

      <div className="profile-body">
        {/* feature implement */}
        {/*  <div className="profile-body-div">
          <div>
            <div>
              <h5 className="mb-0">
                <button
                  className="btn profile-button"
                  onClick={() => {
                    if (AppPermission.crm_assign) {
                      setcrmtoggleAction(!crmtoggleAction)
                    }
                  }}
                >
                  <div className="profile-button-lft profile-crm-image">
                    <img
                      className="profile-crm-image-main"
                      src={slideImg1}
                      alt="Text"
                    />
                  </div>
                  <div className="profile-button-rht">Select CRM</div>
                </button>
              </h5>
            </div>
            {crmtoggleAction && (
              <div>
                <div className="profile-card-body ">
                  <div className="card-body profile-button-body profile-crm">
                    <div className="profile-crm-image-txt">{'Inbuilt'}</div>
                  </div>
                </div>
                <div className="profile-card-body ">
                  <div className="card-body profile-button-body profile-crm">
                    <div>{'Others'}</div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="profile-body-div">
          <div>
            <div>
              <h5 className="mb-0">
                <button
                  className="btn profile-button"
                  onClick={() => { if (AppPermission.campaign_assign) setcampaigntoggleAction(!campaigntoggleAction) }}
                >
                  <div className="material-icons profile-button-lft">
                    campaign
                  </div>
                  <div className="profile-button-rht">Campaign</div>
                </button>
              </h5>
            </div>
            {campaigntoggleAction && (
              <div>
                <div className="profile-card-body">
                  {campaigns.map((item) => {
                    return (
                      <div
                        className="card-body profile-button-body"
                        key={item}
                        onClick={() => changeCampaign(item)}
                      >
                        {Format(item)}
                      </div>
                    )
                  })}
                </div>
              </div>
            )}
          </div>
        </div> */}
        {AppPermission.queue &&
          <div className="profile-body-div">
            <div>
              <div>
                <h5 className="mb-0">
                  <button
                   className={session_state.background.oncall?"btn profile-button disabledbutton":"btn profile-button"}

                  >
                    <div className="material-icons profile-button-lft">queue</div>
                    <div className="profile-button-rht " onClick={() => {
                      // if (AppPermission.queue_assign) {
                      setqueuetoggleAction(!queuetoggleAction)
                      // }
                    }}>Queue</div>
                    <div
                      onClick={updatequeue}
                      className={showsave ? "material-icons profile-button-lft_" : "material-icons profile-button-lft_ disabledbutton"}
                    >
                      save
                    </div>
                  </button>
                </h5>
              </div>
              {queuetoggleAction && (
                <div>
                  <br></br>

                  {queues.map((item, index) => {
                    return (
                      <div className="card-body profile-button-body" key={item}>
                        <Form.Check
                          // disabled={arr_queue.length >= 1 ? false : true}
                          value={item}
                          type="checkbox"
                          onChange={(e) =>
                            chooseQueue(index, {
                              checked: e.target.checked.toString(),
                              value: item,
                            })
                          }
                          label={Format(item)}
                          checked={isChecked[index]}
                        />
                      </div>
                    )
                  })}
                </div>
              )}
            </div>
          </div>
        }
        {/* <div className="profile-body-div"> */}
        <div className="profile-queue-subdiv-rht profile-tag-queue">
          {queues.length > 0 &&
            queues.map((data, index) => {
              return (
                <>  {isChecked[index] === true && <div className="profile-tag-queue-div" key={index}>
                  {isChecked[index] && (

                    <div className="profile-queue"> {Format(data)}</div>


                  )}
                </div>}
                </>
              )
            })}
        </div>
        {/* </div> */}
      </div>
      <div className="profile-tail">
        <button className="btn">
          <div className={callValue === null ? "profile-button-tail" : "profile-button-tail disabledbutton"} onClick={agentLogout}>
            Log Out
          </div>
        </button>

      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    campaigns: state.detail.campaign,
    user: state.detail.user,
    token: state.login.token,
    selected_campaign: state.detail.selected_campaign,
    phone: state.detail.phone_number,
    pausecode: state.detail.pause_codes,
    queues: state.detail.queues,
    selected_queue: state.detail.selected_queue,
    selected_pause: state.detail.selected_pause,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    set_type: (type) => dispatch(setType(type)),
    set_options: (type) => dispatch(setOptions(type)),
    set_lead: (type) => dispatch(setLead(type)),
    set_queue: (type) => dispatch(setQueue(type)),
    set_pause: (type) => dispatch(setPause(type)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile)

import * as actions from './LeadTypes'

const initialState = {
    data: [],
    audio: "",
    playstate: false,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.SET_LEADS:
            return {
                ...state,
                data: action.payload
            }
        case actions.SET_AUDIO:
            return {
                ...state,
                audio: action.payload
            }
        case actions.SETPLAY_SOUND:
            if (state.audio != "") {
                if (action.payload) {
                    state.audio.play()
                } else {
                    state.audio.pause()
                    state.audio.currentTime = 0
                }
            }

            return {
                ...state,
                playstate: action.payload
            }
        default:
            return state;
    }
}
export default reducer;
import api from "../../utils/axios-util";


export const agentdailywork = (payload) => {

    let campaigninfo = JSON.parse(localStorage.getItem('selectedCampaign_data'))
    return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
            await api
                .get(`/agent/activity/count?user=${payload}&template_name=${campaigninfo.template_name}`)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

};
export const leadscore = (payload) => {
    return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
            await api
                .get(`/doocity/call_activity-count?id=${payload.id}&module=${payload.module}`)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

};
export const getcalllog = (payload, type) => {
    return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
            await api
                .get(`/dashboard/call_log/fetch?user=${payload}&type=${type}`)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

};

export const inboundOutbound = (payload, type) => {
    return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
            await api
                .get(`/inbound/outbound/report?user=${payload}`)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

};


export const agentworkhour = (payload, type) => {
    return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
            await api
                .get(`/agentwork/time?user=${payload}`)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

};

export const callreports = (payload, type) => {
    return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
            await api
                .get(`/call/report?user=${payload}`)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

};
export const wa_call_reports = (query) => {

    let query1 = ""
    if (query.dispo_status) {
        query1 = ` &dispo_status=${query.dispo_status}`
    }

    return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
            await api
                .get(`/agentworkreport?user=${query.agent}&date=${query.type}&wa_permit=${query.wa_permit}&chartView=${query.chartView}` + query1)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
}
export const getdispocount = (query) => {
    return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
            await api
                .get(`/agent/dispo/count?user=${query.user}&template_name=${query.template_name}&module=${query.module}`)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
}

export const postdispo = (payload) => {
    return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
            await api
                .post(`/add/dispo`, payload)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
}

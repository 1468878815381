import { CHATDISPOVALIDATION,CALL_MODULE,ON_CALL, INCOMING, ON_DIAL, ON_LOG ,BEFORECALLNAVPATH,AUTODIALSTATUS,AUTODIALDATAS , POP_AUTODIALDATA, DISPOVALIDATION , AUTODIALPLAYPARSE} from './CallTypes'
import store from '../store'
export const on_call = () => {
    return {
        type: ON_CALL,
        payload: 'on_call'
    }
}
export const incoming = () => {
    return {
        type: INCOMING,
        payload: 'incoming'
    }
}
export const on_dial = () => {
    return {
        type: ON_DIAL,
        payload: 'on_dial'

    }
}
export const on_log = () => {
    return {
        type: ON_LOG,
        payload: 'on_log'

    }
}

export const fetchcallaction = (type) => {
    return (dispatch) => {

        if (type === 'log') {
            dispatch(on_log());
        } else if (type === 'incoming') {
            dispatch(incoming());
        } else if (type === 'on_dial') {
            dispatch(on_dial())
        } else if (type === 'on_call') {
            dispatch(on_call())
        }

    }
}

export const beforecallnavpath = (data) => {
    return {
        type: BEFORECALLNAVPATH,
        payload: data

    }
}

export const autodial =(data)=>{
    return {
        type: AUTODIALSTATUS,
        payload: data
    }
}

export const autodialdatas=(data)=>{
    return {
        type: AUTODIALDATAS,
        payload:data
    }
}

export const AutoDialPlayParseBtn=()=>{
    return {
        type: AUTODIALPLAYPARSE
    }
}


export const dispoValidation=(data)=>{
    return {
        type: DISPOVALIDATION,
        payload: data
    }
}
export const chatdispoValidation=(data)=>{
    return {
        type: CHATDISPOVALIDATION,
        payload: data
    }
}


export const autocalldial=()=>{
    return (dispatch) => {
    let state = store.getState()    
  if(state.call.autodialstatus && state.call.autodialdatas.length >0){
    let dialnumber= state.call.autodialdatas[0].leadNumber
    const user = localStorage.getItem("username")
    const station = localStorage.getItem("extension")
    let tenant
    let AppPermission 
    if (localStorage.getItem('config')) {
      tenant = JSON.parse(localStorage.getItem('config')).data.tenant_code
      AppPermission = JSON.parse(localStorage.getItem('config')).data.projects[0].userPermission
    }
    let calldata = {
        action: "Dial",
        agent: state.detail.user,
        agent_name: user,
        auto_dial_caller: false,
        call_type: "AUTO",
        campaign: state.detail.selected_campaign,
        lead_id: undefined,
        list_id: "",
        meeting: false,
        phone_number:dialnumber.toString(),
        station: station,
        substitute: "",
        tenant_id: tenant,
        ticket_id: undefined,
    }
    if(AppPermission.auto_dial_caller && AppPermission.auto_dial){
        calldata.auto_dial_caller=true
    }
    state.background.websocketconnection.emit("initiate-call", calldata);
    return {
        type: "called",
        payload:calldata
    }
  }
}

}

export const autodialnumberpopstate=()=>{
    return (dispatch,getState)=>{
        return new Promise(async (resolve, reject)=>{
            let state = store.getState()
            let poped_data= [...state.call.autodialdatas];
            poped_data.shift()
            dispatch({
                type: POP_AUTODIALDATA ,
                payload:poped_data
                }) 
            resolve(poped_data)
        })
        
    }
    

}

export const callmodule=(data)=>{
    return {
        type: CALL_MODULE,
        payload: data
    }
}
import React, { useEffect, useState, useRef, useMemo } from 'react'
import { Form } from 'react-bootstrap'
import InfiniteScroll from 'react-infinite-scroll-component'
import EventEmitter from '../utils/EventEmitter'
import wp from '../assets/images/wp.png'
import webchat_ from '../assets/images/webchat.svg'
import '../assets/css/chat.css'
import { useDispatch, useSelector } from 'react-redux'
import { ChatHistory, WhatsappContact, upload } from '../redux/actions/ChatActions'
import { chatactive } from '../redux/Chat-notification/Chat-notification-Action'
import moment from 'moment'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import { publishmessage } from '../redux/SocketAction/SocketAction'
import {
 socketmessage, oncall
} from '../redux/background/BackgroundAction'

import fromat from '../utils/format-text'
import { getDefaultLocale } from 'react-datepicker'
import Tooltip from 'react-bootstrap/Tooltip'
import { useNavigate } from 'react-router'
import { Encode } from '../utils/EncodeDecode'
import fbchat from '../assets/images/fb.svg'
import { CustomformData } from '../redux/actions/Customform'
  
function Wpchat(props) {
 let tenant
 const fileInputRef = useRef(null);
 if (localStorage.getItem('config')) {
 tenant = JSON.parse(localStorage.getItem('config')).data.tenant_code
 }
 const user = localStorage.getItem('username')
 const station = localStorage.getItem('extension')
 let getApplicationstatus = JSON.parse(localStorage.getItem('applicationState'))
 let wasession = localStorage.getItem("wasession")
 let agent = localStorage.getItem('user')
 let AppPermission
 if (localStorage.getItem('config')) {
 AppPermission = JSON.parse(localStorage.getItem('config')).data.projects[0].userPermission
 }
 const Dispatch = useDispatch()
 let state_value = useSelector((state) => state)
 const [redirect, setredirect] = useState(false)
 const [history, sethistory] = useState([])
 const [contacts, setcontacts] = useState([])
 // const [type,settype]=useState(0)
 const [SendMessage, setSendMessage] = useState('')
 const [cus_value, setcus_value] = useState('')
 const messagesEndRef = useRef(null)
 const [firstname, setfirstName] = useState('')
 const [channel_type, setchannel_type] = useState('')
 const [contactSearch, setcontactSearch] = useState('')
 const [infinitScroll, setinfinitScroll] = useState({ limit: 7, more: true })
 const [historyinfinitScroll, sethistoryinfinitScroll] = useState({
 limit: 7,
 more: true,
 })
 const [count, setcount] = useState(0)
 const [skipdata, setskipdata] = useState({ agent: agent, skip: 0, take: 10 })
 const [skiphistorydata, setskiphistorydata] = useState({
 phone: '',
 skip: 0,
 take: 10,
 })
 const [phonenumber, setphonenumber] = useState('')
 const [restrictphonenumber, setrestrictphonenumber] = useState('')
 const [historycount, sethistorycount] = useState(0)
 const [chatinfo, setchatinfo] = useState({});
 const navigate = useNavigate()

 // useEffect(()=>{
 // if(wasession==="true"){
 // Dispatch(oncall(false))
 // }
 // },[wasession])


 useEffect(() => {
 Dispatch(chatactive(true))
 localStorage.setItem('chatactive', 'true')

 Apidata(skipdata, 'mount', props.instantClick.channel)
 if (props.instantClick !== '') {
 if (props.instantClick.action === 'inspect') {
 setredirect(false)
 setchannel_type(props.instantClick.channel)
 props.reset()
 } else {
 if (
 props.instantClick.sender_name === null ||
 props.instantClick.sender_name == undefined ||
 props.instantClick.sender_name == ''
 ) {
 setfirstName(props.instantClick.phone_number)
 } else {
 setfirstName(props.instantClick.sender_name)
 }
 setchannel_type(props.instantClick.channel)
 setredirect(true)
 setcus_value(props.instantClick.custom_value)
 chatredirection(props.instantClick.phone_number, props.instantClick.custom_value)

 let data = {
 agent: agent,
 skip: 0,
 take: 10,
 }
 Apidata(data, 'reset', props.instantClick.channel)
 props.reset()
 }
 }
 event()
 }, [])

 useEffect(() => {
 if (props.userMessage !== '') {
 let data = {
 agent: agent,
 skip: 0,
 take: 10,
 }
 let count = 0
 if (redirect === false) {
 Apidata(data, 'reset', channel_type)
 }


 messageReaponse(
 props.userMessage.MSG[0].phone_number,
 props.userMessage.MSG[0]
 )

 }
 }, [props.userMessage])

 useEffect(() => {
 if (props.hangupdata === 1) {
 ClickMe()
 }
 }, [props.hangupdata])

 function event() {
 EventEmitter.addListener('agent-message-response', (data) => {
 console.log(data, "jkjkjkjkjkjkjkjkj")
 if (data != "" && data != undefined) {
 messageReaponse(data.phone_number, data)
 }


 })
 }

 function messageReaponse(data, alldata) {
 if (restrictphonenumber === '') {
 setrestrictphonenumber(data)
 }
 if (restrictphonenumber === data || restrictphonenumber === '') {
 if (data !== undefined) {
 let obj = {}
 obj['phone'] = data
 setSendMessage('')
 obj['skip'] = 0
 obj['take'] = 10
 setskiphistorydata((set) => {
 set.skip = 0
 set.take = 10
 return {
 ...set,
 }
 })
 obj.channel = channel_type
 obj.custom_value = alldata.custom_value
 Dispatch(ChatHistory(obj))
 .then((response) => {
 if (response.data.statusCode === 200) {
 sethistory([].concat(response.data.data))
 } else {
 sethistory([])
 }
 })
 .catch((err) => {
 EventEmitter.emit('error-message-getter', {
 action: 'fetch',
 module: 'whatsapp conversation',
 })
 })
 }
 } else {
 EventEmitter.emit('another-chat', alldata)
 }
 }

 function searchTerm(value) {
 setcontactSearch(value)
 let payload = {}
 payload['search'] = value
 payload['skip'] = 0
 payload['take'] = 10
 payload['agent'] = agent

 if (value === '') {
 let data = {
 agent: agent,
 skip: 0,
 take: 10,
 }
 setinfinitScroll((set) => {
 set.more = true
 return {
 ...set
 }
 })
 Apidata(data, 'reset', channel_type)
 } else {
 SearchApidata(payload)
 setskipdata((set) => {
 set.search = value
 return {
 ...set,
 }
 })
 }
 }

 function SearchApidata(payload) {
 payload.channel = channel_type
 Dispatch(WhatsappContact(payload)).then((response) => {
 if (response.data.statusCode === 200) {
 setskipdata((set) => {
 set.skip = 10
 set.take = 10
 return {
 ...set,
 }
 })
 setcount(response.data.count)
 setcontacts(response.data.data)
 } else {
 setcontacts([])
 }
 setinfinitScroll((set) => {
 set.more = true
 return {
 ...set,
 }
 })
 })
 }

 function Apidata(payload, trigger, channel) {
 if (payload.search && contactSearch === '') {
 delete payload.search
 }
 payload.channel = channel
 Dispatch(WhatsappContact(payload))
 .then((response) => {
 if (response.data.statusCode === 200) {
 setskipdata((set) => {
 set.skip = payload.skip + 10
 set.take = payload.take
 return {
 ...set,
 }
 })
 setinfinitScroll((set) => {
 set.limit = set.limit + set.limit

 return {
 ...set,
 }
 })
 if (trigger === 'reset') {
 setcontacts([].concat(response.data.data))
 } else {
 setcontacts(contacts.concat(response.data.data))
 }

 setcount(response.data.count)
 } else {
 setcontacts([])
 }
 })
 .catch((err) => {
 EventEmitter.emit('error-message-getter', {
 action: 'fetch',
 module: 'whatsapp contact',
 })
 })
 }

 const fetchMoreData = () => {
 if (contacts.length >= count) {
 setinfinitScroll((set) => {
 set.more = false
 return {
 ...set,
 }
 })
 } else {
 setTimeout((v) => {
 Apidata(skipdata, 'mount', channel_type)
 }, 500)
 }
 }

 const redirectChat = (val, data) => {
 setrestrictphonenumber(data.phone_number)
 setcontactSearch('')
 // settype(data.sender_type)
 setredirect(val)
 setchatinfo(data)
 setcus_value(data.custom_value)
 chatredirection(data.phone_number, data.custom_value)
 if (data.sender_name === undefined || data.sender_name === null || data.sender_name === '') {
 setfirstName(data.phone_number)
 } else {
 setfirstName(data.sender_name)
 }
 setcontacts([])
 }
 const chatredirection = (phone, data) => {
 if (phone !== undefined) {
 setphonenumber(phone)
 setskiphistorydata((set) => {
 set.phone = phone
 return {
 ...set,
 }
 })
 skiphistorydata.phone = phone
 skiphistorydata.channel = channel_type
 skiphistorydata.custom_value = data
 Dispatch(ChatHistory(skiphistorydata)).then((response) => {
 if (response.data.statusCode === 200) {
 setskiphistorydata((set) => {
 set.skip = set.skip + 10
 set.take = set.take
 return {
 ...set,
 }
 })
 sethistoryinfinitScroll((set) => {
 set.limit = set.limit + set.limit

 return {
 ...set,
 }
 })
 sethistory(history.concat(response.data.data))
 sethistorycount(response.data.count)
 } else {
 sethistory([])
 }
 })
 }
 }

 const fetchMoreHistoryData = () => {
 if (history.length >= historycount) {
 sethistoryinfinitScroll((set) => {
 set.more = false
 return {
 ...set,
 }
 })
 } else {
 setTimeout((v) => {
 chatredirection(phonenumber, cus_value)
 }, 500)
 }
 }

 const scrollToBottom = () => {
 messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
 }

 const ClickMe = () => {
 props.ClinkMe()
 Dispatch(chatactive(false))

 localStorage.setItem('chatactive', 'false')
 setrestrictphonenumber('')
 }

 function dateformat(value) {
 let date = new Date(value)
 var seconds = Math.floor((new Date() - date) / 1000)

 var interval = seconds / 31536000

 if (interval > 1) {
 return Math.floor(interval) + ' year ago'
 }
 interval = seconds / 2592000
 if (interval > 1) {
 return Math.floor(interval) + ' month ago '
 }
 interval = seconds / 86400
 if (interval > 1) {
 return Math.floor(interval) + ' day ago'
 }
 interval = seconds / 3600
 if (interval > 1) {
 return Math.floor(interval) + ' hour ago'
 }
 interval = seconds / 60
 if (interval > 1) {
 return Math.floor(interval) + ' minute ago'
 }
 return Math.floor(seconds) + ' second ago'
 }

 function sendmessage() {
 let session_id
 for (let i = 0; i < props.whatsappsession.length; i++) {
 if (props.whatsappsession[i].phone_number === phonenumber) {
 session_id = props.whatsappsession[i].session_id
 }
 }


 let data = {
 tenant_id: tenant,
 station: station,
 agent: agent,
 session_id: session_id,
 text: SendMessage,
 type: 'text',
 channel: channel_type,
 phone_number: phonenumber,
 }

 // if (channel_type === 'website') {
 data.custom_value = props.whatsappsession[0].custom_value

 // }

 Dispatch(publishmessage(state_value.background.websocketconnection, data))
 }

 function backtocontact() {
 sethistory([])
 setredirect(false)
 setskiphistorydata((set) => {
 set.phone = ''
 set.skip = 0
 set.take = 10
 return {
 ...set,
 }
 })
 let data = {
 agent: skipdata.agent,
 skip: 0,
 take: 10,
 }
 Apidata(data, 'reset', channel_type)
 }
 function gotolead() {
 console.log(props.whatsappsession[0], "chatinfochatinfochatinfochatinfo")
 Dispatch(socketmessage({ "phonenumber": phonenumber.split(91)[1], evt: "whatsapp" }))
 // 
 let campaigninfo = JSON.parse(localStorage.getItem("selectedCampaign_data"))
 let payload = {
 template_name: campaigninfo.template_name,
 type: campaigninfo.type,
 phone_number: phonenumber.split(91)[1],
 id: ''
 }

 Dispatch(CustomformData(payload)).then((res) => {
 if (res.status === 204) {
 navigate(`/leads/create`)
 } else {
 navigate(`/leads/${Encode(phonenumber.split(91)[1])}/${res.data.data[0].others.lead_id}`)
 }
 })
 }
 function gototicket() {
 navigate(`/tickets/create`)
 }
 function timeformate(Data) {
 const today = new Date(Data);
 const yyyy = today.getFullYear();
 let mm = today.getMonth() + 1;
 let dd = today.getDate();
 let hh = today.getHours();
 let MM = today.getMinutes();
 let ss = today.getSeconds();
 if (dd < 10) dd = "0" + dd;
 if (mm < 10) mm = "0" + mm;
 if (hh < 10) hh = "0" + hh;
 if (MM < 10) MM = "0" + MM;
 if (ss < 10) ss = "0" + ss;

 const formattedToday =
 hh + ":" + MM + ":" + ss;
 return formattedToday;
 }
 function handleFileUpload(event) {

 const file = event.target.files[0];
 const formData = new FormData();
 formData.append('file', file);
 Dispatch(upload(formData))
 .then((response) => {
 if (response.data.statusCode === 200) {
 fileInputRef.current.value = null;
 let session_id
 for (let i = 0; i < props.whatsappsession.length; i++) {
 if (props.whatsappsession[i].phone_number === phonenumber) {
 session_id = props.whatsappsession[i].session_id
 }
 }
 let data = {
 tenant_id: tenant,
 station: station,
 agent: agent,
 session_id: session_id,
 type: response.data.data.type,
 text: response.data.data.url,
 channel: channel_type,
 phone_number: phonenumber,
 }
 data.custom_value = props.whatsappsession[0].custom_value

 Dispatch(publishmessage(state_value.background.websocketconnection, data))
 }
 })
 .catch((err) => {
 fileInputRef.current.value = null;
 // EventEmitter.emit('error-message-getter', {
 // action: 'u',
 // module: 'whatsapp contact',
 // })
 console.log(err)
 })

 }
 function dataformate(Data) {
 const today = new Date(Data);
 const yyyy = today.getFullYear();
 let mm = today.getMonth() + 1;
 let dd = today.getDate();
 let hh = today.getHours();
 let MM = today.getMinutes();
 let ss = today.getSeconds();
 if (dd < 10) dd = "0" + dd;
 if (mm < 10) mm = "0" + mm;
 if (hh < 10) hh = "0" + hh;
 if (MM < 10) MM = "0" + MM;
 if (ss < 10) ss = "0" + ss;

 const formattedToday =
 dd + "-" + mm + "-" + yyyy;
 return formattedToday;
 }
 return (
 <div className="chat-main-box">


 {channel_type === 'whatsapp' && <div className="chat-main-header chat-whatsapp">
 <div>
 <img src={wp} alt="test" className="chat-main-header-icon" />
 <span className="chat-main-header-txt">Whatsapp</span>
 </div>
 <div>
 <span className="material-icons chat-close" onClick={() => ClickMe()}>
 close
 </span>
 </div>
 </div>}

 {channel_type === 'website' && <div className="chat-main-header chat-whatsapp webchat">
 <div>
 <img className='web_chatimg web_chatimg_window' src={webchat_} alt="test" />
 <span className="chat-main-header-txt">WebChat</span>
 </div>
 <div>
 <span className="material-icons chat-close" onClick={() => ClickMe()}>
 close
 </span>
 </div>
 </div>}
 {channel_type === 'facebook' && <div className="chat-main-header chat-whatsapp webchat">
 <div>
 <img className='web_chatimg web_chatimg_window' src={fbchat} alt="test" />
 <span className="chat-main-header-txt">Facebook</span>
 </div>
 <div>
 <span className="material-icons chat-close" onClick={() => ClickMe()}>
 close
 </span>
 </div>
 </div>}

 {redirect === false && (
 <div className="chat-name-body">

 {AppPermission.wa_search &&
 <div style={{ position: 'relative', padding: 5 }}>
 <span
 className="material-icons"
 style={{ position: 'absolute', top: 12, left: 10, color: '#9da2a6' }}
 >
 search
 </span>{' '}
 <Form.Control
 onChange={(event) => {
 searchTerm(event.target.value)
 }}
 type="text"
 placeholder="Search"
 style={{
 paddingLeft: 40,
 height: '35px',
 marginTop: '1px',
 background: '#fff',
 }}
 />
 </div>}
 <div className="chat-name-body-main-content">
 <div
 id="chatDiv"
 style={{
 height: 300,
 overflow: 'auto',
 display: 'flex',
 flexDirection: 'column',
 }}
 >
 {' '}
 <div className="chat-name-body-content">

 <InfiniteScroll
 scrollableTarget="chatDiv"
 dataLength={contacts.length} //This is important field to render the next data
 next={fetchMoreData}
 hasMore={infinitScroll.more}
 >
 {contacts.length > 0 && (
 <>
 {contacts.map((value, i) => {
 return (
 <div
 key={i}
 onClick={() => {
 redirectChat(true, value)
 }}
 className="chat-name-content"
 >
 <div className="chat-icon-div">
 <div className="material-icons chat-icon">
 person
 </div>
 <span className={value.active === 1 ? "online waonline" : "online waonline waoffline"}></span>
 </div>

 <div className="chat-name">
 <div className="chat-name-div">
 <div className="chat-name-div-lft">
 <div className="chat-subname-title">
 {value.sender_name
 ? fromat(value.sender_name) : value.phone_number === "nonumber" ? fromat(value.custom_value.split('_')[0].slice(-10)) :
 fromat(value.phone_number, { maskbool: true })}
 </div>
 <div className="chat-subname">
 <div className="chat-subname-message">
 {' '}
 {value.sender_name
 ? fromat(value.phone_number, { maskbool: true })
 : fromat('Unknown Number')}
 </div>

 </div>

 </div>
 <div className="chat-subname-time">
 {dateformat(value[`modified_at`])}
 </div>
 </div>
 </div>
 </div>
 )
 })}
 </>
 )}
 {contacts.length === 0 && (
 <>
 <div className="chat-bodata-whChat">No Contact</div>
 </>
 )}
 </InfiniteScroll>
 </div>
 </div>
 </div>
 </div>
 )}
 {redirect && (
 <div className="chat-area">
 <div className="chat-main-div">
 <div className="chat-main-body-title ">
 <div className="material-icons chat-body-icon">people_alt</div>
 <div className="chat-main-body-title-name">
 {isNaN(Number(firstname)) !== false?fromat(firstname):fromat(firstname, { maskbool: true })}
 </div>

 {AppPermission.wa_lead &&
 <OverlayTrigger
 placement="bottom"
 delay={{ show: 250, hide: 400 }}
 overlay={
 <Tooltip id="button-tooltip" {...props}>
 Lead
 </Tooltip>
 }
 >
 <span
 onClick={() => {
 gotolead()
 }}
 className="material-icons chat-body-icon-arrow"
 >
 contact_page
 </span>
 </OverlayTrigger>
 }


 {AppPermission.wa_ticket_create &&
 <OverlayTrigger
 placement="bottom"
 delay={{ show: 250, hide: 400 }}
 overlay={
 <Tooltip id="button-tooltip" {...props}>
 Ticket
 </Tooltip>
 }
 >
 <span
 onClick={() => {
 gototicket()
 }}
 className="material-icons chat-body-icon-arrow"
 >
 confirmation_number
 </span>
 </OverlayTrigger>
 }
 <span
 onClick={backtocontact}
 className="material-icons chat-body-icon-arrow"
 >
 arrow_back_ios
 </span>
 </div>
 <div
 id="historyscrollableDiv"
 style={{
 height: 300,
 overflow: 'auto',
 display: 'flex',
 flexDirection: 'column-reverse',
 }}
 >
 <InfiniteScroll
 scrollableTarget="historyscrollableDiv"
 inverse={true}
 style={{ display: 'flex', flexDirection: 'column-reverse' }}
 dataLength={history.length} //This is important field to render the next data
 next={fetchMoreHistoryData}
 hasMore={historyinfinitScroll.more}
 >
 {history.length > 0 && (
 <>
 {history.map((value, i) => {
 return (
 <div key={i}>
 {value.agent !== agent &&
 value.msg_direction === 'out' && (
 <div className="d-flex align-items-center justify-content-start">

 <>
 {value.attachment === 'text' && <span className="chat-request">
 {value.msg_text}{' '}
 <div className='timestamp'>{value.date_time.split('T')[0]}{" "} {moment(value.date_time.split('T')[1], ["HH.mm.ss"]).format("hh:mm a")}
 </div>
 </span>}
 {value.attachment === 'document' && <span className="chat-request">
 <a className='removedecoration' href={value.msg_text} download>Download Document</a>
 <div className='timestamp'>{value.date_time.split('T')[0]}{" "} {moment(value.date_time.split('T')[1], ["HH.mm.ss"]).format("hh:mm a")}
 </div>
 </span>}
 {value.attachment === 'template' && <span className="chat-request">
 {'Template Name: '} {value.msg_text}
 <div className='timestamp'>{value.date_time.split('T')[0]}{" "} {moment(value.date_time.split('T')[1], ["HH.mm.ss"]).format("hh:mm a")}
 </div>
 </span>}
 {value.attachment === 'image' && <span className="chat-request image-span">
 <img src={value.msg_text} alt="Image"></img>
 <div className='timestamp'>{value.date_time.split('T')[0]}{" "} {moment(value.date_time.split('T')[1], ["HH.mm.ss"]).format("hh:mm a")}
 </div>
 </span>}
 {value.attachment === 'video' && <span className="chat-request video-span">
 <video src={value.msg_text} controls>
 Your browser does not support the video tag.
 </video>
 <div className='timestamp'>{value.date_time.split('T')[0]}{" "} {moment(value.date_time.split('T')[1], ["HH.mm.ss"]).format("hh:mm a")}
 </div>
 </span>}
 {value.attachment === 'audio' && <span className="chat-request">
 <audio controls src={value.msg_text}>
 Your browser does not support the audio tag.
 </audio>
 <div className='timestamp'>{value.date_time.split('T')[0]}{" "} {moment(value.date_time.split('T')[1], ["HH.mm.ss"]).format("hh:mm a")}
 </div>
 </span>}


 </>

 
 </div>
 )}

 {value.msg_direction === 'in' && (
 <div className="d-flex align-items-center justify-content-start">


 <>
 {value.attachment === 'text' && <span className={channel_type === "whatsapp" ? "chat-request chat-user" : 'chat-request webchat-user'}>
 {value.msg_text}{' '}
 <div className='timestamp'>{dataformate(value.date_time)}{" "}{timeformate(value.date_time)}
 </div>
 
 </span>}
 {value.attachment === 'template' && <span className={channel_type === "whatsapp" ? "chat-request chat-user" : 'chat-request webchat-user'}>
 {'Template Name: '} {value.msg_text}
 <div className='timestamp'>{dataformate(value.date_time)}{" "}{timeformate(value.date_time)}
 </div>
 </span>}
 {value.attachment === 'document' && <span className={channel_type === "whatsapp" ? "chat-request chat-user" : 'chat-request webchat-user'}>
 <a className='removedecoration' href={value.msg_text} download>Download Document</a>
 <div className='timestamp'>{dataformate(value.date_time)}{" "}{timeformate(value.date_time)}
 </div>
 </span>}
 {value.attachment === 'image' && <span className={channel_type === "whatsapp" ? "chat-request chat-user image-span" : 'chat-request webchat-user image-span'}>
 <img src={value.msg_text} alt="Image"></img>
 <div className='timestamp'>{dataformate(value.date_time)}{" "}{timeformate(value.date_time)}
 </div>
 </span>}
 {value.attachment === 'video' && <span className={channel_type === "whatsapp" ? "chat-request chat-user video-span" : 'chat-request webchat-user video-span'}>
 <div className='timestamp'>{dataformate(value.date_time)}{" "}{timeformate(value.date_time)}
 </div>
 <video src={value.msg_text} controls>
 Your browser does not support the video tag.
 </video> 
 </span>}
 {value.attachment === 'audio' && <span className={channel_type === "whatsapp" ? "chat-request chat-user" : 'chat-request webchat-user'}>
 <audio controls src={value.msg_text}>
 Your browser does not support the audio tag.
 </audio>
 <div className='timestamp'>{dataformate(value.date_time)}{" "}{timeformate(value.date_time)}
 </div>
 </span>}


 </>

 
 </div>
 )}

 {value.agent === agent &&
 value.msg_direction === 'out' && (
 <div className="d-flex align-items-center justify-content-start flex-row-reverse">
 <>
 {value.attachment === 'text' && <span
 className="chat-response"
 style={{ paddingRight: 10 }}
 >
 {value.msg_text}{' '}
 </span>}
 {value.attachment === 'template' && <span
 className="chat-response"
 style={{ paddingRight: 10 }}
 >
 {'Template Name: '} {value.msg_text}
 </span>}

 {value.attachment === 'document' && <span
 className="chat-response"
 style={{ paddingRight: 10 }}
 >
 <a className='removedecoration' href={value.msg_text} download>Download Document</a>
 </span>}
 {value.attachment === 'image' && <span
 className="chat-response image-span"
 style={{ paddingRight: 10 }}
 >
 <img src={value.msg_text} alt="Image"></img>
 </span>}
 {value.attachment === 'video' && <span
 className="chat-response video-span"
 style={{ paddingRight: 10 }}
 >
 <video src={value.msg_text} controls>
 Your browser does not support the video tag.
 </video>
 </span>}
 {value.attachment === 'audio' && <span
 className="chat-response"
 style={{ paddingRight: 10 }}
 >
 <audio controls src={value.msg_text}>
 Your browser does not support the audio tag.
 </audio>
 </span>}


 </>


 </div>
 )}
 <div ref={messagesEndRef} />
 </div>
 )
 })}
 </>
 )}
 {history.length === 0 && (
 <>
 <div className="chat-nodata">No Chat</div>
 </>
 )}
 </InfiniteScroll>
 </div>
 </div>
 <div className="chat-main-tail">

 <div className="chat-main-tail-lft">
 <textarea
 // onKeyPress={(event) => {
 // if (event.key === 'Enter') {
 // sendmessage("Enter")
 // }
 // }}
 value={SendMessage}
 onChange={(e) => {
 setSendMessage(e.target.value)
 }}
 style={{ height: '53px' }}
 className="form-control chat-main-tail-lft-input"
 placeholder="Type here.."
 id="exampleFormControlTextarea1"
 rows="1"
 ></textarea>
 </div>
 {props.whatsappsession.length > 0 && <div className="chat-main-tail-rht">
 <label htmlFor="file-upload" className="file-upload-label">

 <span className="material-icons">attach_file
 <input
 type="file"
 id="file-upload"
 ref={fileInputRef}
 onChange={handleFileUpload}
 className="file-upload-input"
 />
 </span>
 </label>
 </div>}
 {props.whatsappsession.length > 0 && <div className="chat-main-tail-rht">
 <span
 onClick={sendmessage}
 className={cus_value === props.whatsappsession[0].custom_value ? "material-icons chat-main-tail-rht-icon" : "material-icons chat-main-tail-rht-icon disabledbutton"}
 >
 send
 </span>
 </div>}
 </div>
 </div>
 )}
 </div>
 )
}

export default Wpchat

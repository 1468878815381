import React, { useState } from 'react'
import { Col, Form, Row, Button, Card } from 'react-bootstrap'
import CallScreen from '../components/CallScreen'
import LeadTable from '../components/Table'
import VoicemailTable from '../components/Table'
import Format from '../utils/format-text'
import EventEmitter from '../utils/EventEmitter'
import { useEffect } from 'react'
import moment from 'moment'
import {
    fetch_voicemail,
} from '../redux/actions/VoiceMail'
import { setaudio, playaudio } from '../redux/Lead/LeadAction'
import { useDispatch, useSelector } from 'react-redux'
import Dialogbox from '../components/Dialogbox'
import Filters from '../components/filter'

import speaker from '../assets/images/timeline/speaker.svg'
import mutespeaker from '../assets/images/timeline/mutespeaker.svg'
import { useNavigate } from 'react-router'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Outbound } from '../redux/SocketAction/SocketAction'
import { callmodule } from '../redux/Calls/CallAction'
import App from '../App'
import AppPermission from '../AppPermission'
import { Encode } from '../utils/EncodeDecode'

function Voicemail() {
    const user_id = localStorage.getItem('user')
    let wasession = localStorage.getItem("wasession")
    let navigate = useNavigate()
    let stateValue = useSelector((state) => state)
    const [searchdata, setSearch] = useState('')
      let [loader,setloader]= useState(false)
    let dispatch = useDispatch()
    const [Voicemaildetail, setVoicemaildetail] = useState([])
    const [skipdata, setskipdata] = useState({ skip: 0, take: 20 })
    const [count, setcount] = useState(0)
    const [infinitScroll, setinfinitScroll] = useState({ limit: 7, more: true })
    const [filterdata, setfilterdata] = useState({})
    let AppPermission
    if (localStorage.getItem('config')) {
        AppPermission = JSON.parse(localStorage.getItem('config')).data.projects[0].userPermission
    }
    let [maskphoneindex,setmaskphoneindex] = useState({index:0,value:""})
    const [header, setheader] = useState([


        {
            Header: 'Phone Number',
            accessor: 'phone_number',
        },
        {
            Header: 'Campaign',
            accessor: 'campaign',
        },
        {
            Header: 'Voicemail Date',
            accessor: 'calldate',
        },
        {
            Header: 'Queue',
            accessor: 'queue',
        },

        {
            Header: 'Action',
            accessor: 'row_id',

            Cell: ({ row }) => {
                return (
                    <>

                        <div className={localStorage.getItem("voicemailplay") == row.id || localStorage.getItem("voicemailplay") == null ? " lead_action" : "lead_action disabledbutton"}>
                            {localStorage.getItem("voicemailplay") != row.id && AppPermission.voicemail_playparse &&
                                <img
                                    onClick={() => {
                                        playrecordings(row, true)
                                    }}
                                    className={stateValue.background.oncall === false ? "timeline-action-image" : "timeline-action-image disabledbutton"}
                                    src={speaker}
                                    alt="test"
                                />
                            }

                            {localStorage.getItem("voicemailplay") == row.id && AppPermission.voicemail_playparse &&
                                <img
                                    onClick={() => {
                                        pauserecordings(row, false)
                                    }}
                                    className="timeline-action-image"
                                    src={mutespeaker}
                                    alt="test"
                                />
                            }
                            <span
                                className={" material-icons"}
                                style={{ color: '#3e98c7' }}
                                onClick={() => VoicemailView(row)}
                            >
                                info
                            </span>
                            {AppPermission.click_to_call && <span
                                className={(localStorage.getItem("voicemailplay") == row.id || stateValue.lead.playstate == true || stateValue.background.oncall == true) ? "material-icons disabledbutton" : wasession === "true" ? "material-icons disabledbutton" : "material-icons"}
                                style={{ color: '#32AA52' }}
                                onClick={() => clickcall(row)}
                            >
                                phone
                            </span>}

                        </div>


                    </>

                )
            },

        },
    ])
    useEffect(() => {

        fetchvoicemail()
        localStorage.removeItem("voicemailplay")
        let index= header.findIndex((elm=> elm.Header =="Phone Number"))
    if(index !=-1){
      setmaskphoneindex({index:index,value:header[index].accessor})
    }
    }, [])
    const VoicemailView = (ele) => {

        navigate(`/voicemail/${Encode(ele.original.phone_number)}`)
        pauserecordings(ele, false)

    }
    function playrecordings(ele, value) {
        let element = ele.original

        dispatch(setaudio(new Audio(element.audio)))
        if (stateValue.lead.audio === '') {
            dispatch(playaudio(new Audio(element.audio), value))
            localStorage.setItem("voicemailplay", ele.id)
        } else {
            dispatch(playaudio(stateValue.lead.audio, value))
            localStorage.setItem("voicemailplay", ele.id)
        }
    }
    function pauserecordings(ele, value) {

        dispatch(playaudio(stateValue.lead.audio, value))
        localStorage.removeItem("voicemailplay")
    }

    function datetime() {
        var currentdate = new Date().toISOString()
        return currentdate
    }



    const fetchvoicemail = (search,Moredata) => {
        const user_id = localStorage.getItem('user')
        let query = {
            userID: user_id,
        }
        query.skip = skipdata.skip
        query.take = skipdata.take
        if (search !== 'mount' && search !== 'filter' && search !== 'refresh') {
            query.filter = search
            // query.skip = 0
            // query.take = 20
        }
        if (search == 'filter') {
            if (filterdata.from != "" & filterdata.to != "") {
                query.fromDate = filterdata.from
                query.toDate = filterdata.to

            }
            if (filterdata.phone) {
                query.phone = filterdata.phone
            }


        }
        if (search == "refresh") {
            query.skip = 0
            query.take = 20
            setfilterdata({})
            setSearch("")
            setinfinitScroll((set) => {
                set.more = true
                return {
                    ...set,
                }
            })

        }
        if(Moredata !== "Moredata"){
            setloader(true)
        }
        dispatch(fetch_voicemail(query)).then(function (response) {
            setloader(false)
            let element = response.data.data
            let value = []

            if (response.status == 200) {
                setcount(response.data.count)
                if (search == "refresh") {
                    setskipdata((set) => {

                        set.skip =20
                        set.take = 10

                        return {
                            ...set,
                        }
                    })
                }
                else {
                    setskipdata((set) => {
                        if (set.take === 20) {
                            set.skip = 20
                            set.take = 10
                        } else {
                            set.skip = set.skip + 10
                            set.take = 10
                        }
                        return {
                            ...set,
                        }
                    })
                }

                for (let i = 0; i < element.length; i++) {
                    let obj = {
                        phone_number: element[i].phone_number,
                        campaign: Format(element[i].campaign),
                        calldate: `${element[i].calldate.split("T")[0]} ${" "} ${moment(element[i].calldate.split("T")[1], ["HH.mm.ss"]).format("hh:mm a")}`,
                        queue: Format(element[i].queue),
                        sound: false,
                        audio: element[i].record_location

                    }
                    value.push(obj)
                }

                if (query.skip === 0 && searchdata !== '' || search === "refresh") {
                    setVoicemaildetail([].concat(value))
                } else {
                    setVoicemaildetail(Voicemaildetail.concat(value))
                }
            } else if (response.status == 204) {
                setVoicemaildetail([].concat(value))
            }
        }).catch((err) => {
            EventEmitter.emit('error-message-getter', {
                action: 'fetch',
                module: 'voicemail',
            })
        })
    }
    const filterdataapi = (filter) => {
        let query = {
            userID: user_id,
            fromDate: filter.from,
            toDate: filter.to,
            phone: filter.phone
        }
        setfilterdata(filter)
        query.skip = 0
        query.take = 20
        setloader(true)
        dispatch(fetch_voicemail(query)).then(function (response) {
            setloader(false)
            if (response.status == 200) {
                setSearch('')
                setcount(response.data.count)
                let element = response.data.data
                let value = []
                setskipdata((set) => {
                    set.skip = 20
                    set.take = 10
                    return {
                        ...set,
                    }
                })
                for (let i = 0; i < element.length; i++) {
                    let obj = {
                        phone_number: element[i].phone_number,
                        campaign: Format(element[i].campaign),
                        calldate: `${element[i].calldate.split("T")[0]} ${" "} ${moment(element[i].calldate.split("T")[1], ["HH.mm.ss"]).format("hh:mm a")}`,
                        queue: Format(element[i].queue),
                        sound: false,
                        audio: element[i].record_location
                    }
                    value.push(obj)
                }
                setVoicemaildetail([].concat(value))
            }
        }).catch((err) => {
            EventEmitter.emit('error-message-getter', {
                action: 'fetch',
                module: 'voicemail',
            })
        })
    }
    let fetchMoreData = () => {
        if (Voicemaildetail.length >= count) {
            setinfinitScroll((set) => {
                set.more = false
                return {
                    ...set,
                }
            })
        } else {
            setTimeout(() => {
                if (searchdata !== '') {
                    fetchvoicemail(searchdata,"Moredata")
                }
                if (Object.keys(filterdata).length > 0) {
                    fetchvoicemail('filter',"Moredata")
                } else {
                    fetchvoicemail('mount',"Moredata")
                }
            }, 1000)
        }
    }

    function searchvoicemail(e) {
        setSearch(e)
        setinfinitScroll((set) => {
            set.more = true
            return {
                ...set,
            }
        })
        let query = {}
        query.skip = 0
        query.take = 20
        query.filter = e
        query.userID = user_id
        setloader(true)
        dispatch(fetch_voicemail(query)).then(function (response) {
            let element = response.data.data
            let value = []
            setloader(false)
            if (response.status == 200) {
                setfilterdata({})
                setcount(response.data.count)
                setskipdata((set) => {
                    set.skip = 20
                    set.take = 10
                    return {
                        ...set,
                    }
                })
                for (let i = 0; i < element.length; i++) {
                    let obj = {
                        phone_number: element[i].phone_number,
                        campaign: Format(element[i].campaign),
                        calldate: `${element[i].calldate.split("T")[0]} ${" "} ${moment(element[i].calldate.split("T")[1], ["HH.mm.ss"]).format("hh:mm a")}`,
                        queue: Format(element[i].queue),
                        sound: false,
                        audio: element[i].record_location
                    }
                    value.push(obj)
                }
                setVoicemaildetail([].concat(value))
            } else if (response.status == 204) {
                setVoicemaildetail([].concat(value))
            }
        }).catch((err) => {
            EventEmitter.emit('error-message-getter', {
                action: 'fetch',
                module: 'voicemail',
            })
        })
    }

    const refresh = () => {
        fetchvoicemail('refresh')
    }

    function clickcall(data) {
        let readystate = localStorage.getItem("selectedPause")
        if (readystate == "available") {
            let payload = {
                state: stateValue.background.websocketconnection,
                value: data.original.phone_number.toString()
            }
            dispatch(callmodule({
                id: "",
                module: "voicemail",
                phone_no: data.original.phone_number.toString()
            }))
            dispatch(Outbound(payload.state, payload.value))

        } else {

            EventEmitter.emit('error-message-getter', {
                action: 'ready',
                module: "Agent Not ready",
            })
        }

    }


    return (
        <div className="page-main-container">

            <div className="scroll-lft" md={8}>
                <div className="lead-header">
                    {AppPermission.voicemail_serach && <div className="lead-header1">
                        <div className="route-header lead-header1-lft"> Voicemail</div>
                        <div className="lead-search">
                            <span className="material-icons lead-search-icon">search</span>
                            <Form.Control
                                type="text"
                                value={searchdata}
                                placeholder="Search"
                                style={{
                                    border: '1px solid #e9ecef',
                                    backgroundColor: '#fff',
                                    padding: 10,
                                    'padding-left': '40px',
                                }}
                                onChange={(e) => searchvoicemail(e.target.value)}
                            />
                        </div>
                    </div>}
                    <div className="lead-header2">
                        {AppPermission.voicemail_refresh && <div
                            onClick={refresh}
                            className="lead-save-icons lead-create-head-rht material-icons"
                        >
                            refresh
                        </div>}
                        {AppPermission.voicemail_filter && <Filters filterdataapi={filterdataapi} type={'voicemail'} />}


                    </div>
                </div>
                <div className="lead-body">
                    <div
                        id="voicemailDiv"
                        style={{
                            height: '100%',
                            overflow: 'auto',
                            color: 'grey',
                            textAlign: 'center',
                            width: '100%',
                        }}
                    >
                        <InfiniteScroll
                            dataLength={Voicemaildetail.length}
                            next={fetchMoreData}
                            hasMore={infinitScroll.more}
                            scrollableTarget="voicemailDiv"
                            style={{
                                overflow: "initial"
                            }}
                        >
                            <VoicemailTable
                            loader={loader}
                                header={header}
                                leaddetail={Voicemaildetail}
                                type="meet"
                                maskphoneindex={maskphoneindex}
                            />
                        </InfiniteScroll>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Voicemail

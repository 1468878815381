import React, { useState, useRef } from 'react'
import { useEffect } from 'react'
import Leadsview from '../../pages/LeadView'

function CreateLeads() {
  
  const [loadCustomfrom, setloadCustomfrom] = useState(false)

  useEffect(() => {
    
      setloadCustomfrom(true)
   
  }, [])

  return (
    <>
      {loadCustomfrom && (
       <>
          <Leadsview leadmodule={true} />
        </>
      )}
    </>
  )
}

export default CreateLeads

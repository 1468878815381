import { combineReducers } from 'redux'
import LoginReducer from './Login/LoginReducer'
import CallReducer from './Calls/CallReducer'
import DetailReducer from './Details/DetailReducer'
import LeadReducer from './Lead/LeadReducer'
import Activity from './activity/activityReducer'
import CustomForm from './CustomForm/CustomFormReducer'
import Background from './background/BackgroundReducer'
import ChatNotification from "./Chat-notification/Chat-notification-Reducer"
import CustomFilterReducer from './customFilter/CustomFilterReducer'
import SocketAction from './SocketAction/SocketReducer'
const rootReducer = combineReducers({
    call: CallReducer,
    login: LoginReducer,
    detail: DetailReducer,
    lead: LeadReducer,
    activity: Activity,
    customform:CustomForm,
    background:Background,
    chatNotification:ChatNotification,
    customFilter:CustomFilterReducer,
    socketAction:SocketAction
})
export default rootReducer;
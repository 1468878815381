
import api from "../../utils/axios-util";


export const TicketHistory = (data) => {
    return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
            await api
                .get(`/ticketshistory/fetch?skip=${data.skip}&take=${data.take}&ticket_id=${data.ticket_id}`)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
};

export const CreateTicketNote = (data) => {
    return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
            await api
                .post(`/ticket/notelog/create`, data)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
};
export const SendTicketEmail = (data) => {
    return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
            await api
                .post(`/mailsend`, data)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
};

export const TicketCustomformFields = () => {
    return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
            await api
                .get(`/ticketsfields/fetch`)
                .then((response) => {

                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
};

export const TicketFetch = (url) => {
  
    return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
            let teamdata = JSON.parse(localStorage.getItem("Teaminfo"))
            let channel = []
            if (Object.keys(teamdata).length > 0) {
                if (teamdata.type == "all" || teamdata.type == "channel") {
                    channel = teamdata.type_data.channel.map((elm) => elm.name)
                }
            }
            await api
                .post(url, { channel: channel })
                .then((response) => {

                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
};




export const TicketUpdate = (ticket_id, payload) => {
     payload.ticket_id = ticket_id
    return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
            await api
                .put(`/tickets/update`, payload)
                .then((response) => {

                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
};

export const TicketGetRequestModule = (phone, template) => {
    return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
            await api
                .get(`/fetch/requestmodule?phone_number=${phone}&template_name=${template}`)
                .then((response) => {
                   if (response.status === 200) {
                        dispatch({
                            type: 'CUSTOM_TICKET_REQ_MODULE',
                            payload: response.data.data
                        })
                        resolve(response);
                    } else {
                        dispatch({
                            type: 'CUSTOM_TICKET_REQ_MODULE',
                            payload: []
                        })
                        resolve(response);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
};

export const TicketCreate = (payload) => {
    return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
            await api
                .post(`/tickets/create`, payload)
                .then((response) => {

                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
};


export const TicketTagManagement = () => {
    return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
            await api
                .get(`/tags/fetch`)
                .then((response) => {

                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
};
export const TicketMerge = (payload) => {
    payload.portal="false"
    return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
            await api
                .post(`/tickets/merge`, payload)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
};
export const TicketDeMerge = (payload) => {
    return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
            await api
                .post(`/tickets/unmerge`, payload)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
};

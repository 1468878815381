import api from "../../utils/axios-util";


export const CustomformData = (payload) => {
    return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
            await api
                .get(`/records?phone_number=${payload.phone_number}&template_name=${payload.template_name}&id=${payload.id}`)
                .then((response) => {
                   
                    if (response.data.statusCode == 200) {
                        dispatch({
                            type: 'CUSTOM_DATA',
                            payload: response.data.data[0].others
                        })

                        dispatch({
                            type: 'CUSTOM_SIDEBARINFO',
                            payload: true
                        })
                    } else if (response.status == 204) {
                        dispatch({
                            type: 'CUSTOM_SIDEBARINFO',
                            payload: false
                        })
                    }

                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
};


export const CustomformFields = (template_name) => {
    return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
            await api
                .get(`/aster/form/template/fields?template_name=${template_name}`)
                .then((response) => {
                    dispatch({
                        type: 'CUSTOM_FIELDS',
                        payload: response.data.data[0]
                    })
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
};

export const CustomformUpdate = (data, tempdetail) => {
    return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
            await api
                .put(`/aster/form/${tempdetail.template_name}/${tempdetail.id}`, data)
                .then((response) => {
                    dispatch({
                        type: 'CUSTOM_UPDATE',
                        payload: data
                    })
                    resolve("updated", response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
};

export const CustomFormCreate = (payload) => {
    return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
            await api
                .post(`/aster/form`, payload)
                .then((response) => {
                    dispatch({
                        type: 'SET_SAVING',
                        saving: false
                    });

                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
};

export const ModuleCustomformFields = (template_name) => {
    return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
            await api
                .get(`/formbuilder/aster/form/template/fields?template_name=${template_name}`)
                .then((response) => {
                    dispatch({
                        type: 'CUSTOM_FIELDS',
                        payload: response.data.data[0]
                    })
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
};
export const CustommoduleFetch = (payload) => {
    return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
            await api
                .get(`/getAssignedModule?template_name=${payload.template_name}&module=${payload.module}`)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
};


export const PopupRouter = (payload) => {
    return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
            await api
                .get(`/popup?phone_number=${payload.phone_number}&module=${payload.module}&template_name=${payload.template_name}&user=${localStorage.getItem('user')}`)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
};
export const GetChannel = () => {
    return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
            await api
                .get(`/channel/fetch?active=0`)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
};



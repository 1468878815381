import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Timeline from '../Lead/Lead-timeline'
import ContactCustomform from './Contact-Customform'
import Notepad from '../Lead/Lead-notepad'
import Meeting from '../Lead/Lead-meeting'
import { useParams } from 'react-router-dom'
import { CustommoduleFetch } from '../../redux/actions/Customform'
import { useLocation, useNavigate } from 'react-router'
import LinkTickets from '../Lead/Other-Ticket'
import LinkLead from '../Lead/Lead-template'
import { Decode, Encode } from '../../utils/EncodeDecode'
import { OverlayTrigger } from 'react-bootstrap'
import EventEmitter from '../../utils/EventEmitter'
import { PopupRouter } from '../../redux/actions/Customform'
import Tooltip from 'react-bootstrap/Tooltip'
function ContactMainView(props) {
  let AppPermission
  let navigate = useNavigate()
  if (localStorage.getItem('config')) {
    AppPermission = JSON.parse(localStorage.getItem('config')).data.projects[0].userPermission
  }
  const agent = localStorage.getItem('user')
  let dispatch = useDispatch()
  const [custom_module, setcustom_module] = useState([])
  const [count, setCount] = useState(0)
  const [save, setsave] = useState(false)
  let { contact_id, phone_number, meeting_id } = useParams()
  phone_number = Decode(phone_number)
  let [fetchapi, setfetchapi] = useState(false)
  let [regexvalue, setregexvalue] = useState(true)
  let location = useLocation()
  let campaigninfo = JSON.parse(localStorage.getItem("selectedCampaign_data"))
  const [name, setname] = useState('noname')

  useEffect(() => {
    if (count === 1) {
      localStorage.setItem("oncustomform", "true")
    } else {
      localStorage.removeItem("oncustomform")
    }
  }, [count])
  useEffect(() => {
    dispatch(CustommoduleFetch({ template_name: JSON.parse(localStorage.getItem("selectedCampaign_data")).template_name, module: "contact" })).then((response) => {
     
      if (response.data.statusCode === 200) {
        setcustom_module(response.data.data)
      } else {
        setcustom_module([])
      }
    })
      .catch((err) => {
        EventEmitter.emit('error-message-getter', {
          action: 'create',
          module: 'activity',
        })
      })
    if (location.pathname === `/meeting/${Encode(phone_number)}/${meeting_id}` && AppPermission.view_meeting) {
      if (AppPermission.meeting_detail) {
        setCount(3)
      } else if (AppPermission.meeting_custom_form) {
        setCount(1)
      }
    } else if (location.pathname === `/voicemail/${Encode(phone_number)}` && AppPermission.voicemail_view) {

      if (AppPermission.voicemail_custom_form) {
        setCount(1)
      } else if (AppPermission.contact_history) {
        setCount(2)
      }
    }
    else {
      if (AppPermission.contact_custom_form) {
        setCount(1)
      } else if (AppPermission.contact_history) {
        setCount(2)
      } else if (AppPermission.contact_comment_create) {
        setCount(5)
      } else if (AppPermission.contact_lead_template) {
        setCount(7)
      } else if (AppPermission.contact_ticket) {
        setCount(6)
      } else if (AppPermission.contact_meeting) {
        setCount(3)
      }
    }
  }, [])

  useEffect(() => {

    if (props.formdatas.status === 200) {
      setname(props.formdatas.data.data[0].contactName)
    }
  }, [props.formdatas])


  function update() {
    setsave(true)
  }

  function saveleaddetail() {
    setsave(false)
  }



  function regexval(data) {
    if (data) {
      setregexvalue(true)
    } else {
      setregexvalue(false)
    }
  }


  function fetchdata(ticket) {

    props.fetchapi(!fetchapi)


  }
  function contactdialogopen(phone, model) {
    if (model === "tickets") {
      let payload = {
        phone_number: phone
      }
      payload.module = 'tickets'
      dispatch(PopupRouter(payload)).then((res) => {
        if (res.data.ids.length > 0) {
          let id = res.data.ids[0].ticket_id
          navigate(`/tickets/${Encode(phone)}/${id}`)
        } else {
          navigate('/tickets/create')
          localStorage.setItem(`setStatic_number`,`${phone}`)
        }
      }).catch((err) => {
        EventEmitter.emit('error-message-getter', {
          action: 'fetch',
          module: 'popup router',
        })
      })
    }else{
      let payload = {
          phone_number: phone,
          template_name: campaigninfo.template_name,
      }
      payload.module = 'leads'
      dispatch(PopupRouter(payload)).then((res) => {
          if (res.data.ids.length > 0) {
              let id = res.data.ids[0].lead_id
              navigate(`/leads/${Encode(phone)}/${id}`)
          } else {
              navigate('/leads/create')
              localStorage.setItem(`setStatic_number`,`${phone}`)
          }
      }).catch((err) => {
          EventEmitter.emit('error-message-getter', {
              action: 'fetch',
              module: 'popup router',
          })
      }) 
  }

  }
  return (
    <>

      {props.formdatas.status === 200 && (
        <div className="activity" style={{ cursor: 'pointer' }}>
          <div>
            {location.pathname === `/meeting/${Encode(phone_number)}/${meeting_id}` && (location.pathname.split("/")[1] === "meeting" && AppPermission.view_meeting && AppPermission.meeting_detail) && (
              <span
                onClick={() => setCount(3)}
                className={count === 3 ? 'active' : ''}
              >
                Meetings
              </span>
            )}
            {AppPermission.contact_custom_form && <span
              onClick={() => setCount(1)}
              className={count === 1 ? 'active' : ''}
            >
              Details
            </span>}
            {/* {disable && */}
            {AppPermission.contact_action && <span
              onClick={() => setCount(8)}
              className={count === 8 ? 'active' : ''}
            >
              {' '}
              Activity
            </span>}
            {AppPermission.contact_history && <span
              onClick={() => setCount(2)}
              className={count === 2 ? 'active' : ''}
            >
              {' '}
              History
            </span>}
            {AppPermission.contact_lead_template && <span
              onClick={() => setCount(7)}
              className={count === 7 ? 'active' : ''}
            >
              Lead
            </span>}
            {AppPermission.contact_ticket && <span
              onClick={() => setCount(6)}
              className={count === 6 ? 'active' : ''}
            >
              Tickets
            </span>}

            {/* } */}

            {location.pathname !== `/meeting/${Encode(phone_number)}/${meeting_id}` && AppPermission.contact_meeting && <span
              onClick={() => setCount(3)}
              className={count === 3 ? 'active' : ''}
            >
              Meetings
            </span>}

            {AppPermission.contact_comment_create && <span
              onClick={() => setCount(5)}
              className={count === 5 ? 'active' : ''}
            >
              Comments
            </span>}

          </div>
        </div>
      )}

      <div className="lead-main-card">
        {count === 1 && AppPermission.contact_custom_form && (
          <>
            <div className="Customform-catagory Customform-header">
              <div className="Customform-catagory-lft">
                {/* <div className="Customform-category-icon">
                  {' '}
                  <BsFillHexagonFill />
                </div> */}
                <div className="Customform-category-title Customform-ticket-category-title">
                  #{contact_id || ''} Contact Details
                </div>
              </div>
              <div className="Customform-catagory-rht">

                <div className="Customform-save-bts">
                  {custom_module.length > 0 && location.pathname !== "/contact/create" && <>
                    {custom_module.map((item, i) => {
                      return (
                        <>
                          {AppPermission.view_custommodule && <div onClick={() => {
                            localStorage.setItem("module_back", location.pathname)
                            navigate(`/custom-module/${item.template_name}`)
                          }} key={i} className="navbar-custom-module-icon navbar-logo-img Customform-save-icon wa-icon">{item.template_name.split("")[0].toUpperCase()}</div>}
                        </>

                      )
                    })
                    }
                  </>}
                  {props.operation == false && AppPermission.ticket_create && AppPermission.ticket_view && <OverlayTrigger
                    placement={'bottom'}
                    overlay={<Tooltip>Ticket</Tooltip>}
                  >

                     <div
                      className="material-icons Customform-save-icon "
                      onClick={() => { contactdialogopen(phone_number, 'tickets') }}
                    >
                      confirmation_number
                    </div>
                  </OverlayTrigger>}
                  {props.formdatas.status == 200 && <>
                    {AppPermission.lead_view &&
                      <OverlayTrigger
                        placement={'bottom'}
                        overlay={<Tooltip>
                          Lead</Tooltip>}
                      >
                        <div
                          onClick={() => { contactdialogopen(phone_number, 'leads') }}
                          className="material-icons Customform-save-icon "
                        >contact_page

                        </div>
                      </OverlayTrigger>
                    }
                  </>}
                  <OverlayTrigger
                    placement={'bottom'}
                    overlay={<Tooltip>save</Tooltip>}
                   
                  >
                  <div
                    onClick={update}
                    className={regexvalue ? "material-icons Customform-save-icon " : "material-icons Customform-save-icon disabledbutton"}
                  >
                    save
                  </div>
                  </OverlayTrigger>
                </div>
              </div>
            </div>

            <div
              className="lead-main-card_sub-content"

            >
              <ContactCustomform
                operation={props.operation}
                formdatas={props.formdatas}
                statedata={props.statedata}
                savebutton={save}
                fetchapi={fetchdata}
                islead={props.islead}
                regexval={regexval}
                saveleaddetail={saveleaddetail}
              />
            </div>
          </>
        )}
        {count === 2 && AppPermission.contact_history && (
          <Timeline view={"history"} id={props.formdatas.data.data[0].id} type={'contact'} />
        )}
        {count === 8 && AppPermission.contact_action && (
          <Timeline view={"action"} id={props.formdatas.data.data[0].id} type={'contact'} />
        )}
        {count === 5 && AppPermission.contact_comment_create &&
          (
            <Notepad
              basicdata={{
                id: props.formdatas.data.data[0].id,
                phone_number: phone_number,
                module: "contact"
              }}
              type={'contact'}
            />

          )}
        {count === 3 && AppPermission.contact_meeting &&
          (
            <Meeting type={"contact"} name={name} id={props.formdatas.data.data[0].id}
            />

          )}


        {count === 6 && AppPermission.contact_ticket &&
          (
            <LinkTickets fetchapi={fetchdata} id={props.formdatas.data.data[0].id} type={"contact"}
            />

          )}
        {count === 7 && AppPermission.contact_lead_template &&
          (
            <LinkLead type={"contact"} />

          )}

      </div>
    </>
  )
}

export default ContactMainView
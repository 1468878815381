
import "../../assets/css/Error.css"
import logo from '../../assets/images/brokenComputer.svg'
import logo2 from '../../assets/images/logo.png'
const ErrorPage = () => {
    return (
        <div className="main-body">
            <use>
            <img className="whistle" src={logo} alt="test" style={{ height: 250, width: 250, borderRadius: 50 }} />
            
            </use>
            <h1 className="error-code">404</h1>
            <h2 className="error-message">Not this time, The Page You Try TO Reach Not Exist!</h2>
        </div>
    );
}

export default ErrorPage;
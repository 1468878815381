import {
  CHAT_ACTIVE_SCREEN,
  
} from "./Chat-notification-Types";
const initialState = {
  chatScreenActive: false, 
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CHAT_ACTIVE_SCREEN:
      return {
        ...state,
        chatScreenActive: action.payload,
      };
    default:
      return state;
  }
};
export default reducer;

import React from 'react';

const LoadingSpinner = ({size}) => {
  return (
    <div className="loadingio-eclipse">
      <div className={size == 'larger' ? "main-loader larger-loader" : "main-loader smaller-loader"}>
        <div ></div>
      </div>
    </div>
  );
}

export default LoadingSpinner;
import React, { useState, useEffect } from 'react'
import { Col, Row, Card, Accordion, Form } from 'react-bootstrap'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { connect, useDispatch, useSelector } from 'react-redux'
import EventEmitter from '../../utils/EventEmitter'
import Format from '../../utils/format-text'
import { FiPhone, FiMail, FiMessageSquare } from 'react-icons/fi'
import { BsWhatsapp, BsPersonCircle } from 'react-icons/bs'
import { useParams, useLocation } from 'react-router-dom'
import { Outbound } from '../../redux/SocketAction/SocketAction'
import { callLeadDetail , tempData} from '../../redux/background/BackgroundAction'
import { callmodule } from '../../redux/Calls/CallAction'
import missed from "../../assets/images/leadscore/missed.svg"
import outbound from "../../assets/images/leadscore/outgoing.svg"
import inbound from "../../assets/images/leadscore/incoming.svg"
import wa from "../../assets/images/leadscore/wa.svg"
import star from "../../assets/images/leadscore/star.svg"
import Carousel from 'react-bootstrap/Carousel';
import blueusericon from "../../assets/images/leadscore/blueusericon.jpg"
import open from '../../assets/images/leadscore/open.svg'
import pending from '../../assets/images/leadscore/pending.svg'
import resolve from '../../assets/images/leadscore/resolved.svg'
import closesvg from "../../assets/images/leadscore/closesvg.svg"
import hold from "../../assets/images/leadscore/hold.svg"
import Dialogbox from './../Dialogbox'
import { getTemplate } from '../../redux/actions/ChatActions'
import { waTemplate } from '../../redux/SocketAction/SocketAction'


import inprogress from '../../assets/images/leadscore/inprogress.svg'
import woc from '../../assets/images/leadscore/woc.svg'
import overdue from "../../assets/images/leadscore/overdue.svg"
import { leadscore } from "../../redux/actions/Dashboard"
import { ContactFetch } from '../../redux/actions/ContactActions'
import addressimg from '../../assets/images/leadsideview/address.svg'
import cityimg from '../../assets/images/leadsideview/city.svg'
import countryimg from '../../assets/images/leadsideview/country.svg'
import emailimg from '../../assets/images/leadsideview/email.svg'
import firNameimg from '../../assets/images/leadsideview/firName.svg'
import lastnameimg from '../../assets/images/leadsideview/lastname.svg'
import stateimg from '../../assets/images/leadsideview/state.svg'
import headcallimg from '../../assets/images/leadsideview/headcall.png'
import headwaimg from '../../assets/images/leadsideview/headwa.png'
import { Decode } from '../../utils/EncodeDecode'
function LeadSideView(props) {
  let statevalue = useSelector((state) => state)
  let wasession = localStorage.getItem("wasession")
  let dispatch = useDispatch()
  let AppPermission
  if (localStorage.getItem('config')) {
    AppPermission = JSON.parse(localStorage.getItem('config')).data.projects[0].userPermission
  }
  const [userdetails, setuserdetails] = useState({})
  const [score, setscore] = useState({})
  const [ticketscore, setticketscore] = useState([])
  const location = useLocation()
  let { phone_number, meeting_id, ticket_id, contact_id, lead_id } = useParams()
  phone_number = Decode(phone_number)
  const [popUpData, setpopUpData] = useState({
    open: false,
    Type: 'form_bar',
    field: [
      {
        type: 'SelectList',
        label: 'Whatsapp Template',
        placeholder: ' select message',
        required: true,
        model: 'whatsapp_template',
        option: [],
        multiple: false,
        value: '',
      },
    ],
    title: 'Whatsapp Template',
    Message: 'Selected Successfully',
    Butonstatus: 'Send',
  })
  useEffect(() => {

    dispatch(leadscore({ id: props.id, module: props.type })).then((res) => {
      if (res.status === 200) {
        setticketscore(res.data.data)
        setscore(res.data.data)

        if(AppPermission.wa_view){
          dispatch(getTemplate({ id: props.id, module: props.type })).then((res) => {
            if (res.data.length > 0) {
  
              setpopUpData((set) => {
                set.field[0].option = res.data.map(e => {
                  e.label = e.name
                  e.value = e.id
                  return e
                })
                return {
                  ...set
                }
              })
            }
          }).catch((err) => {
            EventEmitter.emit('error-message-getter', {
              action: 'fetch',
              module: "whatsapp Template",
            })
          })
        }




      }
    }).catch((err) => {
      EventEmitter.emit('error-message-getter', {
        action: 'fetch',
        module: "summary",
      })
    })
  }, [])

  useEffect(() => {
    if (props.formdatas.data !== undefined) {
      if (props.type === "contact") {
        console.log(props.formdatas.data,"contact_data_detailsss");
        let obj = {
          first_name: props.formdatas.data.data[0].contactName,
          last_name: "",
          email: props.formdatas.data.data[0].email,
          phone_number: props.formdatas.data.data[0].contactNumber,
          address: props.formdatas.data.data[0].address1,
          country: props.formdatas.data.data[0].country,
          city: props.formdatas.data.data[0].city,
          state: props.formdatas.data.data[0].state,
          profile_url: props.formdatas.data.data[0].profile_url, 

        }
        setuserdetails(obj)

      } else {
        dispatch(
          ContactFetch(
            `/contact/fetchsearch?number=${phone_number}`,
          ),
        ).then((res) => {
          const s = res.data.data


          if (s.length > 0) {
            let obj = {
              first_name: s[0].contactName,
              last_name: "",
              email: s[0].email,
              phone_number: s[0].contactNumber,
              address: s[0].address1,
              country: s.country,
              city: s[0].city,
              state: s[0].state,
              profile_url: s[0].profile_url,

            }
            setuserdetails(obj)
            dispatch(tempData({type:"contact",data:obj}))
          }
          else {
            setuserdetails(props.formdatas.data.data[0].basic)
          }
        })

      }

    }
  }, [props.formdatas])
console.log(props.formdatas,"props contacts");
  function wapopup() {
    setpopUpData((set) => {
      set.open = true
      return {
        ...set
      }
    })
  }
  function closepopup(data) {
    if (data.action === "cancel") {
      setpopUpData((set) => {
        set.open = false
        return {
          ...set
        }
      })
    } else {
      function find_(data_) {
        return data_.value === data.data[0].whatsapp_template;
      }
      let templatedata = {
        "action": "send-wa-template",
        "station": localStorage.getItem('extension'),
        "phone_number": phone_number,
        "sender_id": lead_id,
        "sender_type": 1,
        "sender_name": props.formdatas.data.data[0].others.first_name,
        "tenant_id": JSON.parse(localStorage.getItem("config")).data.tenant_code,
        "agent": localStorage.getItem("user"),
        "template": {
          "id": data.data[0].whatsapp_template,
          "type": "",
          "language": data.field[0].option.find(find_).lang,
          "name": data.field[0].option.find(find_).name,
        }
      }
      if (data.action !== "cancel") {
        dispatch(waTemplate(statevalue.background.websocketconnection, templatedata))
        setpopUpData((set) => {
          set.open = false
          return {
            ...set
          }
        })
      }



    }
  }
  function clickcall(data, names) {
    let readystate = localStorage.getItem("selectedPause")
    if (readystate == "available") {
      let payload = {
        state: statevalue.background.websocketconnection,
        value: data.toString(),
      }
      let id
      let model
      if (ticket_id !== undefined && ticket_id !== "" && props.router !== "voicemail") {
        id = ticket_id.toString()
        model = "ticket"
      } else if (contact_id !== undefined && contact_id !== "" && props.router !== "voicemail") {
        id = contact_id.toString()
        model = "contact"
      }
      else if (meeting_id !== undefined && meeting_id !== "" && props.router !== "voicemail") {
        id = meeting_id.toString()
        model = "meeting"

      }
      else if (props.router === "voicemail") {
        id = ""
        model = "voicemail"

      }
      else {
        id = lead_id
        model = "lead"
      }


      if (names === "") {
        names = phone_number.toString()
      }
      localStorage.setItem("dialpadname", names);
      let leadData = {
        lead_id: id,
        lead_name: names,
        phone_number: phone_number.toString(),
        module: props.type,
        render: 1
      }
      dispatch(callLeadDetail(leadData))
      dispatch(callmodule({
        id: id !== "" ? id : "",
        module: model,
        phone_no: phone_number.toString()
      }))
      if (model === "meeting") {
        dispatch(Outbound(payload.state, payload.value, { meeting: true, meetingid: id }))
      } else {
        dispatch(Outbound(payload.state, payload.value))
      }


    } else {
      EventEmitter.emit('error-message-getter', {
        action: 'ready',
        module: "Agent Not ready",
      })
    }
  }
  return (
    <>
      <Dialogbox
        fields={closepopup}
        value={popUpData}
        className="dialog-body-default1"
        // cancel={{ display: 'none' }}
        save={'disabled'}
      />

      {props.formdatas !== undefined && (
        <div className="lead-side-view-component mobile_hide">

          <div className="lead-side-view-component-1">
            <div className="lead-side-view">
              <div /* className="route-header line" */ style={{ padding: '2%', justifyContent: 'center', alignContent: 'center' }}>
                {location.pathname.split('/')[1] === 'tickets'
                  ? 'Tickets'
                  : location.pathname.split('/')[1] === 'leads'
                    ?
                    <div style={{ display: 'flex' }}>
                      <div className='headText'>Customer</div>
                      <div style={{ marginLeft: 'auto', marginTop: '2px' }}>

                        {AppPermission.click_to_call && statevalue.background.oncall == false && wasession === "false" ?
                          <img
                            className="lead-newsideview-svg-image-head"
                            src={headcallimg}
                            alt="FName"
                            onClick={() => { clickcall(userdetails.phone_number, userdetails.first_name) }}
                          />
                          : null}

                        {statevalue.background.oncall == false && wasession === "false" ?
                          <img
                            className="lead-newsideview-svg-image-head"
                            src={headwaimg}
                            style={{ marginLeft: '5px' }}
                            alt="FName"
                            onClick={() => { wapopup() }}
                          />
                          : null}
                      </div>
                    </div>

                    : location.pathname.split('/')[1] === 'meeting'
                      ? 'Meetings'
                      : location.pathname.split('/')[1] === 'voicemail'
                        ? "Voicemail"
                        : location.pathname.split('/')[1] === 'contact'
                          ? "Contacts" :
                          ''}
                <hr class="hr" style={{ marginTop: '5px' }} />
                <div>
                {userdetails.profile_url !== "" && userdetails.profile_url !== undefined ?
                <img
                          className="lead-newsideview-svg-image"
                          src={userdetails.profile_url} 
                          alt="No Image"
                          height={150}
                          width={150}
                          style={{ display: 'block', margin: '0 auto' }}
                />
                : null}
                {userdetails.profile_url == "" || userdetails.profile_url == undefined?
                <img
                          className="lead-newsideview-svg-image"
                          src={blueusericon}
                          alt="No Image"
                          height={150}
                          width={150}
                          style={{ display: 'block', margin: '0 auto' }}

                />
                : null}
                </div>
                <div className='Leadnewsideview'>
                  <div className='headname'>{userdetails.first_name}</div>                  
                  <div className='headphone'>{Format(userdetails.phone_number, { maskbool: true })}</div>
                </div>
                <div className='Leadwrapper'>
                  <div className='Leadwrappercontent'>
                    <div className='wrapperformlabel'>First Name</div>
                    <div className='wrappervalue'>
                      <div className='wrappervaluetext'>{userdetails.first_name}</div>
                      <div className='wrappericon'>
                        <img
                          className="lead-newsideview-svg-image"
                          src={firNameimg}
                          alt="FName"
                        />
                      </div>

                    </div>
                    <hr class="hr" />
                  </div>

                  <div className='Leadwrappercontent'>
                    <div className='wrapperformlabel'>Email</div>
                    <div className='wrappervalue'>
                      <div className='wrappervaluetext'>{userdetails.email}</div>
                      <div className='wrappericon'><img
                        className="lead-newsideview-svg-image"
                        src={emailimg}
                        alt="email"
                      /></div>

                    </div>
                    <hr class="hr" />
                  </div>

                  <div className='Leadwrappercontent'>
                    <div className='wrapperformlabel'>Address</div>
                    <div className='wrappervalue'>
                      <div className='wrappervaluetext'>{userdetails.address}</div>
                      <div className='wrappericon'><img
                        className="lead-newsideview-svg-image"
                        src={addressimg}
                        alt="add"
                      /></div>

                    </div>
                    <hr class="hr" />
                  </div>

                  <div className='Leadwrappercontent'>
                    <div className='wrapperformlabel'>City</div>
                    <div className='wrappervalue'>
                      <div className='wrappervaluetext'>{userdetails.city}</div>
                      <div className='wrappericon'><img
                        className="lead-newsideview-svg-image"
                        src={cityimg}
                        alt="city"
                      /></div>

                    </div>
                    <hr class="hr" />
                  </div>

                  <div className='Leadwrappercontent'>
                    <div className='wrapperformlabel'>State</div>
                    <div className='wrappervalue'>
                      <div className='wrappervaluetext'>{userdetails.state}</div>
                      <div className='wrappericon'><img
                        className="lead-newsideview-svg-image"
                        src={stateimg}
                        alt="state"
                      /></div>

                    </div>
                    <hr class="hr" />
                  </div>

                  <div className='Leadwrappercontent'>
                    <div className='wrapperformlabel'>Country</div>
                    <div className='wrappervalue'>
                      <div className='wrappervaluetext'>{userdetails.country}</div>
                      <div className='wrappericon'><img
                        className="lead-newsideview-svg-image"
                        src={countryimg}
                        alt="country"
                      /></div>

                    </div>
                    <hr class="hr" />
                  </div>


                </div>

              </div>


            </div>
          </div>

          {(props.type == 'meeting' && AppPermission.meeting_score || props.type == 'lead' && AppPermission.lead_score || props.type == "ticket" && AppPermission.ticket_score || props.type == "voicemail" && AppPermission.voicemail_score || props.type == "contact" && AppPermission.contact_score) && score.length > 0 && <div className="lead-side-view-component-2">
            <div className="lead-side-view-2">
              {props.type !== "ticket" &&
                <>
                  <div className='leadstatus'>
                    <span className='leadstatustxt'>Inbound Call     </span><span className='leadstatustxt'>{score[0].call_type.INBOUND}</span>
                    <hr class="hr" />
                    <span className='leadstatustxt'>Outbound Call     </span><span className='leadstatustxt'>{score[0].call_type.OUTBOUND}</span>
                    <hr class="hr" />
                    <span className='leadstatustxt'> Missed Call     </span><span className='leadstatustxt'>{score[0].call_type.MISSED}</span>
                    <hr class="hr" />
                    <span className='leadstatustxt'>Whatsapp Summary     </span><span className='leadstatustxt'>{score[0].whatsapp.WHATSAPP}</span>
                    <hr class="hr" />

                    {Object.keys(score[0].top_3_status).length > 0 &&
                      <>
                        < >
                          <div className="dash" >
                            <div style={{ cursor: 'pointer' }} className="cardbody">
                              <div className="dash_head">Top 3 Disposition</div>

                              <div className='lead-ico-sec-rank-main'>
                                {Object.keys(score[0].top_3_status).length >= 1 && Object.keys(score[0].top_3_status)[0] !== "null" && <div className='lead-score-svg-star-content'>
                                  <div className='lead-score-svg-star-div'>
                                    <img
                                      className="lead-score-svg-star"
                                      src={star}
                                      alt="test"
                                    />
                                    <img
                                      className="lead-score-svg-star"
                                      src={star}
                                      alt="test"
                                    />
                                    <img
                                      className="lead-score-svg-star"
                                      src={star}
                                      alt="test"
                                    />
                                  </div>
                                  <div className='lead-ico-sec-rank'>{Object.keys(score[0].top_3_status)[0]} {score[0].top_3_status[`${Object.keys(score[0].top_3_status)[0]}`]}</div>
                                </div>}
                                {Object.keys(score[0].top_3_status)[1] !== "null" && <div className='lead-score-svg-star-content'>
                                  <div className='lead-score-svg-star-div'>
                                    <img
                                      className="lead-score-svg-star"
                                      src={star}
                                      alt="test"
                                    />
                                    <img
                                      className="lead-score-svg-star"
                                      src={star}
                                      alt="test"
                                    />

                                  </div>
                                  {Object.keys(score[0].top_3_status).length >= 2 && <div className='lead-ico-sec-rank'>{Object.keys(score[0].top_3_status)[1]} {score[0].top_3_status[`${Object.keys(score[0].top_3_status)[1]}`]}</div>}
                                  {Object.keys(score[0].top_3_status).length <= 2 && <div className='lead-ico-sec-rank'>N/A</div>}

                                </div>}
                                {Object.keys(score[0].top_3_status)[2] !== "null" && <div className='lead-score-svg-star-content'>
                                  <div className='lead-score-svg-star-div'>
                                    <img
                                      className="lead-score-svg-star"
                                      src={star}
                                      alt="test"
                                    />

                                  </div>

                                  {Object.keys(score[0].top_3_status).length >= 3 && <div className='lead-ico-sec-rank'>{Object.keys(score[0].top_3_status)[2]} {score[0].top_3_status[`${Object.keys(score[0].top_3_status)[2]}`]}</div>}
                                  {Object.keys(score[0].top_3_status).length <= 3 && <div className='lead-ico-sec-rank'>N/A</div>}

                                </div>}


                              </div>


                            </div>
                          </div>
                        </>
                      </>
                    }


                    <hr class="hr" />

                  </div>
                </>

              }

              {props.type === "ticket" && ticketscore.length > 0 &&
                <>
                  <div className='leadstatus'>
                    {ticketscore.map((value) => {

                      return (
                        <>
                          <span className='leadstatustxt'>{Format(value.status)}     </span><span className='leadstatustxt'>{value.count}</span>
                          <hr class="hr" />
                        </>
                      )
                    })}
                  </div>
                </>
                // < Carousel variant="dark" controls={false} interval={1000} indicators={false}>
                //   {ticketscore.map((value) => {

                //     return (
                //       <Carousel.Item>


                //         <div className="dash" >

                //           <div style={{ cursor: 'pointer' }} className="cardbody">

                //             <div className="dash_head">Ticket Summary</div>
                //             <div className='lead-ico-sec'>
                //               <div className={value.status === "open" ? "lead-score-svg bg-dred" : value.status === "pending" ? "lead-score-svg bg-orange" : value.status === "resolve" ? "lead-score-svg bg-cement" : value.status === "close" ? "lead-score-svg bg-wa" : value.status === "hold" ? "lead-score-svg bg-blue" : value.status === "inprogress" ? "lead-score-svg bg-pink" : value.status === "woc" ? "lead-score-svg bg-grey" : "lead-score-svg bg-violet"}>
                //                 <img
                //                   className="lead-score-svg-image"
                //                   src={value.status === "open" ? open : value.status === "pending" ? pending : value.status === "resolve" ? resolve : value.status === "close" ? closesvg : value.status === "hold" ? hold : value.status === "inprogress" ? inprogress : value.status === "woc" ? woc : overdue}
                //                   alt="test"
                //                 />
                //               </div>
                //               <p>{Format(value.status)}</p>
                //             </div>

                //             <h1 className={value.status === "open" ? " txt-dred" : value.status === "pending" ? " txt-orange" : value.status === "resolve" ? " txt-cement" : value.status === "close" ? " txt-wa" : value.status === "hold" ? " txt-blue" : value.status === "inprogress" ? " txt-pink" : value.status === "woc" ? " txt-grey" : " txt-violet"}>{value.count}</h1>
                //           </div>



                //         </div>

                //       </Carousel.Item>
                //     )

                //   })}



                // </Carousel>

              }
            </div>

          </div>}
        </div>
      )
      }
    </>
  )
}

export default LeadSideView
import * as actions from './CustomFilterTypes'

const initialState = {
    Filter_column_data: [],
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.SET_FILTER_DATA:
            return {
                ...state,
                Filter_column_data: action.payload.data
            }
       
        default:
            return state;
    }
}
export default reducer;
import React, {
  useState,
  useEffect
} from 'react'
import EventEmitter from '../../utils/EventEmitter'
import { useDispatch, useSelector } from 'react-redux'
import "../../assets/css/Callscreen.css"
import {
  fetchcallaction,
  autodial,
  autocalldial,
  AutoDialPlayParseBtn,
  autodialdatas, callmodule
} from '../../redux/Calls/CallAction'
import { useParams, useLocation } from 'react-router-dom'
import { callLeadDetail } from '../../redux/background/BackgroundAction'
function AutoDialTemplate(props) {
  const location = useLocation()
  let [autodialtimer, setautodialtimer] = useState()
  let [list, setlist] = useState(true)
  let webrtccallstatus = localStorage.getItem('webrtcManual')
  let dialer_interval = JSON.parse(localStorage.getItem('config')).data.projects[0].config[0].dialer_interval
  let [autodialtimerstatus, setautodialtimerstatus] = useState(false)
  let [count1, setcount1] = useState(0)
  let dispatch = useDispatch()
  let stateValue = useSelector((state) => state)
  useEffect(() => {
    event()
  }, [])

  useEffect(() => {
    var timeout = null
    var interval = null
    if (autodialtimerstatus && webrtccallstatus == "false" && stateValue.call.dispoValidation == true) {
      EventEmitter.emit('autodial-timmer', true)
      interval = setInterval(() => {
        setautodialtimer((seconds) => {
          let sec
          if (seconds !== 0) {
            sec = seconds - 1
          } else if (seconds === 0) {
            sec = 5
          }
          return sec
        })
      }, 1000)


      if (stateValue.call.autodialstatus) {
        if (count1 == 0) {

          if (stateValue.call.autodialdatas.length > 0) {
            setcount1(count1 + 1)
            timeout = setTimeout(() => {

              localStorage.setItem('webrtcManual', 'true')
              dispatch(autocalldial())
              dispatch(callmodule({
                id: stateValue.call.autodialdatas[0].Lead_id,
                module: "lead",
                phone_no: stateValue.call.autodialdatas[0].leadNumber,
                auto: true,
              }))
              let name = ""
              if (stateValue.call.autodialdatas[0].leadName === "N/A") {
                localStorage.setItem("dialpadname", stateValue.call.autodialdatas[0].leadNumber);

              } else {
                localStorage.setItem("dialpadname", stateValue.call.autodialdatas[0].leadName);
                name = stateValue.call.autodialdatas[0].leadName
              }
              let leadData = {
                lead_id: stateValue.call.autodialdatas[0].Lead_id,
                lead_name: name,
                phone_number: stateValue.call.autodialdatas[0].leadNumber,
                module: "lead",
                render: 1
              }
              dispatch(callLeadDetail(leadData))

            }, dialer_interval * 1000)
          } else {
            dispatch(autodial(false))
            clearInterval(interval)
          }

        } else {
          timeout = setTimeout(() => {
            localStorage.setItem('webrtcManual', 'true')
            dispatch(autocalldial())
            dispatch(callmodule({
              id: stateValue.call.autodialdatas[0].Lead_id,
              module: "lead",
              phone_no: stateValue.call.autodialdatas[0].leadNumber,
              auto: true,
            }))
            let name = ""
            if (stateValue.call.autodialdatas[0].leadName === "N/A") {
              localStorage.setItem("dialpadname", stateValue.call.autodialdatas[0].leadNumber);

            } else {
              localStorage.setItem("dialpadname", stateValue.call.autodialdatas[0].leadName);
              name = stateValue.call.autodialdatas[0].leadName
            }
            let leadData = {
              lead_id: stateValue.call.autodialdatas[0].Lead_id,
              lead_name: name,
              phone_number: stateValue.call.autodialdatas[0].leadNumber,
              module: "lead",
              render: 1
            }
            dispatch(callLeadDetail(leadData))

          }, autodialtimer * 1000)
        }
      }
    } else {
      EventEmitter.emit('autodial-timmer', false)
      clearTimeout(timeout)
      clearInterval(interval)
    }
    return () => {
      clearTimeout(timeout)
      clearInterval(interval)
    }
  }, [autodialtimerstatus])

  useEffect(() => {
    setautodialtimer(parseInt(localStorage.getItem('autodialtimer')))
    if (stateValue.call.autodialstatus) {

      if (stateValue.call.autodialplayparsebtn == true) {
        setautodialtimerstatus(true)
      } else {
        setautodialtimerstatus(false)
      }

    } else {
      setautodialtimerstatus(false)
    }
  }, [stateValue.call.dispoValidation])

  function event() {
    EventEmitter.addListener('autodialpause', (data) => {
      setautodialtimerstatus(data)
      dispatch(AutoDialPlayParseBtn())
    })
  }
  function startStopbtnact(data) {
    let readystate = localStorage.getItem("selectedPause")
    if (readystate == "available") {
      dispatch(AutoDialPlayParseBtn())
      setautodialtimerstatus(data)
    } else {
      EventEmitter.emit('error-message-getter', {
        action: 'ready',
        module: "Agent Not ready",
      })
    }
  }
  function resetautodial() {
    EventEmitter.emit('autodial-timmer', false)
    dispatch(autodial(false))
    setautodialtimerstatus(false)
    dispatch(autodialdatas([]))
    props.showlist(true)
  }
  function List(data) {
    setlist(data)
    props.showlist(data)
  }

  return (
    <>
      <div className='callscreen-autodial'>

        <p style={{ "margin": "2px 0px 0px 0px" }} className="callscreen-autodial-head autodial ">
          Auto Dial
        </p>
        {stateValue.call.autodialstatus && webrtccallstatus !== "true" && <p style={{ "margin": "2px 0px 0px 0px", "color": "#E43554" }} className="lead-count autodial">
          {autodialtimer}
        </p>}
        {stateValue.call.autodialstatus && webrtccallstatus !== "true" && localStorage.getItem("selectedPause") === "available" && <span
          onClick={() => { startStopbtnact(!stateValue.call.autodialplayparsebtn) }}
          className={
            stateValue.call.autodialplayparsebtn
              ? 'material-icons autodial lead-dial-button-green'
              : 'material-icons autodial  lead-dial-button-red'
          }
        >
          {stateValue.call.autodialplayparsebtn ? 'pause' : 'play_arrow'}
        </span>}
        {stateValue.call.autodialstatus && <span style={{ "color": "#2196f3" }}
          onClick={() => { resetautodial() }}
          className={'material-icons  autodial'}
        >
          restart_alt
        </span>}
        <span style={{ "color": "#2196f3" }}
          onClick={() => { List(!list) }}
          className={
            list === false
              ? 'material-icons autodial  autodial-list-bts'
              : 'material-icons  autodial'
          }
        >
          format_list_numbered
        </span>
      </div>
    </>
  )
}

export default AutoDialTemplate
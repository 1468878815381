import api from "../../utils/axios-util";
 
export const Message = (payload) => {
    return (dispatch, getState) => {
       return new Promise(async (resolve, reject) => {
           await api
               .post(`/message`,payload)
               .then((response) => {
                   resolve(response);
               })
               .catch((err) => {
                   reject(err);
               });
       });
   }
 
};
import React, { useState, useRef } from 'react'
import { useEffect } from 'react'
import ContactTable from '../components/Table'
import EventEmitter from '../utils/EventEmitter'
import { useDispatch, useSelector } from 'react-redux'
import { GetAsterCustommoduleData } from '../redux/actions/ContactActions'
import '../assets/css/Leads.css'
import InfiniteScroll from 'react-infinite-scroll-component'
import Format from '../utils/format-text'
import { useParams, useLocation } from 'react-router-dom'
import Filter from '../components/filter'
import { ModuleCustomformFields } from '../redux/actions/Customform'
import { filterCustommodule } from '../redux/actions/Asterleads'
import { useNavigate } from 'react-router'
function Contacts() {

    let dispatch = useDispatch()
    let navigate = useNavigate()
    let AppPermission
    let { module } = useParams()
    if (localStorage.getItem('config')) {
        AppPermission = JSON.parse(localStorage.getItem('config')).data.projects[0].userPermission
    }
    let module_data = JSON.parse(localStorage.getItem('module_data'))
    let [subfilter, setsubfilter] = useState(['new'])
    let [checked, setchecked] = useState(false)
    let [Filterfield, setFilterfield] = useState([])
    let [filtershow, setfiltershow] = useState(true)

    const [isupload, setisupload] = useState(false)

    const [infinitScroll, setinfinitScroll] = useState({ limit: 7, more: true })
    const [searchContactValue, setsearchContactValue] = useState("")
    const [contactDetails, setcontactDetails] = useState([])
    let [maskphoneindex, setmaskphoneindex] = useState({ index: 0, value: "" })
    const [skipdata, setskipdata] = useState({
        skip: 0,
        take: 20,

    })
    let [count, setcount] = useState(0)
    const [header, setheader] = useState([])
    let [loader,setloader]= useState(false)
    useEffect(() => {
        dispatch(ModuleCustomformFields(module)).then(
            (res) => {
                setisupload(res.data.data[0].isfileupload)
                mountedfunction("_",res.data.data[0].isfileupload)
                let filterfield = res.data.data[0].fields.filter((elm) => {
                    if (
                        elm.agent_show_on_filter == true &&
                        elm.fieldType !== 'Checkbox' &&
                        elm.fieldType !== 'DatePicker' &&
                        elm.fieldType !== 'TimePicker' &&
                        elm.fieldType !== 'DatetimePicker' &&
                        elm.fieldType !== 'RadioButton' &&
                        elm.fieldType !== 'LongTextInput' &&
                        elm.show_on_popup == true
                    ) {
                        if (
                            elm.fieldType == 'SelectList' &&
                            elm.link_type !== 'Child'
                        ) {
                            return elm
                        } else if (elm.fieldType == 'TextInput') {
                            return elm
                        }
                    }
                    if(elm.show_on_popup == true && elm.model !=="id"){
                        setheader((set)=>{
                            set.push({
                                Header: elm.label ,
                                accessor: elm.model,
                            })
                            return set
                        })
                    }
                })
                setFilterfield(filterfield)
            },
        ).catch((err) => {
            EventEmitter.emit('error-message-getter', {
                action: 'fetch',
                module: 'form fields',
            })
        })

        let index = header.findIndex((elm => elm.Header == "Phone Number"))
        if (index != -1) {
            setmaskphoneindex({ index: index, value: header[index].accessor })
        }

    }, [])

    function mountedfunction(action,isupload_) {
        let url
        if (action === "refresh") {
            setinfinitScroll((set) => {
                set.more = true
                return {
                    ...set,
                }
            })
            setsearchContactValue("")
            url = `?template_name=${module}&skip=${0}&take=${20}`
        }
        else if (searchContactValue !== "") {
            url = `?template_name=${module}&skip=${skipdata.skip}&take=${skipdata.take}&value=${searchContactValue}`
        }
        else {
            url = `?template_name=${module}&skip=${skipdata.skip}&take=${skipdata.take}`
        }

        let payload={
            url,
            bodydata:module_data
        }
        if(action !="Moredata"){
            setloader(true)
        }
        dispatch(GetAsterCustommoduleData(payload))
            .then((response) => {
                setloader(false)
                if (response.data.statusCode === 200) {
                    setcount(response.data.count)
                    setskipdata((set) => {
                        if (set.take === 20) {
                            set.skip = 20
                            set.take = 10
                        } else {
                            set.skip = set.skip + 10
                            set.take = 10
                        }

                        return {
                            ...set,
                        }
                    })
                    let element = response.data.data
                    let Headerarr = Object.keys(element[0])

                    let head_result = []
                    if (isupload_) {
                        head_result.push(
                            {
                                Header: "Profile",
                                accessor:'ImageUpload',
                            }
                        )
                    }
                    // for (let i = 0; i < Headerarr.length; i++) {
                    //     const element = Headerarr[i];
                    //     if (element !== "ImageUpload" && element !== "id") {
                    //         head_result.push(
                    //             {
                    //                 Header: element === "ImageUpload" ? "Profile" : element,
                    //                 accessor: element === "ImageUpload" ? "Profile" : element,
                    //             }
                    //         )
                    //     }

                    // }
                    // setheader(head_result)
                    let value = []
                    for (let i = 0; i < element.length; i++) {



                        value.push(element[i])
                    }

                    if (action === "refresh") {
                        setcontactDetails([].concat(value))
                    } else {
                        setcontactDetails(contactDetails.concat(value))
                    }

                } else {
                    setcontactDetails([])
                }
            })
            .catch((err) => {
                EventEmitter.emit('error-message-getter', {
                    action: 'fetch',
                    module: 'contact',
                })
            })
    }

    const filterdataapi = (data, isfilter) => {
        let arr = []
        for (let i = 0; i < Object.keys(data.dynamicfilter).length; i++) {
            const element = Object.keys(data.dynamicfilter)[i];
            let obj_ = {}
            if (Object.values(data.dynamicfilter)[i] !== "") {
                obj_["name"] = `${element}`
                obj_["value"] = Object.values(data.dynamicfilter)[i]
                arr.push(obj_)
            }

        }
        let obj = {
            template_name: module,
            filterValues: arr
        }
        dispatch(filterCustommodule(obj))
            .then((response) => {
                setfiltershow(false)
                // setcount(response.data.count)
                if (response.status === 200) {
                    setcount(response.data.data.length)
                    let element = response.data.data
                    let value = []
                    for (let i = 0; i < element.length; i++) {
                        value.push(element)
                    }
                    setcontactDetails([].concat(value[0]))
                } else {
                    setcontactDetails([])
                }
            })
    }


    let fetchMoreData = () => {
        if (contactDetails.length >= count) {
            setinfinitScroll((set) => {
                set.more = false
                return {
                    ...set,
                }
            })
        } else {
            setTimeout(() => {
                mountedfunction("Moredata")
            }, 1000)
        }
    }



    const refresh = () => {
        mountedfunction('refresh')
        setskipdata((set) => {
            set.skip = 0
            set.take = 20
            return {
                ...set,
            }
        })
        setinfinitScroll((set) => {
            set.more = true
            return {
                ...set,
            }
        })
        setfiltershow(true)
    }
    return (
        <div className="page-main-container">
            <div className="scroll-lft" md={8}>
                <div className="lead-header">
                    <div className="lead-header1">
                        <div className="route-header lead-header1-lft"> {Format(module)}</div>
                    </div>
                    <div className="lead-header2">
                        <div onClick={()=>{
                             navigate(localStorage.getItem("module_back"))
                        }} className=" font_ lead-save-icon lead-create-head-rht ">Back</div>
                        {AppPermission.contact_refresh && <div
                            onClick={refresh}
                            className=" lead-save-icon lead-create-head-rht material-icons"
                        >
                            refresh
                        </div>}
                        {Filterfield.length > 0 && filtershow == true &&
                            <>
                                <Filter
                                    Filterfield={Filterfield}
                                    type={'custommodule'}
                                    filterdataapi={filterdataapi}
                                />
                            </>
                        }
                    </div>
                </div>
                <div className="lead-body">
                    <div
                        id="ticketDiv"
                        style={{
                            height: '100%',
                            overflow: 'auto',
                            color: 'grey',
                            textAlign: 'center',
                            width: '100%',
                        }}
                    >
                        <InfiniteScroll
                            dataLength={contactDetails.length}
                            next={fetchMoreData}
                            hasMore={infinitScroll.more}
                            scrollableTarget="ticketDiv"
                        >
                            {header.length > 0 && <ContactTable
                                loader={loader}
                                checkbox={false}
                                header={header}
                                leaddetail={contactDetails}
                                type="lead"
                                maskphoneindex={maskphoneindex}
                            />}
                        </InfiniteScroll>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contacts
import * as actions from './Chat-notification-Types';
import api from "../../utils/axios-util";


export const chatactive = data => {

    return {
        type: actions.CHAT_ACTIVE_SCREEN,
        payload: data
    }
}


export const getnotification = (data) => {
    return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
            await api.get(`/fetch/notification?agent=${data.agent}&skip=${data.skip}&take=${data.take}`)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
}




export const update_read_unread = (payload) => {
    return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
            payload["agent"] =  localStorage.getItem('user')
            await api
                .post(`/notification/delete`,payload) 
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
};



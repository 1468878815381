import * as actions from "./DetailTypes";

const initialState = {
  campaign: JSON.parse(localStorage.getItem("campaigns")),
  user: localStorage.getItem("user"),
  selected_campaign: localStorage.getItem("selectedCampaign"),
  pause_codes: JSON.parse(localStorage.getItem("pauseCode")),
  queues: JSON.parse(localStorage.getItem("queues")),
  selected_queue: localStorage.getItem("selectedQueue"),
  selected_pause: localStorage.getItem("selectedPause"),
  login_time:
    localStorage.getItem("loginTime") === null
      ? 0
      : localStorage.getItem("loginTime"),
  pause_time:
    localStorage.getItem("pauseTime") === null
      ? 0
      : localStorage.getItem("pauseTime"),
  did: JSON.parse(localStorage.getItem("did")),
  otherAgent: [],
 
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_CAMPAIGN:
      return {
        ...state,
        campaign: action.payload
      };

    case actions.SET_USER:
      return {
        ...state,
        user: action.payload
      };
    case actions.SET_TYPE:
      return {
        ...state,
        selected_campaign: action.payload
      };
    case actions.SET_PHONE:
      return {
        ...state,
        phone_number: action.payload
      };
    case actions.SET_OPTIONS:
      return {
        ...state,
        pause_codes: action.payload.pauseCode,
        queues: action.payload.queues
      };
    case actions.SET_QUEUE:
      return {
        ...state,
        selected_queue: action.payload
      };
    case actions.SET_PAUSE:
      return {
        ...state,
        selected_pause: action.payload
      };
    case actions.LOGIN_TIME:
      return {
        ...state,
        login_time: action.payload
      };
    case actions.PAUSE_TIME:
      return {
        ...state,
        pause_time: action.payload
      };
    case actions.DID:
      return {
        ...state,
        did: action.payload
      };
    case actions.AGENTSTATUS:
      return {
        ...state,
        otherAgent: action.payload
      };
   
    default:
      return state;
  }
};
export default reducer;

import * as actions from './SocketTypes';

const initialState = {
    stop_event :0
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.INITIATE_EVENT:
            let count = 0
            if(action.payload){
                count = state.stop_event + 1
            }else{
                count = 0
            }
            return {
                ...state,
                stop_event : count
            }
        default:
            return state;
    }
}
export default reducer;
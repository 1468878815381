import React, {
  useEffect,
  useState
} from 'react'
import EventEmitter from '../utils/EventEmitter'
import { useDispatch, useSelector } from 'react-redux'
import Timer from './Timepicker'
import Form from 'react-bootstrap/Form'
import DatePicker from 'react-datepicker'
import Dialog from '@material-ui/core/Dialog'
import 'react-datepicker/dist/react-datepicker.css'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Select from 'react-select'
import '../assets/css/dialog.css'
import formate from '../utils/format-text'
import { TicketFetch } from '../redux/actions/TicketAction'
import AsyncSelect from 'react-select/async';
import Regex_ from '../utils/regex'
import Format from '../utils/format-text'
import { PopupRouter } from '../redux/actions/Customform'
import { CustomformData } from '../redux/actions/Customform'
import { ContactFetch } from '../redux/actions/ContactActions'
import { queueLogin, queueLogout, logoutaction,forcelogout } from '../redux/SocketAction/SocketAction'
import moment from 'moment'
function Dialogbox(props) {
    let dispatch = useDispatch()
  let session_state = useSelector((state) => state)
  let agent = localStorage.getItem('user')
  const fieldType = props.value.field
  const [fieldss, setfield] = useState(props.value)
  const [formdata, setformdata] = useState({})
  const [startDate, setStartDate] = useState(new Date())
  const [validate, setvalidate] = useState(false)
  const [shedulevalidate, setshedulevalidate] = useState(false)
  const [regexvalidation, setregexvalidation] = useState({});
  const [textcount, settextcount] = useState({})
  const [module, setmodule] = useState([])
  const [validateregex_button, setvalidateregex_button] = useState(true)
  const [schedulevalidate, setschedulevalidate] = useState(false)
  const [timeError, setTimeError] = useState(null);
  const [dateError, setDateError] = useState(null);
  useEffect(() => {

    if (props.SelectedState !== undefined) {
      setfield((set) => {
        set.field[6].option = props.SelectedState
        return {
          ...set,
        }
      })
    }
  }, [props.SelectedState])
  useEffect(() => {
    if (props.shedulealart) {
      setshedulevalidate(true)
    } else {
      setshedulevalidate(false)
    }
  }, [props.shedulealart])
  useEffect(() => {
    if (props.SelectedCity !== undefined) {
      setfield((set) => {
        set.field[7].option = props.SelectedCity
        return {
          ...set,
        }
      })
    }
  }, [props.SelectedCity])

  useEffect(() => {
    let field = props.value.field
    let obj = {}
    let validateobj = {}
    let txtcount = {}
    for (let i = 0; i < field.length; i++) {
      const element = field[i]
      validateobj[`${element.model}`] = false
      txtcount[`${element.model}`] = 255
      obj[`${element.model}`] = element.value
      if(element.model==="meetingphoneNumber" &&element.value!=="" ){
        handleChangedata(element.model, {value:element.value}, element)
      }
    }
    setmodule([])
    // handleChangedata(val, data, totalvalue)
    setformdata(obj)
  }, [props.value])

  const finaldata = (data, action) => {
    if (action === 'create') {
      
      let count = 0
      for (let i = 0; i < fieldss.field.length; i++) {
        const element = fieldss.field[i]
        if (element.required === true) {
          let value = formdata[`${element.model}`]
          if (value === '') {
            count += 1
          }
        }
      }
      if (count > 0) {
        setvalidate(true)
      } else if(validateregex_button) {
        fieldss['open'] = false
        fieldss['action'] = action
        setvalidateregex_button(true)
        fieldss.data = [formdata]
        props.fields(fieldss)
        setvalidate(false)
      }
    } else {
      fieldss['open'] = false
      fieldss['action'] = action
      fieldss.data = [formdata]
      setvalidate(false)
      props.fields(fieldss)
      if(localStorage.getItem('processing_call')==='force'){
        let array=[
          {
           action:'CTILogout',
           agent:localStorage.getItem('user'),
           campaign:localStorage.getItem('selectedCampaign'),
           station:parseInt(localStorage.getItem('extension')),
           tenant_id:JSON.parse(localStorage.getItem('apinfo')).tenant_code
          },
          {
            action:'user-logout',
            agent:localStorage.getItem('user'),
            station:parseInt(localStorage.getItem('extension')),
            tenant_id:JSON.parse(localStorage.getItem('apinfo')).tenant_code
          },
          {
            action:'QueueLogout', 
            agent:localStorage.getItem('user'),
            campaign:localStorage.getItem('selectedCampaign'),
            current_queue:[],
            noqueuelogin:true,
            station:parseInt(localStorage.getItem('extension')),
            queue:JSON.parse(localStorage.getItem('selectedQueue')),
            tenant_id:JSON.parse(localStorage.getItem('apinfo')).tenant_code
          }
        ]
        localStorage.removeItem('processing_call')
        dispatch(
          forcelogout(session_state.background.websocketconnection, array),
        )
      }else if(localStorage.getItem('processing_call')==='true'){
        localStorage.removeItem('processing_call')
      }
    }
  }
  function handleChangedata(val, data, totalvalue) {
console.log(val, data, totalvalue,"val, data, totalvalueval, data, totalvalueval, data, totalvalue")
    if (val.type == 'SelectList' || val.type === 'SearchSelectList') {

      if ((val.type == 'SelectList' || val.type === 'SearchSelectList') && val.multiple == true) {
        if (Array.isArray(data)) {
          let mergeselect_vals = data.map((elm) => elm.value).toString()
          setformdata((set) => {
            set[`${val.model}`] = mergeselect_vals
            return { ...set }
          })
        } else {
          setformdata((set) => {
            set[`${val.model}`] = data.value
            return { ...set }
          })
        }
      } else {
        if (val.model === 'selected_country') {
          props.dialogonchange(data, 'selected_country')
          setformdata((set) => {
            set[`${val.model}`] = data.label
            return { ...set }
          })
        } else if (val.model === 'selected_state') {
          props.dialogonchange(data, 'selected_state')
          setformdata((set) => {
            set[`${val.model}`] = data.label
            return { ...set }
          })
        } else if (val.model === 'selected_city') {
          props.dialogonchange(data, 'selected_city')
          setformdata((set) => {
            set[`${val.model}`] = data.label
            return { ...set }
          })
        } else if (val.model === 'merge_status') {
          props.dialogonchange(data, 'selected_city')
          setformdata((set) => {
            props.dialogonchange()
            set[`${val.model}`] = data.value
            return { ...set }
          })
        }


        else {
          setformdata((set) => {
            if(val.model==="module"){
              set[`id`] = data.id
            }
            set[`${val.model}`] = data.value
            return { ...set }
          })
          if(val.label==='Meeting Title' && data.value === 'others'){
            props.isshow(true, data.value)
            }else if(val.label==='Meeting Title' && data.value !== 'others'){
            props.isshow(false, data.value)
          }
        }
      }
    } else if (val.type == 'Toggle') {
      formdata[val.model] = data.checked
      setformdata(formdata)
    } else {
      if (totalvalue !== undefined) {
        if (totalvalue.type === "LongTextInput") {
          let count = data.value.split("").length
          if (255 - count <= 255 && 255 - count >= 0) {
            settextcount((set) => {
              set[`${totalvalue.model}`] = 255 - count
              return {
                ...set
              }
            })
            setformdata((set) => {
              set[`${val}`] = data.value
              return { ...set }
            })
          }
        }
        else if (totalvalue.type === "TextInput") {
          // if (totalvalue.model === "meetingphoneNumber") {
          //   let arr = []
          //   let campaigninfo = JSON.parse(localStorage.getItem("selectedCampaign_data"))
          //   let payload = {
          //     template_name: campaigninfo.template_name,
          //     phone_number: data.value,
          //     module: 'leads'
          //   }

          //   dispatch(PopupRouter(payload)).then((res) => {
          //     if (res.data.ids.length > 0) {
          //       arr.push({
          //         label: "lead", value: "lead", id: res.data.ids[0].lead_id
          //       })
          //     } else {
          //       const removeitem = arr.filter((item) => item.value !== "lead");
          //       setmodule(removeitem)
          //     }
          //     payload.module = "meetings"
          //     dispatch(PopupRouter(payload)).then((res) => {
          //       payload.module = "tickets"
          //       if (res.data.ids.length > 0) {
          //         const removeitem = arr.filter((item) => item.value !== "contact");
          //         setmodule(removeitem)
          //       } else {

          //         if (res.data.contact_id.length > 0) {
          //           arr.push({
          //             label: "contact", value: "contact", id: res.data.contact_id[0].id
          //           })
          //         } else {
          //           const removeitem = arr.filter((item) => item.value !== "contact");
          //           setmodule(removeitem)
          //         }

          //       }
          //       dispatch(PopupRouter(payload)).then((res) => {
          //         if (res.data.ids.length > 0) {
          //           arr.push({
          //             label: "ticket", value: "ticket", id: res.data.ids[0].ticket_id
          //           })
          //           setmodule(arr)
          //         } else {
          //           const removeitem = arr.filter((item) => item.value !== "ticket");
          //           setmodule(removeitem)
          //         }
          //       }).catch((err) => {
          //         EventEmitter.emit('error-message-getter', {
          //           action: 'fetch',
          //           module: 'popup router',
          //         })
          //       })
          //     }).catch((err) => {
          //       EventEmitter.emit('error-message-getter', {
          //         action: 'fetch',
          //         module: 'popup router',
          //       })
          //     })
          //   }).catch((err) => {
          //     EventEmitter.emit('error-message-getter', {
          //       action: 'fetch',
          //       module: 'popup router',
          //     })
          //   })
          // }

          if (totalvalue.model === "meetingphoneNumber" && data.value !=="") {
            let arr = []
            let campaigninfo = JSON.parse(localStorage.getItem("selectedCampaign_data"))
            let payload = {
              template_name: campaigninfo.template_name,
              type: campaigninfo.type,
              phone_number: data.value,
              id: ''
            }

            dispatch(CustomformData(payload)).then((res) => {
              if (res.status != 204) {
                arr.push({
                  label: "lead", value: "lead", id: res.data.data[0].others.lead_id
                })
              } else {
                const removeitem = arr.filter((item) => item.value !== "lead");
                setmodule(removeitem)
              }

              dispatch(
                ContactFetch(
                  `/contact/fetchsearch?number=${data.value}`,
                ),
              ).then((res) => {
                const s = res.data.data
                if (s.length > 0) {
                  arr.push({
                    label: "contact", value: "contact", id: s[0].id
                  })
                } else {
                  const removeitem = arr.filter((item) => item.value !== "contact");
                  setmodule(removeitem)
                }
                dispatch(PopupRouter({
                  phone_number: data.value,
                  module: 'tickets'
                }
                )).then((res) => {
                  if (res.data.ids.length > 0) {
                    arr.push({
                      label: "ticket", value: "ticket", id: res.data.ids[0].ticket_id
                    })
                    setmodule(arr)
                  } else {
                    const removeitem = arr.filter((item) => item.value !== "ticket");
                    setmodule(removeitem)
                  }
                
                }).catch((err) => {
                  EventEmitter.emit('error-message-getter', {
                    action: 'fetch',
                    module: 'popup router',
                  })
                })
              }).catch((err) => {
                EventEmitter.emit('error-message-getter', {
                  action: 'fetch',
                  module: 'contact',
                })
              })
            }).catch((err) => {
              EventEmitter.emit('error-message-getter', {
                action: 'fetch',
                module: 'leads',
              })
            })
          }
          let val_ = Regex_(totalvalue.fieldtype)
          let otherval = false
          if (totalvalue.fieldtype === "others") {
            if (totalvalue.model_type === "SmallNumber" || totalvalue.model_type === "Number" || totalvalue.model_type === "BigNumber") {
              val_ = Regex_("number")
            } else {
              otherval = true
            }
          }


          if (data.value === "" || (totalvalue.fieldtype === "others" && otherval)) {

            regexvalidation[`${totalvalue.model}`] = false
            setregexvalidation((set) => {
              set[`${totalvalue.model}`] = false
              return {
                ...set
              }
            })
            setvalidateregex_button(Object.values(regexvalidation).every((condition) =>
              condition === false
            ))
            setformdata((set) => {
              set[`${val}`] = data.value
              return { ...set }
            })
          }
          else if (!val_.test(data.value)) {
            regexvalidation[`${totalvalue.model}`] = true
            setregexvalidation((set) => {
              set[`${totalvalue.model}`] = true
              return {
                ...set
              }
            })
            setvalidateregex_button(Object.values(regexvalidation).every((condition) =>
              condition === false
            ))
            setformdata((set) => {
              set[`${val}`] = data.value
              return { ...set }
            })
          }
          else {
            regexvalidation[`${totalvalue.model}`] = false
            setregexvalidation((set) => {
              set[`${totalvalue.model}`] = false
              return {
                ...set
              }
            })
            setvalidateregex_button(Object.values(regexvalidation).every((condition) =>
              condition === false
            ))
            setformdata((set) => {
              set[`${val}`] = data.value
              return { ...set }
            })
          }
        }


        else {

          setformdata((set) => {
            set[`${val}`] = data.value
            return { ...set }
          })
        }
      } else {

        setformdata((set) => {
          set[`${val}`] = data.value
          return { ...set }
        })
      }


    }
    setshedulevalidate(false)
  }

  function objectConverter(val) {
    const arr = val.option
    let default_value = []
    let option_arr = []
    for (let i = 0; i < arr.length; i++) {
      const element = arr[i]
      let obj = {}
      let default_val = {}
      obj['value'] = element.value
      obj['label'] = element.label
      option_arr.push(obj)
      if (formdata[`${val.model}`] === element.value) {
        default_val['value'] = element.value
        default_val['label'] = element.label
        default_value.push(default_val)
      }
    }

    return (
      <>
        <Select
          isMulti={val.multiple}
          menuPlacement={`auto`}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          menuPortalTarget={document.body}
          menuShouldScrollIntoView={false}
          value={SelectedGetData(val)}
          onChange={(e) => handleChangedata(val, e)}
          options={val.model === "module" ? module : option_arr}
          isSearchable={true && val.readonly ? false : true}
          menuIsOpen={val.readonly ? false : undefined}
          placeholder={formate(val.placeholder)}
        />
      </>
    )
  }

  let DateGetData = (type, model) => {
    if (type == 'DatePicker') {
      if (formdata[model] == null || formdata[model] == '') {
        return formdata[model]
      } else {
        if (formdata[model] == '0000-00-00 00:00:00') {
          return null;
        }
        else if (formdata[model] == '0000-00-00') {
          return null;
        } else {
          return new Date(formdata[model])
        }

      }
    } else if (type == 'TimePicker') {
      return formdata[model]
    }
  }

  function setuserdate(val, fieldata) {
    const today = val
    const yyyy = today.getFullYear()
    let mm = today.getMonth() + 1
    let dd = today.getDate()

    if (dd < 10) dd = '0' + dd
    if (mm < 10) mm = '0' + mm

    const formattedToday = yyyy + '-' + mm + '-' + dd

    handleChangedata(fieldata.model, { value: formattedToday })
  }

  const DateTimeGetData = (datetime_return, model) => {

    if (datetime_return) {
        if (!formdata[model] || formdata[model] === '' || formdata[model] === '0000-00-00 00:00:00') {
            return null;
        } else {
            const dateTime = moment(formdata[model], 'YYYY-MM-DD HH:mm:ss');
            if (!dateTime.isValid()) {
                console.log("DateTimeGetData: Invalid date format");
                return null;
            } else {
                const formattedTime = dateTime.format('HH:mm');
                return formattedTime;
            }
        }
    } else {
        if (!formdata[model] || formdata[model] === '' || formdata[model] === '0000-00-00 00:00:00') {
            return null;
        } else {
            const dateTime = moment(formdata[model], 'YYYY-MM-DD HH:mm:ss');
            if (!dateTime.isValid()) {
                console.log("DateTimeGetData: Invalid date format");
                return startDate //null;
            } else {
                return dateTime.toDate();
            }
        }
    }
  };
  const onDateTimeChanges = (datetime_return, data_val, fieldval) => {

    if (datetime_return) {
      let datetime = new Date()
      datetime.setHours(parseInt(data_val.hour), parseInt(data_val.minute), 0)

      if (formdata[fieldval.model] == '' || formdata[fieldval.model] == null) {
        handleChangedata(fieldval.model, { value: datetime.toISOString() })
      } else {
        let updatetime = new Date(formdata[fieldval.model])
        updatetime.setHours(
          parseInt(data_val.hour),
          parseInt(data_val.minute),
          0,
        )
        handleChangedata(fieldval.model, { value: updatetime.toISOString() })
      }
      const currentTime = new Date();
      currentTime.setMinutes(currentTime.getMinutes() + 35);
      if (datetime < currentTime) {
        setTimeError("Set the schedule time 35 minutes later.");
        setschedulevalidate(true)
      } else {
        setTimeError(null);
        setschedulevalidate(false)
      }
    } else {
      const yyyy = data_val.getFullYear()
      let mm = data_val.getMonth()
      let dd = data_val.getDate()

      if (dd < 10) dd = '0' + dd
      if (mm < 10) mm = '0' + mm

      const formattedToday = yyyy + '-' + mm + '-' + dd
      if (formdata[fieldval.model] == '' || formdata[fieldval.model] == null) {
        handleChangedata(fieldval.model, {
          value: new Date(formattedToday).toISOString(),
        })
      } else {
        let updatetime = new Date(formdata[fieldval.model])
        updatetime.setFullYear(yyyy, mm, dd)
        handleChangedata(fieldval.model, { value: updatetime.toISOString() })
      }
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      if (data_val < today) {
        setDateError("Pick a date from today or later.");
        setschedulevalidate(true)
      } else {
        setDateError(null);
        setschedulevalidate(false)
      }
    }
  }

  let SelectedGetData = (val) => {
    let select_options = val.option
    if (val.model === "module") {
      select_options = module
    }
    if (val.multiple != true) {
      if (
        formdata[`${val.model}`] === '' ||
        formdata[`${val.model}`] === null
      ) {
        return null
      } else {
        if (val.model == 'selected_country') {
          let res_val = select_options.find(
            (elm) => elm.label == formdata[`${val.model}`],
          )
          if (res_val == undefined) {
            return null
          } else {
            return {
              value: res_val.value,
              label: res_val.label,
            }
          }
        } else if (val.model == 'selected_state') {
          if (select_options.length > 0) {
            let res_val = select_options.find(
              (elm) => elm.label == formdata[`${val.model}`],
            )
            if (res_val == undefined) {
              return null
            } else {
              return {
                value: res_val.value,
                label: res_val.label,
              }
            }
          }
        } else if (val.model == 'selected_city') {
          let res_val = select_options.find(
            (elm) => elm.label == formdata[`${val.model}`],
          )
          if (res_val == undefined) {
            return null
          } else {
            return {
              value: res_val.label,
              label: res_val.label,
            }
          }
        } else {
          let res_val = select_options.find(
            (elm) => elm.value == formdata[`${val.model}`],
          )

          if (res_val == undefined) {
            return null
          } else {
            return {
              value: res_val.value,
              label: res_val.label,
            }
          }
        }
      }
    } else {
      if (
        formdata[`${val.model}`] !== '' &&
        formdata &&
        Object.keys(formdata).length > 0
      ) {
        let final_res
        if (formdata[`${val.model}`] !== '') {
          let splited_data = formdata[`${val.model}`].split(',')

          final_res = splited_data.map((elem) => {
            let res_val = select_options.find((elm) => elm.value == elem)
            return {
              value: res_val.value,
              label: res_val.label,
            }
          })
        } else {
          final_res = []
        }

        return final_res
      }
    }
  }
  const onTimeChanges = (val, fieldata) => {
    let { hour, minute, meridiem } = val
    let timevalue = `${hour}:${minute}`
    handleChangedata(fieldata.model, { value: timevalue })
  }

  function timer(data) {
    let val = data.val
    let datetime = data.datetime
    delete data.datetime
    delete data.val
    if (datetime) {
      onDateTimeChanges(true, data, val)
    } else {
      onTimeChanges(data, val)
    }
  }


  const promiseOptions = (inputValue) =>

    new Promise((resolve) => {
      setTimeout(() => {
        let url = `/tickets/fetch?portal=false&All=false&skip=${0}&take=${10}&user=${agent}&search=${inputValue}&merged_as=parent&selectbox=${fieldType[0].api_value}`
        dispatch(TicketFetch(url))
          .then((response) => {
            if (response.status === 200) {
              let values = response.data.data[0].others
              let arr = []
              for (let i = 0; i < values.length; i++) {
                const element = values[i];
                arr.push({ label: element.ticket_id, value: element.ticket_id })

              }

              resolve(arr);
            } else {

              resolve([]);
            }
          })
          .catch((err) => {
            EventEmitter.emit('error-message-getter', {
              action: 'fetch',
              module: 'ticket',
            })
          })
      }, 500);
    });


  function logout() {
    let logout_action = {
      tenant_id: JSON.parse(localStorage.getItem('config')).data.tenant_code,
      agent: localStorage.getItem('user'),
      station: localStorage.getItem('extension'),
      campaign: localStorage.getItem('selectedCampaign'),
      noqueuelogin: true
    }
    dispatch(logoutaction(session_state.background.websocketconnection, logout_action))
  }
  return (
    <div>
      <Dialog
        open={
          props.value.open
        }
        fullWidth={true}
      >
        <DialogTitle className="dialog-header">
          <div className='dialog-header-flex'>
            <div className='dialog-header-flex-lft'>
              {formate(props.value.title)}
            </div>
            <div className='dialog-header-flex-rht'>
              {props.value.topbutton === true &&
                <span class="material-icons" onClick={logout}>
                  logout
                </span>
              }
            </div>
          </div>

        </DialogTitle>
        <DialogContent className="dialog-content">
          <DialogContentText className={props.defaultclass}>
            {props.value.Type == 'Notification_bar' && (
              <>{formate(props.value.Message)}</>
            )}
            {props.value.Type == 'form_bar' && (
              <>
                {fieldType.length === 0 && <>{formate(props.value.Message)}</>}
                {fieldType.map((res, i) => {
                  return (
                    <>
                      {res.type == 'SelectList' && (res.show == true || res.show == undefined) && (
                        <>
                          <div className="Customform-field-cnt">
                            <label
                              className={
                                res.required
                                  ? 'label_cls required'
                                  : 'label_cls'
                              }
                            >
                              {formate(res.label)}{' '}
                            </label>
                            {objectConverter(res)}
                            {res.required &&
                              formdata[`${res.model}`] == '' &&
                              validate && (
                                <span className="require_class">
                                  Please fill required fields
                                </span>
                              )}
                          </div>
                        </>
                      )}
                      {res.type == 'SearchSelectList' && (
                        <>
                          <div className="Customform-field-cnt">
                            <label
                              className={
                                res.required
                                  ? 'label_cls required'
                                  : 'label_cls'
                              }
                            >
                              {formate(res.label)}{' '}
                            </label>
                            <AsyncSelect menuPlacement={`auto`}
                              onChange={(e) => handleChangedata(res, e)}
                              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                              menuPortalTarget={document.body}
                              menuShouldScrollIntoView={false} isMulti={res.multiple} cacheOptions loadOptions={promiseOptions} defaultOptions />
                            {res.required &&
                              formdata[`${res.model}`] == '' &&
                              validate && (
                                <span className="require_class">
                                  Please fill required fields
                                </span>
                              )}
                          </div>
                        </>
                      )}
                      {res.type == 'TextInput' && (res.show===true || res.show === undefined)&&(
                        <>
                          <div className="Customform-field-cnt">
                            <label
                              className={
                                res.required
                                  ? 'label_cls required'
                                  : 'label_cls'
                              }
                            >
                              {formate(res.label)}
                            </label>
                            <Form.Control
                              onChange={(e) =>
                                handleChangedata(res.model, {
                                  value: e.target.value,
                                }, res)
                              }
                              value={formdata[`${res.model}`]}
                              readOnly={res.readonly}
                              className="form-control"
                              placeholder={formate(res.placeholder)}
                            />
                             {(
    (regexvalidation[res.model] || (res.fieldtype === "phone_number" && formdata[res.model] === 0)) ||
    (res.required && formdata[res.model] === '' && validate)
) && (
    <span className="require_class">
        Please Enter Valid {Format(res.fieldtype)}
    </span>
)}

                          </div>
                        </>
                      )}

                      {res.type == 'LongTextInput' && (
                        <>
                          <div className="Customform-field-cnt">
                            <label
                              className={
                                res.required
                                  ? 'label_cls required'
                                  : 'label_cls'
                              }
                            >
                              {formate(res.label)}
                            </label>
                            <Form.Control
                              onChange={(e) =>
                                handleChangedata(res.model, {
                                  value: e.target.value,
                                }, res)
                              }
                              value={formdata[`${res.model}`]}
                              as="textarea"
                              placeholder={formate(res.placeholder)}
                            />
                            {res.required &&
                              formdata[`${res.model}`] == '' &&
                              validate && (
                                <span className="require_class">
                                  Please fill required fields
                                </span>
                              )}
                            {textcount[`${res.model}`] === 0 && (
                              <span className="require_class">
                                Max Characters 255 Reached
                              </span>
                            )}
                          </div>
                        </>
                      )}
                      {res.type == 'Toggle' && (
                        <>
                          <div className="Customform-field-cnt">
                            <label
                              className={
                                res.required
                                  ? 'label_cls required'
                                  : 'label_cls'
                              }
                            >
                              {formate(res.label)}
                            </label>
                            <Form.Check
                              type="switch"
                              id="custom-switch"
                              checked={formdata[res.model] === 'true'}
                              onChange={(e) => {
                                setformdata((set) => {
                                  props.dialogonchange(e.target.checked.toString())
                                  set[res.model] = e.target.checked.toString()
                                  return {
                                    ...set,
                                  }
                                })
                              }}
                            />
                            {res.required &&
                              formdata[`${res.model}`] == '' &&
                              validate && (
                                <span className="require_class">
                                  Please fill required fields
                                </span>
                              )}
                          </div>
                        </>
                      )}
                      {res.type == 'Checkbox' && (
                        <>
                          <div className="Customform-field-cnt">
                            <label
                              className={
                                res.required
                                  ? 'label_cls required'
                                  : 'label_cls'
                              }
                            >
                              {formate(res.label)}{' '}
                            </label>
                            <Form.Check
                              value={res.value}
                              type="checkbox"
                              checked={formdata[res.model] === 'true'}
                              onChange={(e) => {
                                setformdata((set) => {
                                  set[res.model] = e.target.checked.toString()
                                  return {
                                    ...set,
                                  }
                                })
                              }}
                            />
                            {res.required &&
                              formdata[`${res.model}`] == '' &&
                              validate && (
                                <span className="require_class">
                                  Please fill required fields
                                </span>
                              )}
                          </div>
                        </>
                      )}

                      {res.type == 'DatePicker' && (
                        <>
                          <div className="Customform-field-cnt">
                            <label
                              className={
                                res.required
                                  ? 'label_cls required'
                                  : 'label_cls'
                              }
                            >
                              {formate(res.label)}
                            </label>
                            <DatePicker
                              portalId="root-portal"
                              dateFormat="dd/MM/yyyy"
                              minDate={res.allow_previousdate?new Date():false}
                              selected={DateGetData(res.type, res.model)}
                              onChange={(date_value) =>
                                setuserdate(date_value, res)
                              }
                              placeholderText={formate(res.placeholder)}
                              className="form-control"
                            />
                            {res.required &&
                              formdata[`${res.model}`] == '' &&
                              validate && (
                                <span className="require_class">
                                  Please fill required fields
                                </span>
                              )}
                          </div>
                        </>
                      )}
                      {res.type == 'TimePicker' && (
                        <>
                          <div className="Customform-field-cnt">
                            <label
                              className={
                                res.required
                                  ? 'label_cls required'
                                  : 'label_cls'
                              }
                            >
                              {formate(res.label)} {` (HH:MM)`}
                            </label>
                            <Timer
                              currentvalue={DateGetData(
                                res.fieldType,
                                res.model,
                              )}
                              valuegetter={res}
                              datetime={false}
                              minutefinaldata={timer}
                              hoursfinaldata={timer}
                            ></Timer>
                            {res.required &&
                              formdata[`${res.model}`] == '' &&
                              validate && (
                                <span className="require_class">
                                  Please fill required fields
                                </span>
                              )}
                          </div>
                        </>
                      )}
                      {res.type == 'DatetimePicker' && (
                        <>
                          <div className="Customform-field-cnt">
                            <label
                              className={
                                res.required
                                  ? 'label_cls required'
                                  : 'label_cls'
                              }
                            >
                              {formate(res.label)} {`(HH:MM DD/MM/YYYY)`}
                            </label>
                            <div className="DatetimePicker">
                              <div className="Datetime_container">
                                <Timer
                                  currentvalue={DateTimeGetData(
                                    true,
                                    res.model,
                                  )}
                                  disabled={res.disabled}
                                  readOnly={res.readonly}
                                  valuegetter={res}
                                  datetime={true}
                                  minutefinaldata={timer}
                                  hoursfinaldata={timer}
                                ></Timer>
                              </div>
                              <div className="Datetime_container">
                                <DatePicker
                                  portalId="root-portal"
                                  minDate={res.allow_previousdate?new Date():false}
                                  dateFormat="dd/MM/yyyy"
                                  selected={DateTimeGetData(false, res.model)}
                                  onChange={(date_value) =>
                                    onDateTimeChanges(false, date_value, res)
                                  }
                                  className="form-control"
                                />
                              </div>
                            </div>
                            {res.required &&
                              formdata[`${res.model}`] == '' &&
                              validate && (
                                <span className="require_class">
                                  Please fill required fields
                                </span>
                              )}
                            {shedulevalidate &&
                              (
                                <span className="require_class">
                                  Schedule date must 35 minute after
                                </span>
                              )}
                              {schedulevalidate && timeError &&  (
                              <span className="require_class">
                                {timeError}
                              </span>
                            )}
                            {schedulevalidate && dateError &&(
                              <span className="require_class">
                                {dateError}
                              </span>
                            )}

                          </div>
                        </>
                      )}
                    </>
                  )
                })}
              </>
            )}
          </DialogContentText>
        </DialogContent>
        <div className="dialog-buttons">
          {props.value.Type == 'Notification_bar' && (
            <button
              className="dialog-btn1"
              onClick={() => {
                finaldata(false)
              }}
            >
              {formate(
                props.value.Butonstatus != '' &&
                  props.value.Butonstatus != undefined
                  ? props.value.Butonstatus
                  : 'ok!',
              )}
            </button>
          )}
          {props.value.Type == 'form_bar' && (
            <>
              <button
                className="dialog-btn1"
                onClick={() => {
                  finaldata(false, 'cancel')
                }}
                style={props.cancel}
              >
                Cancel{' '}
              </button>
              <button
                className={validateregex_button ? "dialog-btn1" : " dialog-btn1"}
                onClick={() => {
                  finaldata(false, 'create')
                }}
              >
                {' '}
                {formate(props.value.Butonstatus)}
              </button>
            </>
          )}
        </div>
      </Dialog>
    </div>
  )
}

export default Dialogbox

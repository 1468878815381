import React, { useState, useEffect, useRef } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import Dialogbox from '../Dialogbox'
import { CreateNote } from '../../redux/actions/Asterleads'
import { CreateTicketNote,SendTicketEmail } from '../../redux/actions/TicketAction'
import EventEmitter from '../../utils/EventEmitter'
import { Form } from 'react-bootstrap'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
function LeadNote(props) {
  let AppPermission
  if (localStorage.getItem('config')) {
    AppPermission = JSON.parse(localStorage.getItem('config')).data.projects[0].userPermission
  }
  var agent = localStorage.getItem('user')
  let state = useSelector((state) => state)
  let Dispatch = useDispatch()
  let [checked, setchecked] = useState(false)
  const [wordCount, setwordCount] = useState(255)
  const [notepadtext, setnotepadtext] = useState('')
  const [popupdata, setpopupdata] = useState({
    open: false,
    Type: 'form_bar',
    field: [],
    title: 'Create Comment',
    Message: 'Are you sure you want to create Comment ? ',
    Butonstatus: 'create',
  })
  const dialogopen = (data) => {
    setpopupdata((set) => {
      set.open = data
      return {
        ...set,
      }
    })
  }
  const closepopup = (data) => {
    setpopupdata((set) => {
      set.open = false
      return {
        ...set,
      }
    })
    if (data.action === 'create') {
      if (props.type != 'ticket') {

        let payload = {
          lead_id: `${props.basicdata.id}`,
          user: agent,
          phone_number: props.basicdata.phone_number.toString(),
          description: notepadtext,
          channel: 'note',
          module: props.basicdata.module,
        }

        Dispatch(CreateNote(payload))
          .then((response) => {
            if (response.data.statusCode === 201) {
              setnotepadtext('')
            }
          })
          .catch((err) => {
            EventEmitter.emit('error-message-getter', {
              action: 'create',
              module: 'lead note',
            })
          })
      }
      else {
        let payload = {
          ticket_id: `${props.basicdata.id}`,
          user: agent,
          description: notepadtext,
        }

        Dispatch(CreateTicketNote(payload))
          .then((response) => {
            if (response.data.statusCode === 201) {
             
              if (checked) {
              let  emailpayload={
                  "receiver":props.basicdata.all.email,
                  "subject":"demosubject",
                  "message":notepadtext
                }
                Dispatch(SendTicketEmail(emailpayload))
                .then((response) => { 
                  if(response.data.statusCode===200){
                    setnotepadtext('')
                    setchecked(false)
                  }
                  
                }).catch((err) => {
                  EventEmitter.emit('error-message-getter', {
                    action: 'create',
                    module: 'email to customer',
                  })
                })
              }else{
                setnotepadtext('')
              }
            }
          })
          .catch((err) => {
            EventEmitter.emit('error-message-getter', {
              action: 'create',
              module: 'comment',
            })
          })
      }
    } else {
      setnotepadtext('')
    }
  }

  function gettextareavalue(value) {
    let count = value.split("").length

    if (255 - count <= 255 && 255 - count >= 0) {
      setwordCount(255 - count)
      setnotepadtext(value)
    }

  }
  return (
    <>
      <Dialogbox
        fields={closepopup}
        value={popupdata}
        defaultclass={'dialog-body-default'}
      />
      <div className="lead-notepad-container">


        <div className="lead-notepad-head ">
          <div className="lead-notepad-head-lft lead-meetings-head-lft">

            <div className="lead-notepad-head-title"> Comments</div>

          </div>
          <div className='lead-notepad-rht'>
            <div>
              {AppPermission.ticket_comment_mail && props.type == 'ticket' && <OverlayTrigger
                placement={'bottom'}
                overlay={<Tooltip>{checked ? "Public" : "Private"}</Tooltip>}
              >
               <div className="ticket-toggle-icon lead-create-head-rht lead-notepad-rht-toggle">
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    placeholder="nnnnnnnnn"
                    checked={checked}
                    onChange={(e) => {
                      setchecked(e.target.checked)
                    }}
                  />
                </div>
              </OverlayTrigger>}
            </div>

            <div
              className=" lead-save-icon lead-notepad-head-rht material-icons"
              onClick={() => {
                dialogopen(true)
              }}
            >
              save
            </div>
          </div>
        </div>
        <div className='lead-note-padbody'>

          <textarea
            value={notepadtext}
            onChange={(e) => {
              gettextareavalue(e.target.value)
            }}
            className="lead-note-pad form-control"
            rows="20"
            placeholder={props.type === 'ticket' ? "Type Here To Create Comment" : "Type Here To Create Comment"}
          ></textarea>
          <div className='lead-notepad-word-count'>Max Word Count : {wordCount}</div>
        </div>

      </div>
    </>
  )
}

export default LeadNote

import React, { useState, useEffect } from 'react'
import { fetchcallaction } from '../redux/Calls/CallAction'
import { connect, useDispatch, useSelector } from 'react-redux'
import Dialpad from './CallDialPad'
import Contactdetail from './Contacts/Contactdetail'
import { webrtcmanual } from '../redux/background/BackgroundAction'
import CallDetails from './Contacts/Calldetail'
import EventEmitter from '../utils/EventEmitter'
import { callmodule } from '../redux/Calls/CallAction'
import { TicketFetch } from '../redux/actions/TicketAction'
import { useNavigate } from 'react-router'
import Autodial_Template from './Lead/autodial-template'
import Autodial_list from './Contacts/Autodial-list'

import {
  ContactFetch,
  ContactCustomformFields,
} from '../redux/actions/ContactActions'
import { CustomformData, CustomformFields, PopupRouter } from '../redux/actions/Customform'
import {
  callLeadDetail
} from '../redux/background/BackgroundAction'
function Calllog(props) {
  let session_state = useSelector((state) => state)
  let campaigninfo = JSON.parse(localStorage.getItem("selectedCampaign_data"))
  let process = localStorage.getItem('process')
  let dispatch = useDispatch()
  let navigate = useNavigate()
  const [callHeader, setcallHeader] = useState();
  const [list, setlist] = useState(true);
  let config
  let AppPermission
  if (localStorage.getItem('config')) {
    config = JSON.parse(localStorage.getItem('config'))
    AppPermission = JSON.parse(localStorage.getItem('config')).data.projects[0].userPermission
  }
  const agent = localStorage.getItem('user')
  useEffect(() => {
    if (AppPermission.view_call_log) {
      setcallHeader(1)
    }
    else if (AppPermission.view_contacts) {
      setcallHeader(2)
    }
    else if (AppPermission.view_dialer_screen) {
      setcallHeader(3)
    }
  }, [])
  const dialpadnumbers = (data) => {
    getnumber([data,null,'null','null',false])
  }

  function getnumber(value) {
    let payload = {
      template_name: campaigninfo.template_name,
      phone_number: value[0].toString()
    }
    if (process === "leads" || process==="leads,meetings" || process==="leads,tickets,meetings" || process==="leads,tickets") {
      payload.module = 'leads'
      dispatch(PopupRouter(payload)).then((res) => {
        if (res.data.ids.length > 0) {
         let id=res.data.ids[0].lead_id
          dispatch(callmodule({
            id: id,
            module: "lead",
            phone_no: value[0].toString()
          }))
          payload = {
            state: session_state.background.websocketconnection,
            value: value[0],
            isabandon: value[4],
          }
          props.outboundnumber(payload)
          var names = value[1] === null ? "" : value[1] === undefined ? "" : value[1]
          if (names === "") {
            names = value[0]
          }
          localStorage.setItem("dialpadname", names);
        }else{
          payload = {
            state: session_state.background.websocketconnection,
            value: value[0],
            isabandon: value[4],
          }
          dispatch(callmodule({
            id: "",
            module: "lead",
            phone_no: value[0].toString()
          }))
          props.outboundnumber(payload)
          
        }
      }).catch((err) => {
        EventEmitter.emit('error-message-getter', {
          action: 'fetch',
          module: 'popup router',
        })
      })
    }else if (process === "tickets" || process==="tickets,meetings") {
      payload.module = 'tickets'
      dispatch(PopupRouter(payload)).then((res) => {
        if (res.data.ids.length > 0) {
         let id=res.data.ids[0].ticket_id
          dispatch(callmodule({
            id: id,
            module: "ticket",
            phone_no: value[0].toString()
          }))
          payload = {
            state: session_state.background.websocketconnection,
            value: value[0],
            isabandon: value[4],
          }
          props.outboundnumber(payload)
          var names = value[1] === null ? "" : value[1] === undefined ? "" : value[1]
          if (names === "") {
            names = value[0]
          }
          localStorage.setItem("dialpadname", names);
        }else{
          payload = {
            state: session_state.background.websocketconnection,
            value: value[0],
            isabandon: value[4],
          }
          dispatch(callmodule({
            id: "",
            module: "ticket",
            phone_no: value[0].toString()
          }))
         props.outboundnumber(payload)
          
        }
      }).catch((err) => {
        EventEmitter.emit('error-message-getter', {
          action: 'fetch',
          module: 'popup router',
        })
      })
    }
    else if (process === "meetings") {
      payload.module = 'meetings'
      dispatch(PopupRouter(payload)).then((res) => {
        if (res.data.ids.length > 0) {
         let id=res.data.ids[0].fwp_id
          dispatch(callmodule({
            id: id,
            module: "meeting",
            phone_no: value[0].toString()
          }))
          payload = {
            state: session_state.background.websocketconnection,
            value: value[0],
            isabandon: value[4],
          }
          props.outboundnumber(payload)
          var names = value[1] === null ? "" : value[1] === undefined ? "" : value[1]
          if (names === "") {
            names = value[0]
          }
          localStorage.setItem("dialpadname", names);
        }else{
          payload = {
            state: session_state.background.websocketconnection,
            value: value[0],
            isabandon: value[4],
          }
          props.outboundnumber(payload)
          if(res.data.contact_id.length>0){
            dispatch(callmodule({
              id: res.data.contact_id[0].id,
              module: "contact",
              phone_no: value[0].toString()
            }))
          }else{
            dispatch(callmodule({
              id: "",
              module: "meeting",
              phone_no: value[0].toString()
            }))
          }
          
      }
      }).catch((err) => {
        EventEmitter.emit('error-message-getter', {
          action: 'fetch',
          module: 'popup router',
        })
      })
    }
    


  }

  function showlist(data) {
    setlist(data)
  }
  return (
    <>
      <div className='callscreen-header-div'>
        {session_state.call.autodialstatus && <Autodial_Template showlist={showlist} ></Autodial_Template>}
        <>
          {list && <div className="callscreen-header span" style={{ cursor: 'pointer' }}>
            {AppPermission.view_call_log &&
              <span
                onClick={() => setcallHeader(1)}
                className={callHeader === 1 ? 'active' : ''}
              >
                Call Log
              </span>
            }
            {AppPermission.view_contacts &&
              <span
                onClick={() => setcallHeader(2)}
                className={callHeader === 2 ? 'active' : ''}
              >
                Contacts
              </span>
            } {AppPermission.view_dialer_screen &&
              <span
                onClick={() => setcallHeader(3)}
                className={callHeader === 3 ? 'active' : ''}
              >
                Dial Pad
              </span>
            }
          </div>}
          <>{callHeader === 1 && AppPermission.view_call_log && list && <CallDetails callnumber={getnumber} />}</>
          <>
            {callHeader === 2 && AppPermission.view_contacts && list && <Contactdetail callnumber={getnumber} />}
          </>
          <>
            {callHeader === 3 && AppPermission.view_dialer_screen && list && (
              <div className="dialpad_setup">
                <Dialpad dialpadnumbers={dialpadnumbers} />
              </div>
            )}
          </>
          <>
            {list === false && (
              <Autodial_list></Autodial_list>
            )}
          </>
        </>

      </div>
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    callAction: (type) => dispatch(fetchcallaction(type)),
  }
}
export default connect(null, mapDispatchToProps)(Calllog)
